// deps
// eslint-disable-next-line import/no-unresolved
import generateUri from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import {
  adminRouter,
  adminRouterWithBasePath,
} from "../../../constants/routers/admin";

/**
 * Retourne l’URI de la route API demandée.
 * @param {object} options
 * @param {string} options.id
 * @param {boolean} [options.includeBasePath]
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default} [options.router]
 * @param {object} [options.parameters]
 * @param {string} [options.scheme]
 * @param {string | {user: string; password: string}} [options.userInfo]
 * @param {string} [options.host]
 * @param {number} [options.port]
 * @param {string | object} [options.query]
 * @param {string} [options.fragment]
 * @returns {string}
 */
export default function generateAdminUrl(options) {
  const { includeBasePath = false, ...otherOptions } = options;

  if (includeBasePath) {
    return generateUri({
      router: adminRouterWithBasePath,
      ...otherOptions,
    });
  }

  return generateUri({
    router: adminRouter,
    ...otherOptions,
  });
}
