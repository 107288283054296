// deps
import { defineMessage } from "react-intl";

/* Tri */
export const USERS_ADMINS_SORT_VALUE_CREATED_ASC = "created_asc";
export const USERS_ADMINS_SORT_VALUE_CREATED_DESC = "created_desc";
export const USERS_ADMINS_SORT_VALUE_FIRST_NAME_ASC = "first_name_asc";
export const USERS_ADMINS_SORT_VALUE_FIRST_NAME_DESC = "first_name_desc";

export const usersAdminsSortMessage = defineMessage({
  id: "raiden.library.constants.usersAdmins.sort",
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} first_name_asc {Nom croissant} first_name_desc {Nom décroissant} other {{sort}}}",
});

export const USERS_ADMINS_SORTS = {
  [USERS_ADMINS_SORT_VALUE_CREATED_ASC]: {
    id: USERS_ADMINS_SORT_VALUE_CREATED_ASC,
  },
  [USERS_ADMINS_SORT_VALUE_CREATED_DESC]: {
    id: USERS_ADMINS_SORT_VALUE_CREATED_DESC,
  },
  [USERS_ADMINS_SORT_VALUE_FIRST_NAME_ASC]: {
    id: USERS_ADMINS_SORT_VALUE_FIRST_NAME_ASC,
  },
  [USERS_ADMINS_SORT_VALUE_FIRST_NAME_DESC]: {
    id: USERS_ADMINS_SORT_VALUE_FIRST_NAME_DESC,
  },
};

export const USERS_ADMINS_SORT_LIST = Object.values(USERS_ADMINS_SORTS);

/* Gender */
export const USERS_ADMINS_GENDER_VALUE_MALE = "male";
export const USERS_ADMINS_GENDER_VALUE_FEMALE = "female";
export const USERS_ADMINS_GENDER_VALUE_OTHER = "other";

export const usersAdminsGenderMessage = defineMessage({
  defaultMessage: "{gender, select, male {M.} female {Mme} other {Non Défini}}",
});

export const USERS_ADMINS_GENDERS = {
  [USERS_ADMINS_GENDER_VALUE_MALE]: {
    id: USERS_ADMINS_GENDER_VALUE_MALE,
  },
  [USERS_ADMINS_GENDER_VALUE_FEMALE]: {
    id: USERS_ADMINS_GENDER_VALUE_FEMALE,
  },
  [USERS_ADMINS_GENDER_VALUE_OTHER]: {
    id: USERS_ADMINS_GENDER_VALUE_OTHER,
  },
};
export const USERS_ADMINS_GENDER_LIST = Object.values(USERS_ADMINS_GENDERS);
