/**
 * @param {object} param0
 * @param {import("../../../../types/Customer").CustomerUpdateLoginCredentialsDataFormValues} param0.fields
 * @param {boolean} [param0.isSameEmail]
 */
export default function customersSerializeUpdateLoginCredentials({
  fields,
  isSameEmail = false,
}) {
  return {
    data: {
      // If the email is the same, we don't need to send it, the user is just updating the password
      email: isSameEmail ? undefined : fields?.data?.email,
      password: fields?.data?.password ? fields?.data?.password : undefined,
      password_confirmation: fields?.data?.password_confirmation
        ? fields?.data?.password_confirmation
        : undefined,
    },
  };
}
