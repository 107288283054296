/**
 * Retourne une valeur de la configuration sur un environnement, ou sur la clé globale
 *   si l’environnement ne possède pas cette clé.
 * @param {object} param0
 * @param {object} param0.configuration
 * @param {Function} param0.key
 * @param {string|number} [param0.environmentId]
 */
export default function configurationGet({
  configuration,
  key,
  environmentId,
}) {
  const environment = (configuration?.environments ?? []).find(function (
    environment,
  ) {
    return String(environment.id) === environmentId;
  });

  const value = key(environment);

  if (value) {
    return value;
  }

  return key(configuration);
}
