// deps
import { useRef, useEffect } from "react";
import { Box, Button, useBreakpointValue, Text, Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

// components
import FormControlRHF from "../../../components/ReactHookForm/FormControlRHF";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import { FormPinInput } from "../../../components/Form/PinInput";

// constants
import { TWO_FA_METHOD_VALUE_APP } from "../../../constants/twoFa";

/**
 * @typedef Props
 * @property {import("../../../types/TwoFaChallenge").TwoFaChallenge} resource
 * @property {() => void} challenge
 * @property {boolean} isLoading
 * @property {() => void} onSubmit
 * @property {number} timeLeft
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export default function Form({
  resource,
  challenge,
  isLoading,
  timeLeft,
  onSubmit,
}) {
  const intl = useIntl();
  const { control } = useFormContext();
  /** @type {import("react").MutableRefObject<HTMLElement | null> } */
  const codeInputRef = useRef(null);

  const responsive = useBreakpointValue({
    base: true,
    sm: false,
  });

  // auto focus verification code input
  useEffect(() => {
    codeInputRef.current?.focus();
  }, [codeInputRef]);

  return (
    <>
      <ApiErrorMessage />

      <Box px="1rem">
        <Text color="gray.500" mb="16px">
          {intl.formatMessage({
            defaultMessage:
              "Veuillez utiliser l’authentification à deux facteurs pour confirmer votre demande.",
          })}
        </Text>
        <FormControlRHF
          control={control}
          name="data.otp_code"
          label={intl.formatMessage(
            {
              defaultMessage:
                "Veuillez entrer le code {method, select, app {généré par votre application mobile} sms {reçu par sms} email {reçu par email} other {{method}} }",
            },
            {
              method: resource.method,
            },
          )}
          rules={{
            required: true,
          }}
          {...(timeLeft > 0 && {
            helperText: intl.formatMessage(
              {
                defaultMessage:
                  "Vous n’avez pas reçu le code ? Vour pourrez demander un nouveau code dans {timeLeft}s.",
              },
              {
                timeLeft,
              },
            ),
          })}
          renderWithFormControl={(field) => (
            <Flex
              width="full"
              align={responsive ? "center" : "flex-start"}
              flexDirection={responsive ? "column" : "row"}
              gap=".5rem">
              <FormPinInput
                numberOfDigits={6}
                {...field}
                onComplete={onSubmit}
                isDisabled={field.isDisabled}
              />
              {resource.method !== TWO_FA_METHOD_VALUE_APP ? (
                timeLeft > 0 ? (
                  <Button type="submit" colorScheme="brandPrimary">
                    <FormattedMessage defaultMessage="Valider" />
                  </Button>
                ) : (
                  <Button
                    type="button"
                    colorScheme="brandPrimary"
                    onClick={challenge}
                    isLoading={isLoading}
                    isDisabled={timeLeft > 0}>
                    <FormattedMessage defaultMessage="Obtenir le code" />
                  </Button>
                )
              ) : (
                <Button type="submit" colorScheme="brandPrimary">
                  <FormattedMessage defaultMessage="Valider" />
                </Button>
              )}
            </Flex>
          )}
        />
      </Box>
    </>
  );
}
