import {
  Box,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { cloneElement, forwardRef, isValidElement, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import ResizeTextarea from "react-textarea-autosize";
import { RULES_TEXTAREA_MAX_LENGTH } from "../../../constants/form";
import DatePicker from "../../DatePicker";
import AdvisedFormLabel from "../../Form/AdvisedFormLabel";
import FormErrorMessageRHF from "../FormErrorMessageRHF";
import FormErrorObserverRHF from "../FormErrorObserverRHF";
import ReactHookFormNumberInput from "../NumberInput";

/**
 * @typedef {object} Rules
 * @property {boolean} [required]
 * @property {number} [maxLength]
 * @property {number} [minLength]
 * @property {number} [max]
 * @property {number} [min]
 * @property {number} [step]
 * @property {RegExp} [pattern]
 * @property {(value: any) => boolean | Promise<boolean> | string} [validate]
 * @property {boolean} [valueAsNumber]
 * @property {boolean} [valueAsDate]
 * @property {(value: any) => any} [setValueAs]
 * @property {boolean} [disabled]
 * @property {(e: import("react").SyntheticEvent) => void} [onChange]
 * @property {(e: import("react").SyntheticEvent) => void} [onBlur]
 * @property {any} [value]
 */

/**
 * @template TValue
 * @typedef {object} FieldProps
 * @property {string} name
 * @property {import("react-hook-form").Noop} onBlur
 * @property {(event: {target: { name: string, value: TValue}}) => void} onChange
 * @property {TValue} value
 * @property {boolean} isRequired
 * @property {boolean} isDisabled
 * @property {boolean} isInvalid
 */

/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} FormControllerRenderParams
 * @property {import("react-hook-form").FieldPath<TFieldValues>} name
 * @property {import("react-hook-form").Noop} onBlur
 * @property {(...event: any[]) => void} onChange
 * @property {import("react-hook-form").FieldPathValue<TFieldValues, TName>} value
 * @property {boolean} isRequired
 * @property {boolean} isDisabled
 * @property {boolean} isInvalid
 * @property {import("react-hook-form").RefCallBack} ref
 */

/**
 * A component that is used as a fundamental block of a form. Every field should be wrapped by this component.
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} Props
 * @property {import("react-hook-form").Control<TFieldValues>} [control]
 * @property {TName} name
 * @property {Rules} [rules]
 * @property {string} [type]
 * @property {any} [label]
 * @property {any} [placeholder]
 * @property {any} [defaultValue]
 * @property {any} [helperText]
 * @property {object} [labelProps]
 * @property {boolean} [isDisabled]
 * @property {boolean} [displayError]
 * @property {string} [errorMessageMaxWidth]
 * @property {any} [inputProps]
 * @property {boolean} [shouldUnregister]
 * @property {boolean} [withCharactersCounter]
 * @property {any} [advisedText]
 * @property {boolean} [preventFowardRef]
 * @property {(params: FormControllerRenderParams<TFieldValues, TName>) => React.ReactNode} [render]
 * @property {(params: FormControllerRenderParams<TFieldValues, TName>) => React.ReactNode} [renderWithFormControl]
 * @property {React.ReactNode} [children]
 */

/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @param {Props<TFieldValues, TName> & import("@chakra-ui/react").BoxProps} props
 * @param {import("react").ForwardedRef<any>} ref
 */
function _FormController(
  {
    control,
    name,
    rules,
    type,
    label,
    placeholder,
    isDisabled: _isDisabled,
    inputProps,
    helperText,
    displayError = true,
    errorMessageMaxWidth,
    defaultValue,
    render,
    renderWithFormControl,
    labelProps,
    shouldUnregister = false,
    withCharactersCounter = false,
    advisedText,
    children,
    ...otherProps
  },
  ref,
) {
  const { control: defaultControl } = useFormContext();

  /** @type {Rules} */
  const localRules = useMemo(() => {
    return {
      ...rules,
      ...(withCharactersCounter && {
        maxLength: rules?.maxLength ?? RULES_TEXTAREA_MAX_LENGTH,
      }),
    };
  }, [rules, withCharactersCounter]);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    // @ts-ignore
    control: control ?? defaultControl,
    rules: localRules,
    shouldUnregister,
    defaultValue,
  });
  const { ref: fieldRef } = field;

  const intl = useIntl();

  const mergedRefs = useMemo(() => {
    return (node) => {
      if (node) {
        fieldRef(node);
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }
    };
  }, [fieldRef, ref]);

  const isDisabled = Boolean(_isDisabled || rules?.disabled);
  const isRequired = Boolean(rules?.required);
  const isInvalid = Boolean(error);

  const commonFieldProps = {
    isDisabled,
    isRequired,
    isInvalid,
  };

  return (
    <Box {...otherProps}>
      {typeof render === "function" &&
        render({
          ...field,
          ...commonFieldProps,
          ref: mergedRefs,
        })}
      {render === undefined && (
        <FormControl {...commonFieldProps}>
          {label && (
            <FormLabel
              {...labelProps}
              display={advisedText ? "flex" : undefined}>
              {advisedText ? (
                <AdvisedFormLabel label={label} advisedText={advisedText} />
              ) : (
                label
              )}
            </FormLabel>
          )}
          {isValidElement(children) &&
            cloneElement(children, {
              ...field,
              ...commonFieldProps,
              ref: mergedRefs,
            })}
          {typeof children === "function" &&
            children({
              ...field,
              ...commonFieldProps,
              ref: mergedRefs,
            })}
          {typeof renderWithFormControl === "function" &&
            renderWithFormControl({
              ...field,
              ...commonFieldProps,
              ref: mergedRefs,
            })}
          {children === undefined &&
            renderWithFormControl === undefined &&
            (() => {
              switch (type) {
                case "datetime-picker":
                  return (
                    <DatePicker
                      {...field}
                      {...commonFieldProps}
                      ref={mergedRefs}
                      {...inputProps}
                    />
                  );
                case "number-input":
                  return (
                    <ReactHookFormNumberInput
                      {...field}
                      {...commonFieldProps}
                      min={rules?.min}
                      max={rules?.max}
                      step={rules?.step ?? 1}
                      ref={mergedRefs}
                      value={field.value ?? defaultValue ?? 0}
                      placeholder={
                        placeholder ??
                        (typeof label === "string" ? label : undefined)
                      }
                      {...inputProps}
                    />
                  );
                case "textarea":
                  return (
                    <Textarea
                      {...field}
                      {...commonFieldProps}
                      value={String(field?.value ?? "")}
                      placeholder={
                        placeholder ??
                        (typeof label === "string" ? label : undefined)
                      }
                      ref={mergedRefs}
                      as={ResizeTextarea}
                      {...inputProps}
                    />
                  );
                default:
                  return (
                    <>
                      <Input
                        {...field}
                        {...commonFieldProps}
                        ref={mergedRefs}
                        type={type}
                        placeholder={
                          placeholder ??
                          (typeof label === "string" ? label : null)
                        }
                        value={String(field?.value ?? "")}
                        sx={{
                          "&:placeholder-shown": {
                            textTransform: "ellipsis",
                          },
                        }}
                        {...inputProps}
                      />
                    </>
                  );
              }
            })()}
          {withCharactersCounter ? (
            <Flex gap="1rem" alignItems="center" w="full">
              {helperText && <FormHelperText>{helperText}</FormHelperText>}
              <Text
                mt=".5rem"
                fontSize="sm"
                color={
                  (field?.value?.length ?? 0) > (localRules?.maxLength ?? 0)
                    ? "red.500"
                    : "gray.500"
                }
                ml="auto">
                {intl.formatMessage(
                  { defaultMessage: "{count}/{max} caractères" },
                  {
                    count: field?.value?.length ?? 0,
                    max: localRules?.maxLength,
                  },
                )}
              </Text>
            </Flex>
          ) : (
            <>{helperText && <FormHelperText>{helperText}</FormHelperText>}</>
          )}
        </FormControl>
      )}
      {displayError && (
        <FormErrorObserverRHF
          name={name}
          render={({ hasError, error }) => (
            <Collapse in={hasError} unmountOnExit={true}>
              <FormErrorMessageRHF error={error} maxW={errorMessageMaxWidth} />
            </Collapse>
          )}
        />
      )}
    </Box>
  );
}

// eslint-disable-next-line jsdoc/valid-types
/** @type {<TFieldValues extends import("react-hook-form").FieldValues, TName extends import("react-hook-form").FieldPath<TFieldValues>>(props: Props<TFieldValues, TName> & { ref?: import("react").MutableRefObject<any> } & import("@chakra-ui/react").BoxProps) => React.ReactElement} */
// @ts-ignore
const FormControlRHF = forwardRef(_FormController);

export default FormControlRHF;
