import {
  AspectRatio,
  Box,
  DarkMode,
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { CenteredContent } from "../../../../components/CenteredContent";
import { NewsgroupsSubscribersCreate } from "../../../Newsgroups/Subscribers/Create";

export function NewsLetter() {
  return (
    <Box background="linear-gradient(90deg, rgba(0,37,56,1) 0%, rgba(0,37,56,1) 50%, rgba(0,139,213,1) 50%, rgba(0,139,213,1) 100%);">
      <CenteredContent>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing="0"
          flexBasis="0"
          alignItems="stretch">
          <HStack
            justify="center"
            backgroundColor="rgba(0,37,56,1)"
            pl={{ base: "1.375rem", lg: "0" }}
            pr={{ base: "1.375rem", lg: "2.6875rem" }}
            py="2.5rem">
            <AspectRatio ratio={315 / 75} w="315px">
              <Image
                src={generateFrontPath({
                  id: "@front.internal-assets",
                  parameters: { filePath: "logo-description-white.svg" },
                  includeBasePath: true,
                })}
                alt="Clévacances"
                loading="lazy"
              />
            </AspectRatio>
          </HStack>

          <Box
            flexGrow={1}
            backgroundColor="rgba(0,139,213,1)"
            py={{ base: "1.25rem", lg: "3.125rem" }}
            pl={{ base: "1rem", lg: "2rem" }}
            pr={{ base: "1rem", lg: "0" }}>
            <DarkMode>
              <NewsgroupsSubscribersCreate />
            </DarkMode>
          </Box>
        </Stack>
      </CenteredContent>
    </Box>
  );
}
