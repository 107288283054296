import { HStack, Text } from "@chakra-ui/react";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

const MAX_TAGS = 10;

/**
 * @typedef {object} Props
 * @property {import(".").AutocompleteItemValue[]} values
 * @property {import(".").AutocompleteRenderTag} renderTag
 * @property {import(".").AutocompleteResolveItemByValue} resolveItemByValue
 * @property {import(".").AutocompleteResolveItemName} resolveItemName
 * @property {boolean} isHydrationDone
 * @property {import(".").AutocompleteRemoveValue} removeValue
 * @property {boolean} isMultiple
 */
/**
 * @param {Props} props
 */
export function AutocompleteTags({
  values,
  renderTag,
  resolveItemByValue,
  resolveItemName,
  isHydrationDone,
  removeValue,
  isMultiple,
}) {
  const refContainer = useRef(/** @type {HTMLDivElement | null} */ (null));

  const refTags = useRef(/** @type {HTMLDivElement | null} */ (null));

  const [tagOverflow, setTagOverflow] = useState(values.length > MAX_TAGS);

  // limits the number of tags to render
  const displayedValues = useMemo(() => {
    return values.filter((_, index) => index < MAX_TAGS);
  }, [values]);

  const displayedValuesLength = useMemo(() => {
    return displayedValues.length;
  }, [displayedValues.length]);

  useEffect(() => {
    const container = refContainer.current;
    const tags = refTags.current;
    if (!container || !tags) {
      return;
    }

    const handler = () => {
      if (
        (tags.scrollWidth > container.clientWidth ||
          values.length > MAX_TAGS) &&
        isMultiple
      ) {
        setTagOverflow(true);
      } else {
        setTagOverflow(false);
      }
    };

    const observerContainer = new ResizeObserver(handler);
    const observerTags = new ResizeObserver(handler);

    observerContainer.observe(container);
    observerTags.observe(tags);

    return () => {
      observerContainer.disconnect();
      observerTags.disconnect();
    };
  }, [displayedValuesLength, isMultiple, values.length]);

  return (
    <HStack ref={refContainer} flexGrow={1} overflow="hidden">
      {tagOverflow && (
        <Text flexGrow={1} flexShrink={0}>
          <FormattedMessage
            defaultMessage="{count} éléments"
            values={{ count: values.length }}
          />
        </Text>
      )}

      <HStack overflow="hidden" flexGrow={1}>
        <HStack
          ref={refTags}
          display="inline-flex"
          opacity={tagOverflow ? 0 : 1}
          flexGrow={isMultiple ? 0 : 1}
          overflow="hidden"
          pointerEvents={tagOverflow ? "none" : undefined}>
          {displayedValues.map((value) => (
            <Fragment key={value}>
              {renderTag({
                item: resolveItemByValue({ value }),
                itemValue: value,
                resolveItemName,
                isHydrationDone,
                removeValue,
                isMultiple,
              })}
            </Fragment>
          ))}
        </HStack>
      </HStack>
    </HStack>
  );
}
