// deps
import { createContext, useContext, useMemo } from "react";
import { useDisclosure } from "@chakra-ui/react";

const MaintenanceModeContext = createContext(
  /** @type {{ onOpen: () => void; onClose: () => void; isOpen: boolean }} */ ({
    isOpen: false,
    onOpen: () => {},
    onClose: () => {},
  }),
);

export function MaintenanceModeProvider(props) {
  const { children } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const value = useMemo(
    function () {
      return { isOpen, onOpen, onClose };
    },
    [isOpen, onOpen, onClose],
  );

  return (
    <MaintenanceModeContext.Provider value={value}>
      {children}
    </MaintenanceModeContext.Provider>
  );
}

export function useMaintenanceMode() {
  return useContext(MaintenanceModeContext);
}
