import { Box, Button } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { CenteredContent } from "../../components/CenteredContent";
import { Href } from "../../components/Href";
import { PageHeader } from "../../components/PageHeader";
import { PageLayout } from "../../components/PageLayout";
import { Splash } from "../../components/Splash";
import { useAutoLayout } from "../../hooks/useAutoLayout";

function Error404() {
  const intl = useIntl();

  const { userCustomer } = useAuth();

  const Layout = useAutoLayout({ user: userCustomer });

  return (
    <PageLayout
      header={
        <PageHeader
          title={intl.formatMessage({
            defaultMessage: "La page demandée n'existe pas",
          })}
          breadCrumbItems={[
            {
              title: intl.formatMessage({ defaultMessage: "Accueil" }),
              url: generateFrontPath({
                id: "@front.account",
              }),
            },
            { title: intl.formatMessage({ defaultMessage: "Erreur 404" }) },
          ]}
        />
      }>
      <Box px="1rem" py="1rem">
        <CenteredContent>
          <Splash
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: { filePath: "illustrations/not-found.svg" },
              includeBasePath: true,
            })}
            title={intl.formatMessage({
              defaultMessage:
                "Nous sommes désolés, cette page est introuvable ou n'existe pas",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Retournez à l'accueil ou utilisez votre menu de navigation",
            })}
            addon={
              <Href
                href={
                  Layout.name === "signedIn"
                    ? generateFrontPath({ id: "@front.account" })
                    : generateFrontPath({ id: "@front.signin" })
                }>
                <Button size="lg" colorScheme="brandPrimary">
                  {intl.formatMessage({
                    defaultMessage: "Retourner à l'accueil",
                  })}
                </Button>
              </Href>
            }
          />
        </CenteredContent>
      </Box>
    </PageLayout>
  );
}

export default Error404;
