/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const TABS_THEME = {
  baseStyle: {
    tab: {
      _focus: {
        zIndex: "unset",
      },
    },
  },
};
