import { Box } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import NextLink from "next/link";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @param {object} params
 * @param {string | null} params.role
 * @returns {import("@chakra-ui/react").BoxProps}
 */
function getMenuItemProps({ role }) {
  const defaultProps = {
    py: "1.875rem",
    px: "0.5rem",
    _hover: {
      textDecoration: "underline",
    },
  };
  switch (role) {
    case "highlighted": {
      return {
        ...defaultProps,
        px: "1.125rem",
        color: "#fff",
        backgroundColor: "brandPrimary.500",
        textDecorationColor: "#fff",
      };
    }
    default: {
      return defaultProps;
    }
  }
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} menuItem
 */
/**
 * @param {Props} props
 */
export function MenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target, role } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  return (
    <>
      {href !== undefined ? (
        <NextLink href={href} passHref>
          <Box as="a" whiteSpace="nowrap" target={target}>
            <Box {...getMenuItemProps({ role })}>{title}</Box>
          </Box>
        </NextLink>
      ) : (
        <Box whiteSpace="nowrap">
          <Box {...getMenuItemProps({ role })}>{title}</Box>
        </Box>
      )}

      {menuItem.children.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </>
  );
}
