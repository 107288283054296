// hooks
import { useCallback } from "react";
import useAuth from "./useAuth";

/**
 * @typedef {object} CanPerformParams
 * @property {string | Array<string> | null} [authorizations]
 * @property {Array<string>} [resourcePolicies]
 * @property {string | Array<string> | null} [policies]
 * @property {string | string[] | null} [somePolicies]
 * @property {number | null} [environmentId]
 * @property {Array<string> | null} [environmentsId]
 * @property {number | null} [hotelId]
 */

/**
 * @callback CanPerformCallback
 * @param {CanPerformParams} params
 * @returns {boolean}
 */

/**
 * @returns {CanPerformCallback}
 */
export default function useCanPerform() {
  const { userCan } = useAuth();

  /** @type {CanPerformCallback} */
  const canPerform = useCallback(
    function ({
      authorizations = null,
      resourcePolicies = [],
      policies = null,
      environmentId = null,
      environmentsId = null,
      somePolicies = null,
      hotelId = null,
    }) {
      if (
        /* Si le paramètre n’est pas un tableau */
        policies &&
        !Array.isArray(policies)
      ) {
        policies = [policies];
      }

      if (
        /* Si le paramètre n’est pas un tableau */
        somePolicies &&
        !Array.isArray(somePolicies)
      ) {
        somePolicies = [somePolicies];
      }

      if (authorizations) {
        if (Array.isArray(environmentsId) && environmentsId.length > 0) {
          return environmentsId.some((id) =>
            userCan({ authorizations, environmentId: id }),
          );
        } else {
          if (!userCan({ authorizations, environmentId, hotelId })) {
            return false;
          }
        }
      }

      if (
        Array.isArray(policies) &&
        !policies.every(function (policy) {
          return resourcePolicies.includes(policy);
        })
      ) {
        return false;
      }

      if (
        Array.isArray(somePolicies) &&
        !somePolicies.some(function (policy) {
          return resourcePolicies.includes(policy);
        })
      ) {
        return false;
      }

      return true;
    },
    [userCan],
  );

  return canPerform;
}
