import { Avatar, Box, HStack, Stack, Text } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { useIntl } from "react-intl";
import { NOT_HIGHLIGHTED_MENU_ITEMS } from "../../../../constants/menu";
import MenuCore from "../../../../components/Menu/MenuCore";
import MenuItemCore from "../../../../components/Menu/MenuItemCore";
import MenuItemStandard from "./MenuItem/MenuItemStandard";
import SignOutButton from "./SignOutButton";
import generatePampaUri from "@raiden/library-ui/libraries/utils/generatePampaUri";

function MenuStandard() {
  const intl = useIntl();

  /** @type {import("@raiden/library-ui/contexts/Auth").AuthContextValue} */
  const { userCustomer } = useAuth();

  return (
    <>
      <Box p="1rem">
        <NextLink
          href={generateFrontPath({ id: "@front.account.my-account" })}
          passHref>
          <HStack
            as="a"
            spacing="1rem"
            alignItems="center"
            backgroundColor="gray.50"
            borderRadius="0.375rem"
            p=".5rem"
            cursor="pointer"
            role="group">
            <Avatar
              name={userCustomer?.display_name}
              src={
                userCustomer?.avatar
                  ? generatePampaUri({
                      image: userCustomer.avatar,
                      format: "default",
                      mode: "crop",
                      width: 48,
                      height: 48,
                    })
                  : undefined
              }
            />

            <Stack spacing="4px">
              <Text fontSize="1rem" fontWeight={400} lineHeight="normal">
                {`${userCustomer?.display_name}`}
              </Text>

              <Text
                fontSize="1rem"
                fontWeight={600}
                lineHeight="1rem"
                _groupHover={{ textDecoration: "underline" }}>
                {intl.formatMessage({
                  defaultMessage: "Modifier mes informations",
                })}
              </Text>
            </Stack>
          </HStack>
        </NextLink>
      </Box>

      <MenuCore
        menuItems={NOT_HIGHLIGHTED_MENU_ITEMS}
        render={({
          menuItems,
          closeMenuItem,
          openMenuItem,
          openedMenuItemsIds,
        }) => (
          <>
            {menuItems?.map?.((item) => (
              <MenuItemCore
                item={item}
                openedMenuItemsIds={openedMenuItemsIds}
                openMenuItem={openMenuItem}
                closeMenuItem={closeMenuItem}
                render={(params) => <MenuItemStandard {...params} />}
                key={item.id}
              />
            ))}
          </>
        )}
      />

      <Box borderTopWidth="1px" px="10px" py="1rem">
        <SignOutButton />
      </Box>
    </>
  );
}

export default MenuStandard;
