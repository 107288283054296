import { createContext } from "react";

/**
 * @typedef {object} CenteredContextValue
 * @property {string} maxWidth
 */

/** @type {CenteredContextValue} */
const DEFAULT_VALUE = {
  maxWidth: "1200px",
};

export const CenteredContentContext = createContext(
  /** @type {CenteredContextValue} */ (DEFAULT_VALUE),
);
