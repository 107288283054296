import { createContext, memo, useContext } from "react";

const DEFAULT_VALUE = -1;

export const EnvironmentIdContext = createContext(DEFAULT_VALUE);

export const EnvironmentIdProvider = memo(
  /**
   * @typedef {object} Props
   * @property {number} environmentId
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function EnvironmentIdProvider({ environmentId, children }) {
    return (
      <EnvironmentIdContext.Provider value={environmentId}>
        {children}
      </EnvironmentIdContext.Provider>
    );
  },
);

export function useEnvironmentId() {
  const environmentId = useContext(EnvironmentIdContext);

  if (environmentId === DEFAULT_VALUE) {
    throw new Error(
      "useEnvironmentId must be used within a EnvironmentIdProvider",
    );
  }
  return environmentId;
}
