import NextLink from "next/link";

/**
 * @typedef {object} Props
 * @property {import("react").AnchorHTMLAttributes<HTMLAnchorElement>["href"]} [href]
 * @property {import("react").AnchorHTMLAttributes<HTMLAnchorElement>["target"]} [target]
 * @property {import("react").AnchorHTMLAttributes<HTMLAnchorElement>["rel"]} [rel]
 * @property {import("react").ReactNode} children
 */
/**
 * @param {import("react").AnchorHTMLAttributes<HTMLAnchorElement> & Props} props
 */
export function Href({ href, target, rel, children }) {
  return href ? (
    <NextLink href={href} passHref>
      <a style={{ display: "inline" }} target={target} rel={rel}>
        {children}
      </a>
    </NextLink>
  ) : (
    <>{children}</>
  );
}
