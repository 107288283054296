import { Box, Spinner } from "@chakra-ui/react";
import { getPostPreferredRevision } from "@raiden/library-ui/helpers/posts/getPreferredRevision";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { Content } from "../../../components/Posts/Content";

/**
 * @typedef {object} Props
 * @property {number | string} [idOrAlias] used to fetch the post to render
 * @property {import("@raiden/library-ui/types/Post").Post} [post] used to render the post if already fetched
 */
/**
 * @param {Props} props
 */
export function PostsContentView({ idOrAlias, post }) {
  const strIdOrAlias =
    idOrAlias !== undefined ? idOrAlias.toString() : undefined;

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post>} */
  const { swrResponse } = useApi(
    post === undefined && (strIdOrAlias?.length ?? 0) > 0
      ? generateApiUrl({
          id: "@posts.show",
          parameters: {
            postId: idOrAlias,
          },
          query: {
            fields: ["post.current_revision"],
          },
        })
      : null,
  );

  return (
    <>
      {post ? (
        <Content content={getPostPreferredRevision({ post })?.content} />
      ) : (
        <DataHandler
          swrResponse={swrResponse}
          loading={
            <Box px="1rem">
              <Spinner />
            </Box>
          }>
          {({ data: post }) => (
            <Content content={getPostPreferredRevision({ post })?.content} />
          )}
        </DataHandler>
      )}
    </>
  );
}
