// Workflow.

export const CARTS_WORKFLOW_VALUE_DIRECT = "direct";
export const CARTS_WORKFLOW_VALUE_SYSTEM = "system";
export const CARTS_WORKFLOW_VALUE_PARTNER = "partner";

export const CARTS_WORKFLOWS = {
  [CARTS_WORKFLOW_VALUE_DIRECT]: {
    id: CARTS_WORKFLOW_VALUE_DIRECT,
    icon: "envelope",
    color: "purple",
    color2: "#9385bb",
  },
  [CARTS_WORKFLOW_VALUE_SYSTEM]: {
    id: CARTS_WORKFLOW_VALUE_SYSTEM,
    icon: "panier",
    color: "blue",
    color2: "#008CD2",
  },
  [CARTS_WORKFLOW_VALUE_PARTNER]: {
    id: CARTS_WORKFLOW_VALUE_PARTNER,
    icon: "user",
    color: "brown",
    color2: "#B77A33",
  },
};

export const CARTS_WORKFLOW_LIST = Object.values(CARTS_WORKFLOWS);

// États

export const CARTS_STATE_VALUE_DRAFT = "draft";
export const CARTS_STATE_VALUE_CONFIRMED = "confirmed";
export const CARTS_STATE_VALUE_FINALIZED = "finalized";
export const CARTS_STATE_VALUE_CANCELLED = "cancelled";

export const CARTS_STATES = {
  [CARTS_STATE_VALUE_DRAFT]: {
    id: CARTS_STATE_VALUE_DRAFT,
    color: "default",
  },
  [CARTS_STATE_VALUE_CONFIRMED]: {
    id: CARTS_STATE_VALUE_CONFIRMED,
    color: "green",
  },
  [CARTS_STATE_VALUE_FINALIZED]: {
    id: CARTS_STATE_VALUE_FINALIZED,
    color: "green",
  },
  [CARTS_STATE_VALUE_CANCELLED]: {
    id: CARTS_STATE_VALUE_CANCELLED,
    color: "red",
  },
};

export const CARTS_STATE_LIST = Object.values(CARTS_STATES);

// deps
import { defineMessage } from "react-intl";

export const cartsWorkflowMessage = defineMessage({
  defaultMessage: `{workflow, select,
    direct {réservation directe}
    system {réservation en ligne}
    partner {réservation partenaire}
    other {{workflow}}
  }`,
});

export const cartsWorkflowMessageCount = defineMessage({
  defaultMessage: `{workflow, select,
    direct {{count, plural, =0 {aucune réservation directe} one {1 réservation directe} other {# réservations directes}}}
    system {{count, plural, =0 {aucune réservation en ligne} one {1 réservation en ligne} other {# réservations en ligne}}}
    partner {{count, plural, =0 {aucune réservation partenaire} one {1 réservation partenaire} other {# réservations partenaire}}}
    other {{workflow}}
  }`,
});

export const cartsStateMessage = defineMessage({
  defaultMessage: `{state, select,
    draft {brouillon}
    confirmed {confirmé}
    finalized {finalisé}
    cancelled {annulé}
    other {{state}}
  }`,
});

// Tourist tax mode constant specific for the update tourist tax form in cart/booking detail.
export const CARTS_GUEST_TOURIST_TAX_MODE_FORM_EXONERATED = "exonerated";
export const CARTS_GUEST_TOURIST_TAX_MODE_FORM_DO_NOT_DISPLAY = "doNotDisplay";
export const CARTS_GUEST_TOURIST_TAX_MODE_FORM_DEFINE_TAX = "defineTax";

export const CARTS_GUEST_TOURIST_TAX_MODE_FORMS = {
  [CARTS_GUEST_TOURIST_TAX_MODE_FORM_EXONERATED]: {
    id: CARTS_GUEST_TOURIST_TAX_MODE_FORM_EXONERATED,
  },
  [CARTS_GUEST_TOURIST_TAX_MODE_FORM_DO_NOT_DISPLAY]: {
    id: CARTS_GUEST_TOURIST_TAX_MODE_FORM_DO_NOT_DISPLAY,
  },
  [CARTS_GUEST_TOURIST_TAX_MODE_FORM_DEFINE_TAX]: {
    id: CARTS_GUEST_TOURIST_TAX_MODE_FORM_DEFINE_TAX,
  },
};

// Cart Payment methods
export const CART_PAYMENT_MODES_VALUE_CB = "cb";
export const CART_PAYMENT_MODES_VALUE_CHQ = "chq";
export const CART_PAYMENT_MODES_VALUE_VIR = "vir";
export const CART_PAYMENT_MODES_VALUE_ANCV = "ancv";
export const CART_PAYMENT_MODES_VALUE_CASH = "cash";
export const CART_PAYMENT_MODES_VALUE_OTHER = "other";

export const CART_PAYMENT_MODES = {
  [CART_PAYMENT_MODES_VALUE_CB]: {
    id: CART_PAYMENT_MODES_VALUE_CB,
  },
  [CART_PAYMENT_MODES_VALUE_CHQ]: {
    id: CART_PAYMENT_MODES_VALUE_CHQ,
  },
  [CART_PAYMENT_MODES_VALUE_VIR]: {
    id: CART_PAYMENT_MODES_VALUE_VIR,
  },
  [CART_PAYMENT_MODES_VALUE_ANCV]: {
    id: CART_PAYMENT_MODES_VALUE_ANCV,
  },
  [CART_PAYMENT_MODES_VALUE_CASH]: {
    id: CART_PAYMENT_MODES_VALUE_CASH,
  },
  [CART_PAYMENT_MODES_VALUE_OTHER]: {
    id: CART_PAYMENT_MODES_VALUE_OTHER,
  },
};

export const CART_PAYMENT_MODES_LIST = Object.values(CART_PAYMENT_MODES);

export const cartPaymentModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    cb {Carte bancaire}
    chq {Chèque}
    vir {Virement}
    ancv {Chèques vacances}
    cash {Espèces}
    other {Autres}}`,
});

export const cartByPaymentModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    cb {par carte bancaire}
    chq {par chèque}
    vir {par virement}
    ancv {par chèques vacances}
    cash {par espèces}
    other {autres}}`,
});
