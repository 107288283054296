import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { DgFipModal } from "../../../../Customer/DgFipModal";
import { Wrapper } from "../Wrapper";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import { COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT } from "@raiden/library-ui/constants/cookies";
import { useCookies } from "../../../../../hooks/useCookies";
import cookie from "cookie";

/**
 * @typedef Props
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {() => void} onSuccess
 */

/**
 * @param {Props} props
 */
export function WarningDgfipMissing({ customer, onSuccess }) {
  const intl = useIntl();

  const { isOpen, onClose, onOpen } = useDisclosure();

  function handleSuccess() {
    onSuccess();
    onClose();
  }

  const { setCookies } = useCookies();

  const handleClose = () => {
    setCookies((currentValue) => ({
      ...currentValue,
      [COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT]: "1",
    }));
    document.cookie = cookie.serialize(
      COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT,
      "1",
      {
        path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
        secure: true,
      },
    );
  };

  return (
    <>
      <Wrapper bgColor="orange.100">
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: "1rem", md: "0" }}
          py=".75rem"
          alignItems="center">
          <Box display={{ base: "none", md: "block" }}>
            <Icon icon="ms_warning" color="orange.500" size="24px" />
          </Box>

          <Flex flexDir="column" ml=".75rem" gap="0">
            <Text fontWeight="600" color="gray.700">
              {intl.formatMessage({
                defaultMessage: "Informations fiscales manquantes",
              })}
            </Text>

            <Text color="gray.700">
              {intl.formatMessage({
                defaultMessage:
                  "Ces informations sont indispensables à la gestion de vos réservations, merci de bien vouloir les renseigner.",
              })}
            </Text>
          </Flex>

          <Flex ml={{ base: "0", md: "auto" }} gap="1rem">
            <Button
              backgroundColor="brandPrimary.500"
              color="white"
              _hover={{
                background: "brandPrimary.600",
              }}
              onClick={onOpen}>
              {intl.formatMessage({
                defaultMessage: "Compléter mes informations",
              })}
            </Button>

            <Box cursor="pointer">
              <Button variant="unstyled" onClick={handleClose}>
                <Icon icon="ms_close" color="gray.700" size="24px" />
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Wrapper>

      <DgFipModal
        customer={customer}
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={handleSuccess}
      />
    </>
  );
}
