import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import { useIntl } from "react-intl";
import { Handler } from "./Handler";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} [onClose]
 * @property {() => void} onSuccess
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {boolean} [isClosable]
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export function LegalConditionsModal({
  isOpen,
  onClose,
  onSuccess,
  customer,
  isClosable = false,
}) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      scrollBehavior="inside"
      blockScrollOnMount
      closeOnOverlayClick={isClosable}
      size="2xl">
      <ModalOverlay />

      <ModalContent>
        {isClosable && <ModalCloseButton />}

        <ModalHeader fontWeight="400">
          {intl.formatMessage({
            defaultMessage: "Mise à jour des CGU Clévacances",
          })}
        </ModalHeader>

        <ApiErrorProvider>
          <Handler customer={customer} onSuccess={onSuccess} />
        </ApiErrorProvider>
      </ModalContent>
    </Modal>
  );
}
