export const AUTH_FIELDS = [
  "hotels.rooms",
  "rooms.main_picture",
  "users.customer.warnings",
  "users.customers.email",
  "users.customers.avatar",
  "users.customers.contacts",
  "users.customers.fiscal",
  "users.customers.address",
  "users.customers.hotels",
  "users.customers.missing_attributes",
  "users.customers.rooms_infos",
];
