import { Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import { useConfigurationMenu } from "@raiden/library-ui/hooks/useConfigurationMenu";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Href } from "../../../../../components/Href";
import { useCart } from "../../../../../hooks/useCart";
import { Cart } from "../../../../Cart";
import { MenuItem } from "./MenuItem";

const PopoverMenu = memo(function PopoverMenu() {
  const { userCustomer } = useAuth();

  const { menu: menuTop } = useConfigurationMenu({ alias: "top" });
  const { menu: menuMain } = useConfigurationMenu({ alias: "main" });

  const { cart } = useCart();

  return (
    <>
      <Flex
        position="sticky"
        top="0"
        justify="center"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
        backgroundColor="#fff">
        <Href href={generateFrontPath({ id: "@front.home" })}>
          <Image
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: { filePath: "logo.svg" },
              includeBasePath: true,
            })}
            w="12.5rem"
            py="1rem"
            alt="Clévacances"
            loading="lazy"
          />
        </Href>
      </Flex>

      <Stack flexGrow={1} justifyContent="flex-end" spacing="0.75rem" py="1rem">
        {menuMain?.items?.map((menuItem, index) => {
          return <MenuItem menuItem={menuItem} key={index} />;
        })}

        {cart && <Cart cart={cart} />}

        {menuTop?.items?.map((menuItem, index) => {
          return <MenuItem menuItem={menuItem} key={index} />;
        })}

        <NextLink
          href={generateFrontPath({
            id: userCustomer ? "@front.account" : "@front.signin",
          })}
          passHref>
          <Link color="brandPrimary.500" py="0.5rem" px="1.5rem">
            <Text as="span" color="inherit">
              {userCustomer ? (
                <FormattedMessage defaultMessage="Mon compte" />
              ) : (
                <FormattedMessage defaultMessage="M'identifier" />
              )}
            </Text>
          </Link>
        </NextLink>
      </Stack>
    </>
  );
});

export default PopoverMenu;
