/* istanbul ignore file */

// deps
import { Text, useColorModeValue, VStack } from "@chakra-ui/react";

export default function FilesEditorImageDragAndDropButton(props) {
  const { label, icon, isDisabled = false, ...otherProps } = props;

  const isDisabledColor = useColorModeValue("gray.300", "gray.600");

  return (
    <VStack
      {...otherProps}
      disabled={isDisabled}
      cursor={isDisabled ? "not-allowed" : "pointer"}
      as="button"
      type="button"
      p="1rem"
      w="full"
      spacing=".5rem"
      _hover={!isDisabled && { bgColor: "rgba(0, 0, 0, .1)" }}>
      <Text fontSize="4.6875rem">{icon}</Text>

      <Text
        color={isDisabled ? isDisabledColor : "gray.500"}
        fontWeight="bold"
        textAlign="center">
        {label}
      </Text>
    </VStack>
  );
}
