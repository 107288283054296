/**
 *
 * @param {import("../../../../../types/Customer").CustomerFiscal} fiscal
 * @returns {string}
 */
export function getFiscalNumber(fiscal) {
  if (fiscal?.is_fr && !fiscal?.is_pro) {
    return fiscal?.fiscal_number ?? "";
  }
  return "";
}
