import { LeadsCreateContextProvider } from "../../Leads/Create/Context";
import { LeadsCreateBlock } from "./Block";

/**
 * @param {object} params
 * @param {"label" | "ranking"} params.type
 * @param {boolean} params.isEditor
 */
export function getLeadsCreateBlock({ type, isEditor }) {
  return () => (
    <LeadsCreateContextProvider isEditor={isEditor}>
      <LeadsCreateBlock type={type} />
    </LeadsCreateContextProvider>
  );
}
