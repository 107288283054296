import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { defineMessage } from "react-intl";
import uniqid from "uniqid";

/** @type {import("../components/Menu/MenuItemCore").MenuItemCoreDeclaration[]} */
const _MENU_ITEMS = [
  {
    title: defineMessage({ defaultMessage: "Accueil" }),
    icon: "ms_home",
    href: generateFrontPath({ id: "@front.home" }),
    isHighlighted: true,
    isActive: ({ router }) =>
      router.pathname === generateFrontPath({ id: "@front.home" }),
  },
  {
    title: defineMessage({ defaultMessage: "Chercher" }),
    icon: "ms_search",
    href: generateFrontPath({ id: "@front.search" }),
    isHighlighted: true,
    isActive: ({ router }) =>
      router.pathname === generateFrontPath({ id: "@front.search" }),
  },
  {
    title: defineMessage({ defaultMessage: "Clévacances" }),
    icon: "ms_location_on",
    href: generateFrontPath({ id: "@front.news.search" }),
    isHighlighted: true,
    isActive: ({ router }) =>
      router.pathname === generateFrontPath({ id: "@front.news.search" }),
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../components/Menu/MenuItemCore").MenuItemCoreDeclaration[]} items
 * @param {number} [depth]
 * @returns {import("../components/Menu/MenuItemCore").MenuItemCore[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS_PUBLIC = prepare(_MENU_ITEMS);

export const HIGHLIGHTED_MENU_ITEMS_PUBLIC = MENU_ITEMS_PUBLIC.filter(
  (item) => item.isHighlighted,
);

export const NOT_HIGHLIGHTED_MENU_ITEMS_PUBLIC = MENU_ITEMS_PUBLIC.filter(
  (item) => !item.isHighlighted,
);
