// deps
import { ModalBody, ModalFooter, ButtonGroup, Button } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

// hooks
import useRequest from "../../../hooks/useRequest";
import useCountDown from "../../../hooks/useCountDown";
import { useConfiguration } from "../../../hooks/useConfiguration";

// containers
import Form from "./Form";

// components
import { useApiError } from "../../../components/ReactHookForm/ApiError";

// librairies
import generateApiUrl from "../../../libraries/utils/generateApiUrl";

/**
 * @typedef {object} TwoFaChallengeValues
 * @property {object} data
 * @property {string} data.otp_code
 */

/**
 * @returns {TwoFaChallengeValues}
 */
function getDefaultValues() {
  return {
    data: {
      otp_code: "",
    },
  };
}

/**
 * @typedef Props
 * @property {() => void} onClose
 * @property {(fields) => void} onSubmit
 * @property {import("../../../types/TwoFaChallenge").TwoFaChallenge} resource
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export default function Handler({ onClose, onSubmit, resource }) {
  const intl = useIntl();

  const { configuration } = useConfiguration();
  const delayBetweenAttempts =
    configuration.auth?.two_factor_authentication?.enrollments
      ?.delay_between_attempts;

  const { request, isLoading, toastSuccess } = useRequest();

  const { start, timeLeft } = useCountDown();

  const { resolveApiError, resetApiErrors } = useApiError();

  const form = useForm({
    defaultValues: getDefaultValues(),
  });
  const { handleSubmit } = form;

  function challenge() {
    request(
      generateApiUrl({
        id: "@2fa.challenge",
      }),
      {
        method: "POST",
        body: {
          data: {
            challenge_id: resource.challenge_id,
          },
        },
      },
    )
      .then(() => {
        toastSuccess({
          title: intl.formatMessage({
            defaultMessage:
              "Le code de validation a été envoyé. Vous le recevrez dans quelques instants",
          }),
        });

        resetApiErrors();
        if (delayBetweenAttempts) {
          start(delayBetweenAttempts);
        }
      })
      .catch((response) => {
        resolveApiError({ response, form });
      });
  }

  function submit() {
    handleSubmit((fields) => {
      onSubmit(fields);
    })();
  }

  return (
    <FormProvider {...form}>
      <ModalBody>
        <form
          id={"twoFactorAuthenticationForm"}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate>
          <Form
            resource={resource}
            challenge={challenge}
            isLoading={isLoading}
            timeLeft={timeLeft}
            onSubmit={submit}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="outline" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "Annuler",
            })}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
