/**
 * @param {object} param0
 * @param {import("../../../../types/Customer").CustomerUpdateLegalConditionsDataFormValues} param0.fields
 */
export default function customersSerializeUpdateLegalConditions({ fields }) {
  return {
    data: {
      legal_accepted: fields?.data?.legal_accepted,
    },
  };
}
