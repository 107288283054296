// deps
import PropTypes from "prop-types";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";

export default function ErrorDetail(props) {
  const { detail } = props;

  return (
    <>
      {detail && (
        <Box px="1rem" mt="1rem">
          <Alert status="error">
            <AlertIcon />

            {detail}
          </Alert>
        </Box>
      )}
    </>
  );
}

ErrorDetail.propTypes = {
  detail: PropTypes.string,
};
