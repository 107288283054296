import { Box, Button, LightMode, Text } from "@chakra-ui/react";
import { memo, useCallback, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FormattedMessage } from "react-intl";
import { ApiErrorProvider } from "../../../components/ReactHookForm/ApiError";
import { useLeadsCreateContext } from "../../Leads/Create/Context";
import { LeadsCreateHandler } from "../../Leads/Create/Handler";
import { LeadsCreateForm } from "../../Leads/Form";

export const LeadsCreateBlock = memo(
  /**
   * @typedef {object} Props
   * @property {"label" | "ranking"} type
   */
  /**
   * @param {Props} props
   */
  function LeadsCreateBlock({ type }) {
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSuccess = useCallback(() => {
      setIsSuccess(true);
    }, []);

    const handleResetForm = useCallback(() => {
      setIsSuccess(false);
    }, []);

    const { isEditor } = useLeadsCreateContext();

    return (
      <LightMode>
        <Box position="relative">
          {isSuccess ? (
            <Button
              onClick={handleResetForm}
              variant="outline"
              colorScheme="brandPrimary"
              borderColor="brandPrimary.700">
              <Text variant="button" color="brandPrimary.700">
                <FormattedMessage defaultMessage="Retourner au formulaire" />
              </Text>
            </Button>
          ) : (
            <GoogleReCaptchaProvider
              reCaptchaKey={
                process.env.NEXT_PUBLIC_RECAPTCHA_V3_PUBLIC_KEY ?? ""
              }>
              <ApiErrorProvider>
                <LeadsCreateHandler type={type} onSuccess={handleSuccess}>
                  {({ form, isLoading }) => (
                    <LeadsCreateForm
                      type={type}
                      control={form.control}
                      isLoading={isLoading}
                    />
                  )}
                </LeadsCreateHandler>
              </ApiErrorProvider>
            </GoogleReCaptchaProvider>
          )}
          {isEditor && (
            <Box position="absolute" top="0" left="0" w="full" h="full" />
          )}
        </Box>
      </LightMode>
    );
  },
);
