export const BLOCK_LEAD_FORM_RANKING = {
  type: "lead-form-ranking",
  uid: "",
  allowHorizontalPositioning: true,
  alignment: "flex-start",
  fullWidth: true,
  paddingTop: 0,
  paddingRight: 16,
  paddingLeft: 16,
  paddingBottom: 0,
  backgroundType: "transparent",
  backgroundColor: "#FFFFFF",
  backgroundSrc: "",
  backgroundRepeat: "no-repeat",
  content: {
    intl: {
      fr: {},
    },
  },
};
