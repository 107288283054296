// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const frontBaseUri = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  null,
  null,
  null,
);

export const frontBaseUriWithBasePath = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_FRONT_SCHEME,
  null,
  process.env.NEXT_PUBLIC_FRONT_HOST,
  null,
  process.env.NEXT_PUBLIC_FRONT_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @returns {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default}
 */
function buildRouter(baseUri) {
  const frontRouter = new Router();

  frontRouter.group(baseUri, (frontRouter) => {
    // assets (previewer)
    frontRouter
      .get("/public/<filePath>")
      .setId("@front.assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    frontRouter
      .get("/<filePath>")
      .setId("@front.internal-assets")
      .setConstraint("filePath", /.*/);

    frontRouter.get("/").setId("@front.root");

    frontRouter.group("/<locale>", (frontRouter) => {
      frontRouter.group("/newsgroups", (frontRouter) => {
        frontRouter.group("/subscribers", (frontRouter) => {
          frontRouter.post("/").setId("@front.newsgroups.subscribers");
          frontRouter.group("/<newsgroupSubscriberId>", (frontRouter) => {
            frontRouter.post("/").setId("@front.newsgroups.subscribers.update");
            frontRouter.group("/action", (frontRouter) => {
              frontRouter
                .post("/enable")
                .setId("@front.newsgroups.subscribers.enable");
            });
          });
        });
      });

      frontRouter.get("/").setId("@front.home");
      frontRouter.get("/404").setId("@front.404");
      frontRouter.get("/500").setId("@front.500");
      frontRouter.get("/410").setId("@front.410");
      frontRouter.get("/recherche").setId("@front.search");
      frontRouter.get("/actualites").setId("@front.news.search");
      frontRouter.get("/actualites/<postSlug>").setId("@front.articles.view");
      frontRouter.get("/pages/<postSlug>").setId("@front.page.view");
      frontRouter.get("/faq").setId("@front.faq.search");
      frontRouter.get("/faq/<postSlug>").setId("@front.faq.view");
      frontRouter.get("/destinations").setId("@front.destinations.search");
      frontRouter
        .get("/destinations/<campaignSlug>")
        .setId("@front.destinations.view");
      frontRouter.get("/thematiques").setId("@front.thematics.search");
      frontRouter
        .get("/thematiques/<campaignSlug>")
        .setId("@front.thematics.view");
      frontRouter.get("/regions/<regionSlug>").setId("@front.regions.view");
      frontRouter
        .get("/departements/<departmentSlug>")
        .setId("@front.departments.view");
      frontRouter.get("/hebergement/<hotelSlug>").setId("@front.hotels.view");
      frontRouter.get("/rooms/<roomSlug>").setId("@front.rooms.view");
      frontRouter.get("/offres-speciales").setId("@front.specialOffers");
      frontRouter.get("/contact").setId("@front.contact");

      frontRouter.get("/avis-client").setId("@front.guestReview");

      frontRouter.group("/signin", (frontRouter) => {
        frontRouter.get("/").setId("@front.signin");
        frontRouter.get("/password-recovery").setId("@front.password-recovery");
      });

      frontRouter.group("/account", (frontRouter) => {
        frontRouter.get("/").setId("@front.account");
        frontRouter.get("/actualites").setId("@front.account.news");
        frontRouter
          .get("/actualites/<postSlug>")
          .setId("@front.account.news.view");
        frontRouter
          .get("/rooms-availabilities")
          .setId("@front.account.rooms-availabilities");

        frontRouter.group("/my-account", (frontRouter) => {
          frontRouter.get("/").setId("@front.account.my-account");
          frontRouter
            .get("/personal-informations")
            .setId("@front.account.my-account.personnal-informations");
          frontRouter
            .get("/contact-coordinates")
            .setId("@front.account.my-account.contact-coordinates");
          frontRouter
            .get("/public-profile")
            .setId("@front.account.my-account.public-profile");
          frontRouter
            .get("/login-credentials")
            .setId("@front.account.my-account.login-credentials");
          frontRouter
            .get("/bank-accounts")
            .setId("@front.account.my-account.bank-accounts");
          frontRouter
            .get("/notifications")
            .setId("@front.account.my-account.notifications");
          frontRouter.get("/2fa").setId("@front.account.my-account.2fa");
        });

        frontRouter.group("/hotels", (frontRouter) => {
          frontRouter.get("/").setId("@front.account.hotels");

          frontRouter.group("/<hotelId>", function (frontRouter) {
            frontRouter.get("/").setId("@front.account.hotels.view");
            frontRouter.get("/update").setId("@front.account.hotels.update");
            frontRouter.get("/medias").setId("@front.account.hotels.medias");
            frontRouter
              .get("/configuration")
              .setId("@front.account.hotels.configuration");
            frontRouter
              .get("/contents")
              .setId("@front.account.hotels.contents");
            frontRouter.get("/bookings").setId("@front.account.hotels.booking");
            frontRouter
              .get("/payment-modes")
              .setId("@front.account.hotels.paymentModes");
            frontRouter
              .get("/checkin-checkout")
              .setId("@front.account.hotels.checkinCheckout");

            frontRouter
              .get("/contacts")
              .setId("@front.account.hotels.contacts");

            frontRouter.get("/pets").setId("@front.account.hotels.pets");
            frontRouter
              .get("/booking-online")
              .setId("@front.account.hotels.bookingOnline");
            frontRouter.get("/advance").setId("@front.account.hotels.advance");
            frontRouter
              .get("/amenities")
              .setId("@front.account.hotels.amenities");
            frontRouter
              .get("/room-compositions")
              .setId("@front.account.hotels.roomCompositions");

            frontRouter.group("/rooms", function (frontRouter) {
              frontRouter.group("/<roomId>", function (frontRouter) {
                frontRouter.get("/").setId("@front.account.hotels.rooms.view");
                frontRouter
                  .get("/description")
                  .setId("@front.account.hotels.rooms.description");
                frontRouter
                  .get("/default-prices")
                  .setId("@front.account.hotels.rooms.defaultPrices");
                frontRouter
                  .get("/fiscal-informations")
                  .setId("@front.account.hotels.rooms.fiscalInformations");
                frontRouter
                  .get("/bank-account")
                  .setId("@front.account.hotels.rooms.bankAccount");
                frontRouter
                  .get("/guarantee")
                  .setId("@front.account.hotels.rooms.guarantee");
                frontRouter
                  .get("/tourist-tax")
                  .setId("@front.account.hotels.rooms.touristTax");
                frontRouter
                  .get("/seasons")
                  .setId("@front.account.hotels.rooms.seasons");
                frontRouter
                  .get("/widget")
                  .setId("@front.account.hotels.rooms.widget");
                frontRouter
                  .get("/import-ical")
                  .setId("@front.account.hotels.rooms.importIcal");
                frontRouter
                  .get("/export-ical")
                  .setId("@front.account.hotels.rooms.exportIcal");
                frontRouter
                  .get("/amenities")
                  .setId("@front.account.hotels.rooms.amenities");
                frontRouter
                  .get("/compositions")
                  .setId("@front.account.hotels.rooms.compositions");
                frontRouter
                  .get("/services")
                  .setId("@front.account.hotels.rooms.services");
                frontRouter
                  .get("/discounts")
                  .setId("@front.account.hotels.rooms.discounts");
                frontRouter
                  .get("/documents")
                  .setId("@front.account.hotels.rooms.documents");
                frontRouter
                  .get("/bookings")
                  .setId("@front.account.hotels.rooms.bookings");
                frontRouter
                  .get("/reviews")
                  .setId("@front.account.hotels.rooms.reviews");
                frontRouter
                  .get("/availabilities")
                  .setId("@front.account.hotels.rooms.availabilities");
                frontRouter
                  .get("/visualization")
                  .setId("@front.account.hotels.rooms.visualization");
                frontRouter
                  .get("/metrics")
                  .setId("@front.account.hotels.rooms.metrics");
              });
            });
          });
        });
        frontRouter.group("/services", (frontRouter) => {
          frontRouter.get("/").setId("@front.account.services");
        });
        frontRouter.get("/discounts").setId("@front.account.discounts");
        frontRouter.get("/documents").setId("@front.account.documents");
        frontRouter
          .get("/customer-travelers")
          .setId("@front.account.customerTravelers");

        frontRouter.group("/partners", function (frontRouter) {
          frontRouter.get("").setId("@front.account.partners");
          frontRouter
            .get("/white-label")
            .setId("@front.account.partners.whitelabel");
          frontRouter.group("/subscriptions", function (frontRouter) {
            frontRouter.get("").setId("@front.account.partners.subscriptions");

            frontRouter.group("/customers", function (frontRouter) {
              frontRouter
                .get("")
                .setId("@front.account.partners.subscriptions.customers");
              frontRouter.group(
                "/<subscriptionCustomerId>",
                function (frontRouter) {
                  frontRouter
                    .get("")
                    .setId(
                      "@front.account.partners.subscriptions.customers.view",
                    );
                },
              );
            });
          });
        });

        frontRouter.group("/messages", function (frontRouter) {
          frontRouter.get("/").setId("@front.account.messages");
          frontRouter.group("/<cartId>", function (frontRouter) {
            frontRouter.group("/booking", function (frontRouter) {
              frontRouter.group("/<bookingId>", function (frontRouter) {
                frontRouter
                  .get("/")
                  .setId("@front.account.cart.booking.messages");
              });
            });
          });
        });

        frontRouter.group("/bookings", function (frontRouter) {
          frontRouter.get("/").setId("@front.account.bookings");
        });

        frontRouter.group("/cart", function (frontRouter) {
          frontRouter.group("/<cartId>", function (frontRouter) {
            frontRouter.group("/booking", function (frontRouter) {
              frontRouter.group("/<bookingId>", function (frontRouter) {
                frontRouter.get("/").setId("@front.account.carts.view");
              });
            });
          });
        });

        frontRouter.group("/reviews", function (frontRouter) {
          frontRouter.get("/").setId("@front.account.reviews");

          frontRouter.group("/<reviewId>", function (frontRouter) {
            frontRouter.get("/").setId("@front.account.reviews.view");
          });
        });

        frontRouter.get("/metrics").setId("@front.account.metrics");
      });
    });

    frontRouter.group("/api", (frontRouter) => {
      frontRouter
        .get("/translation-messages")
        .setId("@front.api.translation-messages");
      frontRouter.get("/manifest.json").setId("@front.api.manifest");
      frontRouter.get("/widget").setId("@front.api.widget");
      frontRouter.get("/widget/sncf").setId("@front.api.sncf");
    });
  });

  return frontRouter;
}

export const frontRouter = buildRouter(frontBaseUri);
export const frontRouterWithBasePath = buildRouter(frontBaseUriWithBasePath);
