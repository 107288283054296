/**
 * @param {object} param0
 * @param {import("../../../../types/Customer").CustomerFiscalDataFormValues} param0.fields
 * @param {boolean} [param0.isCustomerFrench]
 */
export default function customersSerializeUpdateFiscal({
  fields,
  isCustomerFrench,
}) {
  const isPro = fields?.data?.is_pro === "1";

  return {
    data: {
      is_pro: fields?.data?.is_pro,
      ...(isPro && {
        person_type: fields?.data?.person_type,
        fiscal_siren: fields?.data?.fiscal_siren,
        fiscal_company: fields?.data?.fiscal_company,
      }),

      ...(!isPro && {
        birthdate: fields?.data?.birthdate,
        fiscal_number: isCustomerFrench
          ? fields?.data?.fiscal_number
          : undefined,
      }),

      first_name: fields?.data?.first_name,
      last_name: fields?.data?.last_name,
      gender: fields?.data?.gender,

      address_1: fields?.data?.address_1,
      address_2: fields?.data?.address_2,
      city: fields?.data?.city,
      country_id: fields?.data?.country_id,
      zipcode: fields?.data?.zipcode,

      rooms: fields?.data?.rooms?.length > 0 ? fields?.data?.rooms : [],
    },
  };
}
