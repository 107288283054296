// libraries
import generatePampaUri from "../../../libraries/utils/generatePampaUri";

/**
 * Récupère la source en fonction du contenu du block de l’éditeur.
 */
export default function filesGetSrc({ content, applyTransforms = true }) {
  switch (content.type) {
    case "files":
      return generatePampaUri({
        // @ts-ignore
        image: {
          pampa_uri: content?.src,
        },
        ...(applyTransforms && {
          mode: "crop",
          transformation: content?.transformations,
        }),
      });

    case "external":
    default:
      return content?.src;
  }
}
