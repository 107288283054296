import { useWatch } from "react-hook-form";

/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @typedef {object} Props
 * @property {import("react-hook-form").Control<TFieldValues>} [control]
 * @property {TName} name
 * @property {(value: import("react-hook-form").FieldPathValue<TFieldValues, TName>) => import("react").ReactElement | null} children
 */
/**
 * @template {import("react-hook-form").FieldValues} TFieldValues
 * @template {import("react-hook-form").FieldPath<TFieldValues>} TName
 * @param {Props<TFieldValues, TName>} props
 */
function FormObserver({ control, name, children }) {
  const value = useWatch({ control, name });

  return children(value);
}

export default FormObserver;
