import { POSTS_RENDERER_TOOLS } from "@raiden/library-ui/constants/editeur";
import { LOCALES_LANG_NAMES } from "@raiden/library-ui/constants/locales";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import {
  ContentRenderer,
  RendererChakra,
} from "@splitfire-agency/raiden-le-editeur/dist/renderers";
import { useMemo } from "react";

/**
 * @typedef {object} Props
 * @property {string | object} [content]
 */
/**
 * @param {Props} props
 */
export function Content({ content }) {
  const { locale, defaultLocale } = useLocale();

  const language = useMemo(() => {
    return {
      name: LOCALES_LANG_NAMES[locale ?? defaultLocale].name,
      key: locale ?? defaultLocale,
    };
  }, [defaultLocale, locale]);

  const document = useMemo(() => {
    return typeof content === "string" ? JSON.parse(content) : content;
  }, [content]);

  return (
    <ContentRenderer
      tools={POSTS_RENDERER_TOOLS}
      language={language}
      renderer={RendererChakra}
      document={document}
    />
  );
}
