import {
  Box,
  Button,
  Center,
  Image,
  Progress,
  Stack,
  Text,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CenteredContent } from "../../../components/CenteredContent";

/**
 * @param {import("../../../types/Layouts").LayoutProps} props
 */
function MaintenanceLayout({ value }) {
  const [isLoading, setIsLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const router = useRouter();

  // call mutate every 10 seconds if the modal is open to check if the maintenance is over
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoading) {
        setProgress((prev) => {
          if (prev < 100) {
            const newValue = prev + 1;
            return newValue;
          }
          if (prev === 100) {
            setIsLoading(true);
            router.reload();
          }
          return prev;
        });
      }
    }, 100);

    return () => {
      clearTimeout(interval);
    };
  }, [isLoading, progress, router]);

  return (
    <Box px="1rem" py="6rem" backgroundColor="gray.100" h="100vh">
      <CenteredContent>
        <Center>
          <Image
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: { filePath: "logo.svg" },
              includeBasePath: true,
            })}
            w="full"
            maxW="300px"
            alt="Clévacances"
            loading="lazy"
          />
        </Center>

        <Stack spacing="1.5rem" textAlign="center" mt="4rem">
          <Image
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: {
                filePath: "illustrations/maintenance.svg",
              },
              includeBasePath: true,
            })}
            w="320px"
            maxWidth="100%"
            margin="auto"
            alt=""
            loading="lazy"
          />

          <Text variant="h3">
            <FormattedMessage defaultMessage="Site en maintenance" />
          </Text>

          <Text variant="h5">
            <FormattedMessage defaultMessage="Notre site est en maintenance, merci de revenir plus tard." />
          </Text>

          <Center>
            <Box borderRadius="10px" overflow="hidden" position="relative">
              <Button
                isLoading={isLoading}
                onClick={useCallback(() => {
                  setIsLoading(true);
                  router.reload();
                }, [router])}
                colorScheme="brandSecondary">
                <Text as="span" color="inherit" variant="button-md">
                  <FormattedMessage defaultMessage="Recharger la page" />
                </Text>
              </Button>

              {!isLoading && (
                <Progress
                  position="absolute"
                  bottom="0"
                  left="0"
                  right="0"
                  value={progress}
                  size="20px"
                  h="4px"
                  colorScheme="brandSecondary"
                  backgroundColor="rgba(255, 255, 255, 0.5)"
                />
              )}
            </Box>
          </Center>
        </Stack>
      </CenteredContent>
    </Box>
  );
}

export default MaintenanceLayout;
