/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const INPUT_THEME = {
  variants: {
    outline: {
      field: {
        borderRadius: "0.625rem",
        _focus: {
          borderColor: "var(--chakra-colors-brandPrimary-500)",
          boxShadow: "0 0 0 1px var(--chakra-colors-brandPrimary-500)",
          zIndex: "initial",
        },
      },
      addon: {
        borderRadius: "0.625rem",
      },
    },
  },
};
