import { Box, Button, Flex, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { Wrapper } from "../Wrapper";
import { Href } from "../../../../../components/Href";
import { useCookies } from "../../../../../hooks/useCookies";
import cookie from "cookie";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import { COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT } from "@raiden/library-ui/constants/cookies";

export function WarningTwoFactorAuthNotActivated() {
  const intl = useIntl();

  const router = useRouter();

  const { setCookies } = useCookies();

  const handleClose = () => {
    setCookies((currentValue) => ({
      ...currentValue,
      [COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT]: "1",
    }));
    document.cookie = cookie.serialize(
      COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT,
      "1",
      {
        path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
        secure: true,
      },
    );
  };

  function handleTwoFactorAuthRedirect() {
    const twoFactorAuthUrl = generateFrontPath({
      id: "@front.account.my-account.2fa",
    });
    router.push(twoFactorAuthUrl);
  }

  return (
    <Wrapper bgColor="blue.100">
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "1rem", md: "0" }}
        py=".75rem"
        alignItems="center">
        <Box display={{ base: "none", md: "block" }}>
          <Icon icon="ms_info" color="brandPrimary.500" size="24px" />
        </Box>

        <Flex flexDir="column" ml=".75rem" gap="0">
          <Text fontWeight="600" color="gray.700">
            {intl.formatMessage({
              defaultMessage:
                "L’authentification à deux facteurs n’est pas activée pour votre compte",
            })}
          </Text>

          <Text color="gray.700">
            {intl.formatMessage({
              defaultMessage:
                "L’authentification à deux facteurs est conseillée pour sécuriser les modifications faites à votre compte et la gestion de vos hébergements.",
            })}
          </Text>
        </Flex>

        <Flex ml={{ base: "0", md: "auto" }} gap="1rem">
          <Href
            href={generateFrontPath({
              id: "@front.account.my-account.2fa",
            })}>
            <Button
              backgroundColor="brandPrimary.500"
              color="white"
              ml={{ base: "0", md: "auto" }}
              _hover={{
                background: "brandPrimary.600",
              }}
              onClick={handleTwoFactorAuthRedirect}>
              {intl.formatMessage({
                defaultMessage: "Activer l’authentification sécurisée",
              })}
            </Button>
          </Href>

          <Box cursor="pointer">
            <Button variant="unstyled" onClick={handleClose}>
              <Icon icon="ms_close" color="gray.700" size="24px" />
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  );
}
