/**
 *
 * @typedef {object} Props
 * @property {string} field
 * @property {string[]} missingAttributes
 * @property {import("react-intl").IntlShape} intl
 * @property {string | import("react").ReactNode | import("react-intl").MessageDescriptor} [message]
 * @property {"customer" | "admin"} [pointOfView]
 */
/**
 * @param {Props} props
 * @returns {string | import("react").ReactNode | import("react-intl").MessageDescriptor}
 */
export default function getAdvisedTextFromMissingAttributes({
  field,
  missingAttributes,
  intl,
  message,
  pointOfView = "customer",
}) {
  if (missingAttributes.includes(field)) {
    return (
      message ??
      intl.formatMessage(
        {
          defaultMessage:
            "{pointOfView, select, customer {Nous vous conseillons de remplir ce champ afin de compléter au mieux votre profil} admin {Nous vous conseillons de remplir ce champ afin de compléter au mieux le profil du propriétaire} other {}}",
        },
        {
          pointOfView,
        },
      )
    );
  }
}
