import { LOCAL_STORAGE_PREFIX } from "../../constants/localStorage";
import { localStorageRemoveItem } from "./removeItem";

/**
 * @param {string} key
 * @returns {any} possibly undefined
 */
export function localStorageGetItem(key) {
  // server-side security
  if (typeof window === "undefined") {
    return undefined;
  }

  const item = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);

  if (!item) {
    return undefined;
  }

  try {
    return JSON.parse(item);
  } catch (error) {
    // If the item is not a valid JSON, remove it from the localStorage
    localStorageRemoveItem(key);
    return undefined;
  }
}
