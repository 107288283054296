import { memo } from "react";
import MenuCore from "../../../../components/Menu/MenuCore";
import MenuItemCore from "../../../../components/Menu/MenuItemCore";
import MenuItemHighlighted from "../Menu/MenuItem/MenuItemHighlighted";

const MenuHighlighted = memo(
  /**
   * @typedef {object} Props
   * @property {import("../../../../components/Menu/MenuItemCore").MenuItemCore[]} items
   * @property {boolean} isOpened
   */
  /**
   * @param {Props} props
   */
  function MenuHighlighted({ items, isOpened }) {
    return (
      <MenuCore
        menuItems={items}
        render={({
          menuItems,
          closeMenuItem,
          openMenuItem,
          openedMenuItemsIds,
        }) => (
          <>
            {menuItems?.map?.((item) => (
              <MenuItemCore
                item={item}
                openedMenuItemsIds={openedMenuItemsIds}
                openMenuItem={openMenuItem}
                closeMenuItem={closeMenuItem}
                render={(params) => (
                  <MenuItemHighlighted {...params} isOpened={isOpened} />
                )}
                key={item.id}
              />
            ))}
          </>
        )}
      />
    );
  },
);

export default MenuHighlighted;
