import { Alert, AlertDescription, Box } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import { Icon } from "@raiden/library-ui/components/Icon";
import { PasswordForm } from "../../../components/Password/Form";

export default function Form() {
  return (
    <Box px="1rem">
      <ApiErrorMessage />

      <Alert status="info" mb="1rem">
        <Box mr=".5rem">
          <Icon icon="ms_info" color="brandPrimary.500" size="24px" />
        </Box>

        <AlertDescription>
          <FormattedMessage defaultMessage="Votre mot de passe temporaire est expiré, veuillez choisir votre nouveau mot de passe de connexion." />
        </AlertDescription>
      </Alert>

      <PasswordForm />
    </Box>
  );
}
