export const MAX_PER_PAGE = 250;

export const DEFAULT_PER_PAGE = 25;

export const PER_PAGES = [25, 50, 100];

export const DEFAULT_CONFIGURATION = {
  environments: [],
  departments: {},
  subscriptions: [],
};
