/**
 * @typedef {"text" | "link" | "page" | "article" | undefined} MenuItemAdapterType
 */

import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";

/**
 * @typedef {object} MenuItemAdapterValues
 * @property {MenuItemAdapterType} type
 * @property {string} title
 * @property {string | undefined} href
 * @property {"_blank" | undefined} target
 * @property {string | null} role
 * @property {string | null} icon
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem[]} children
 */

/**
 * @param {object} params
 * @param {MenuItemAdapterType} params.type
 * @param {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} params.menuItem
 * @param {(obj: import("@raiden/library-ui/types/common/TranslatedField").TranslatedField | undefined) => string | undefined} params.translate
 * @returns {string | undefined}
 */
function getMenuItemAdapterHref({ type, menuItem, translate }) {
  if (type === "text") {
    return undefined;
  }
  if (type === "link") {
    const url = translate(menuItem.url);
    const urlWithoutQuery = url?.split("?")[0];
    if (!url?.startsWith("http") && urlWithoutQuery?.includes(".")) {
      return `https://${url}`;
    }
    return url;
  }
  if (type === "page") {
    return generateFrontPath({
      id: "@front.page.view",
      parameters: {
        postSlug: `${menuItem.object_id}-${translate(menuItem.object?.slug)}`,
      },
    });
  }
}

/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} params.menuItem
 * @returns {MenuItemAdapterType}
 */
export function getMenuItemAdapterType({ menuItem }) {
  if (menuItem.type === "link" && Object.keys(menuItem.url).length === 0) {
    return "text";
  }
  if (menuItem.type === "link") {
    return "link";
  }
  if (
    menuItem.type === "object" &&
    menuItem.object_type === "post" &&
    menuItem.object?.type === "page"
  ) {
    return "page";
  }
}

/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} params.menuItem
 * @param {(obj: import("@raiden/library-ui/types/common/TranslatedField").TranslatedField | undefined) => string | undefined} params.translate
 * @returns {MenuItemAdapterValues}
 */
export function getMenuItemAdapterValues({ menuItem, translate }) {
  const type = getMenuItemAdapterType({ menuItem });

  const href = getMenuItemAdapterHref({ type, menuItem, translate });

  return {
    type,
    title: translate(menuItem.title) ?? "-Titre manquant-",
    href,
    target: href?.startsWith("http") ? "_blank" : undefined,
    role: menuItem.role,
    icon: menuItem.icon,
    children: menuItem.children,
  };
}
