import { LOCAL_STORAGE_PREFIX } from "../../constants/localStorage";

/**
 * @param {string} key
 * @returns {any}
 */
export function localStorageRemoveItem(key) {
  // server-side security
  if (typeof window === "undefined") {
    return;
  }

  localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${key}`);
}
