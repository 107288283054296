import { useCallback } from "react";
import useSWR from "swr";
import { SWR_CONFIG_FOR_STATIC_DATA } from "../constants/swr/swrConfigForStaticData";
import useApiFetcher from "./useApiFetcher";

/**
 * @template T,[M=object]
 * @typedef {{swrResponse: import("swr").SWRResponse<import("../types/Api/ApiResponse").ApiResponse<T, M>>}} UseApi
 **/

/**
 * @template T,[M=object]
 * @param {string | Array | null} url
 * @param {object} [options]
 * @param {"GET" | "POST" | "PUT" | "DELETE"} [options.method]
 * @param {any} [options.body]
 * @param {Partial<import("swr/_internal").PublicConfiguration<import("../types/Api/ApiResponse").ApiResponse<T>>>} [options.swrConfig]
 * @returns {UseApi<T,M>}
 **/

export function useApi(url, options = {}) {
  const apiFetcher = useApiFetcher();

  const fetcher = useCallback(
    (url) => {
      return apiFetcher(Array.isArray(url) ? url[0] : url, {
        method: options.method ?? "GET",
        body: options.body,
      });
    },
    [apiFetcher, options.body, options.method],
  );

  const swrResponse =
    /** @type {import("swr").SWRResponse<import("../types/Api/ApiResponse").ApiResponse<T, M>>} */ (
      useSWR(url, fetcher, {
        ...SWR_CONFIG_FOR_STATIC_DATA,
        ...options.swrConfig,
      })
    );

  return { swrResponse };
}
