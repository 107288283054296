import useAuth from "@raiden/library-ui/hooks/useAuth";
import useCanPerform from "@raiden/library-ui/hooks/useCanPerform";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { isActivePath } from "./MenuItemCore";

// TODO test

/**
 * Trim menu items
 * @param {import("./MenuItemCore").MenuItemCore[]} menuItems
 * @param {{
 * canPerform: (params: { authorizations: string | string[]}) => boolean,
 * isRoot: boolean
 * userCustomer: import("@raiden/library-ui/types/Customer").Customer | null
 * }} param1
 * @param {number} [depth]
 * @returns {import("./MenuItemCore").MenuItemCore[]}
 */
export function prepare(
  menuItems = [],
  { canPerform, isRoot, userCustomer },
  depth = 0,
) {
  const trimmed = [];
  menuItems?.forEach((menuItem) => {
    const trimmedChildren = prepare(menuItem.children, {
      canPerform,
      isRoot,
      userCustomer,
    });
    if (
      (menuItem?.validator?.({ canPerform, isRoot, userCustomer }) ?? true) &&
      ((menuItem?.href ?? "")?.length > 0 ||
        typeof menuItem?.onClick === "function" ||
        trimmedChildren.length > 0)
    ) {
      trimmed.push({
        ...menuItem,
        children: prepare(
          menuItem.children,
          { canPerform, isRoot, userCustomer },
          depth + 1,
        ),
      });
    }
  });
  return trimmed;
}

/**
 * @param {object} options
 * @param {import("next/router").NextRouter} options.router
 * @param {string} options.pathWithoutQueryParams
 * @param {import("./MenuItemCore").MenuItemCore[]} options.menuItems
 * @returns {string[]}
 */
function getInitialOpenedMenuItemsIds({
  router,
  pathWithoutQueryParams,
  menuItems = [],
}) {
  /** @type {string[]} */
  const openedMenuItemsIds = [];
  menuItems?.forEach((item) => {
    const isActive = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
    });
    const isActiveDeep = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
      children: item.children,
    });
    if ((item.children ?? []).length > 0 && (isActive || isActiveDeep)) {
      openedMenuItemsIds.push(item.id);
    }
  });
  return openedMenuItemsIds;
}

/**
 * @typedef {object} Props
 * @property {import("./MenuItemCore").MenuItemCore[]} menuItems
 * @property {(params: { menuItems: import("./MenuItemCore").MenuItemCore[], openedMenuItemsIds: string[], openMenuItem: (item: import("./MenuItemCore").MenuItemCore) => void, closeMenuItem: (item: import("./MenuItemCore").MenuItemCore) => void  }) => import("react").ReactElement} render
 */
/**
 * @param {Props} props
 */
function MenuCore({ menuItems: _menuItems, render }) {
  const router = useRouter();

  const canPerform = useCanPerform();

  const { isRoot, userCustomer } = useAuth();

  const menuItems = useMemo(() => {
    return prepare(_menuItems, {
      canPerform,
      isRoot,
      userCustomer: userCustomer ?? null,
    });
  }, [_menuItems, canPerform, isRoot, userCustomer]);

  const [openedMenuItemsIds, setOpenedMenuItemsIds] = useState(() => {
    const pathWithoutQueryParams = router.asPath?.split?.(/[?#]/)[0];
    return getInitialOpenedMenuItemsIds({
      menuItems,
      router,
      pathWithoutQueryParams,
    });
  });

  const openMenuItem = useCallback(
    /** @param {import("./MenuItemCore").MenuItemCore} item */
    (item) => {
      if (item.depth === 0) {
        setOpenedMenuItemsIds([item.id]);
      } else {
        setOpenedMenuItemsIds((openedMenuItems) => [
          ...openedMenuItems,
          item.id,
        ]);
      }
    },
    [],
  );

  const closeMenuItem = useCallback(
    /** @param {import("./MenuItemCore").MenuItemCore} item */
    (item) => {
      setOpenedMenuItemsIds((openedMenuItems) => {
        const newOpenedMenuItems = [...openedMenuItems];
        const index = newOpenedMenuItems.indexOf(item.id);
        if (index > -1) {
          newOpenedMenuItems.splice(index, 1);
        }
        return newOpenedMenuItems;
      });
    },
    [],
  );

  return render({ menuItems, openedMenuItemsIds, openMenuItem, closeMenuItem });
}

export default MenuCore;
