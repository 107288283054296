/**
 * Get the completion percentage of the form
 * @typedef {object} Props
 * @property {Array<string>} formFields
 * @property {Array<string>} missingAttributes
 */

/**
 * @param {Props} props
 * @returns {number}
 */
export function getCompletionPercentage({ formFields, missingAttributes }) {
  if (formFields.length === 0) {
    return 0;
  }

  const missingFields = formFields.filter((field) =>
    missingAttributes.find((attr) => attr === field),
  );

  return Math.round(
    ((formFields.length - missingFields.length) / formFields.length) * 100,
  );
}
