import {
  Alert,
  AlertDescription,
  Box,
  Flex,
  Link,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import { Icon } from "@raiden/library-ui/components/Icon";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { PostsContentView } from "../../Posts/ContentView";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { Splash } from "../../../components/Splash";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { getPostSlug } from "@raiden/library-ui/helpers/posts/getSlug";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";

/**
 * @param {object} param0
 * @param {string} [param0.postAlias]
 * @returns {string}
 */
function getPostUrl({ postAlias }) {
  return generateApiUrl({
    id: "@posts.show",
    parameters: {
      postId: postAlias,
    },
    query: {
      fields: ["post.current_revision"],
    },
  });
}

export function Form() {
  const translate = useTranslate();

  const intl = useIntl();

  const ownerCgvAlias = process.env.NEXT_PUBLIC_POST_PAGE_OWNER_CGU_ALIAS;

  const charterAlias = process.env.NEXT_PUBLIC_POST_PAGE_OWNER_CHARTE_ALIAS;

  const wikiCharterFallbackUrl =
    "https://wiki.clevacances.com/wp-content/uploads/Charte_qualite_10_2022_remplissable-1.pdf";

  const { defaultLocale, locale } = useLocale();

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post>} */
  const { swrResponse: swrResponsePost } = useApi(
    getPostUrl({ postAlias: ownerCgvAlias }),
  );

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post>} */
  const { swrResponse: swrResponsePostCharter } = useApi(
    getPostUrl({ postAlias: charterAlias }),
  );

  const postCharter = swrResponsePostCharter?.data?.data ?? null;

  return (
    <Stack spacing="1rem">
      <ApiErrorMessage />

      <DataHandler
        swrResponse={swrResponsePost}
        keepDataOnRevalidation={true}
        noResult={
          <Flex alignItems="center" flexDir="column" gap="1rem" mx="auto">
            <Box width={{ base: "200px", lg: "700px" }}>
              <Splash
                src={generateFrontPath({
                  id: "@front.internal-assets",
                  parameters: { filePath: "illustrations/error.svg" },
                  includeBasePath: true,
                })}
                title={intl.formatMessage({
                  defaultMessage:
                    "Erreur lors du chargement des Conditions Générales de Ventes",
                })}
              />
            </Box>
          </Flex>
        }>
        {({ data: postCgv }) => (
          <>
            {postCgv?.current_revision?.summary && (
              <Alert status="info" mb="1rem">
                <Box mr=".5rem">
                  <Icon icon="ms_info" color="brandPrimary.500" size="24px" />
                </Box>

                <AlertDescription>
                  {translate(postCgv?.current_revision?.summary)}
                </AlertDescription>
              </Alert>
            )}

            {postCgv?.current_revision?.title && (
              <Text variant="h4">
                {translate(postCgv?.current_revision?.title)}
              </Text>
            )}

            <Box maxHeight="425px" overflowY="auto">
              <PostsContentView post={postCgv ?? undefined} />
            </Box>
          </>
        )}
      </DataHandler>

      <FormControlRHF
        mt="auto"
        name="data.legal_accepted"
        renderWithFormControl={(fields) => (
          <Switch
            display="flex"
            alignItems="center"
            isChecked={fields.value}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="4px"
            lineHeight="normal"
            p=".5rem"
            {...fields}
            value={"1"}>
            <FormattedMessage
              defaultMessage="Je reconnais avoir pris connaissance des nouvelles Conditions Générales d'Utilisation ainsi que de la <link>charte CléVacances</link> et en accepte les termes."
              values={{
                link: (chunks) => (
                  <Link
                    href={
                      postCharter
                        ? generateFrontUrl({
                            id: "@front.page.view",
                            parameters: {
                              postSlug: getPostSlug({
                                post: postCharter,
                                locale,
                                defaultLocale,
                              }),
                            },
                            includeBasePath: true,
                          })
                        : wikiCharterFallbackUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                    textDecoration="underline">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Switch>
        )}
      />
    </Stack>
  );
}
