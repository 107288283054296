import { Box, HStack, Link } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { Href } from "../../../../../components/Href";
import { Icon } from "@raiden/library-ui/components/Icon";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @param {object} params
 * @param {string | null} params.role
 * @returns {import("@chakra-ui/react").BoxProps}
 */
function getMenuItemProps({ role }) {
  /** @type {import("@chakra-ui/react").BoxProps} */
  const DEFAULT_PROPS = {
    py: "8px",
    px: "1.5rem",
    color: "brandPrimary.500",
  };
  switch (role) {
    case "highlighted": {
      return {
        ...DEFAULT_PROPS,
        color: "#fff",
        backgroundColor: "brandPrimary.500",
      };
    }

    default: {
      return DEFAULT_PROPS;
    }
  }
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} menuItem
 */
/**
 * @param {Props} props
 */
export function MenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target, role, icon } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  const item = (
    <HStack spacing="0.625rem">
      {icon && <Icon icon={icon} />}

      <Box flexGrow={1} {...getMenuItemProps({ role })}>
        {title}
      </Box>
    </HStack>
  );

  return (
    <Box>
      {href !== undefined ? (
        <Href href={href} target={target}>
          <Link as="span" color="brandPrimary.500" whiteSpace="nowrap">
            {item}
          </Link>
        </Href>
      ) : (
        <Box whiteSpace="nowrap">{item}</Box>
      )}

      {menuItem.children.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </Box>
  );
}
