import { Box } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { Href } from "../../../../components/Href";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @param {object} params
 * @param {string | null} params.role
 * @returns {import("@chakra-ui/react").BoxProps}
 */
function getMenuItemProps({ role }) {
  switch (role) {
    case "category": {
      return {
        pb: "0.75rem",
        fontSize: "1.25rem",
        fontWeight: 400,
        letterSpacing: "0.4px",
      };
    }

    default: {
      return {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
      };
    }
  }
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} menuItem
 */
/**
 * @param {Props} props
 */
export function MenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target, role } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  const item = <Box {...getMenuItemProps({ role })}>{title}</Box>;

  return (
    <Box>
      {href !== undefined ? (
        <Href href={href} target={target}>
          <Box whiteSpace="nowrap">{item}</Box>
        </Href>
      ) : (
        <Box whiteSpace="nowrap">{item}</Box>
      )}

      {menuItem.children.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </Box>
  );
}
