import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

/**
 * @typedef {(params: {
 * pathWithoutQueryParams: string,
 * router: import("next/router").NextRouter,
 * }) => boolean} isActiveFunction
 */

/**
 * Determines if a path is active or not.
 *
 * Supports active deep detection if `children` is provided.
 * @param {object} param0
 * @param {isActiveFunction} [param0.isActive]
 * @param {import("next/router").NextRouter} param0.router
 * @param {string} param0.pathWithoutQueryParams
 * @param {string} [param0.href]
 * @param {MenuItemCore[]} [param0.children]
 * @returns {boolean}
 */
export function isActivePath({
  isActive,
  router,
  pathWithoutQueryParams,
  href = "",
  children = [],
}) {
  if ((href.length ?? "") > 0) {
    // use custom activation function if provided
    if (isActive !== undefined) {
      if (isActive({ pathWithoutQueryParams, router })) {
        return true;
      }
    } else {
      if (pathWithoutQueryParams?.startsWith?.(href.split?.(/[?#]/)[0])) {
        return true;
      }
    }
  }
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (
      isActivePath({
        isActive: child.isActive,
        router,
        pathWithoutQueryParams,
        href: child.href,
        children: child.children,
      })
    ) {
      return true;
    }
  }
  return false;
}

/**
 * @typedef {{
 * title: {defaultMessage: string},
 * description?: {defaultMessage: string},
 * icon?: string,
 * href?: string,
 * target?: import("react").HTMLAttributeAnchorTarget,
 * onClick?: () => void,
 * validator?: (params: {isRoot: boolean, canPerform: (params: { authorizations: string | string[]}) => boolean, userCustomer: import("@raiden/library-ui/types/Customer").Customer | null}) => boolean,
 * depth?: number,
 * isActive?: isActiveFunction,
 * isHighlighted?: boolean,
 * children?: MenuItemCoreDeclaration[],
 * }} MenuItemCoreDeclaration
 */
/**
 * @typedef {{
 * id: string,
 * title: {defaultMessage: string},
 * description?: {defaultMessage: string},
 * icon?: string,
 * href?: string,
 * target?: import("react").HTMLAttributeAnchorTarget,
 * onClick?: () => void,
 * validator?: (params: {isRoot: boolean, canPerform: (params: { authorizations: string | string[]}) => boolean, userCustomer: import("@raiden/library-ui/types/Customer").Customer | null}) => boolean,
 * depth?: number,
 * isActive?: isActiveFunction,
 * isHighlighted?: boolean,
 * children?: MenuItemCore[],
 * }} MenuItemCore
 */
/**
 * @param {{
 * item: MenuItemCore,
 * openedMenuItemsIds: string[],
 * openMenuItem: (Item) => void,
 * closeMenuItem: (Item) => void,
 * render: (params: {item: MenuItemCore, isActive: boolean, isActiveDeep: boolean, isOpened: boolean, handleClickChevron: () => void, openedMenuItemsIds: string[], openMenuItem: (Item) => void, closeMenuItem: (Item) => void}) => import("react").ReactElement,
 * }} props
 */
function MenuItemCore({
  item,
  openedMenuItemsIds,
  openMenuItem,
  closeMenuItem,
  render,
}) {
  const router = useRouter();

  const { isActive, isActiveDeep } = useMemo(() => {
    const pathWithoutQueryParams = router.asPath?.split?.(/[?#]/)[0];
    return {
      isActive: isActivePath({
        isActive: item.isActive,
        router,
        pathWithoutQueryParams,
        href: item.href ?? "",
      }),
      isActiveDeep: isActivePath({
        isActive: item.isActive,
        router,
        pathWithoutQueryParams,
        href: item.href ?? "",
        children: item.children,
      }),
    };
  }, [item.isActive, item.href, item.children, router]);

  const isOpened = openedMenuItemsIds?.includes?.(item.id);

  const handleClickChevron = useCallback(() => {
    isOpened ? closeMenuItem(item) : openMenuItem(item);
  }, [closeMenuItem, isOpened, openMenuItem, item]);

  return render({
    item,
    closeMenuItem,
    handleClickChevron,
    isActive,
    isActiveDeep,
    isOpened,
    openMenuItem,
    openedMenuItemsIds,
  });
}

export default MenuItemCore;
