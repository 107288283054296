import { Tag, TagLabel } from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} Props
 * @property {string} label
 * @property {boolean} condition
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export function PasswordTag({ label, condition }) {
  return (
    <Tag
      size="sm"
      variant="outline"
      colorScheme={condition ? "green" : "gray"}
      borderRadius="12px">
      {condition && <Icon icon="ms_done" />}

      <TagLabel ml=".25rem">{label}</TagLabel>
    </Tag>
  );
}
