/**
 * Retourne la liste des rooms à partir d'une liste d'hotels
 * @param {import("../../../types/Hotel").Hotel[]} hotelList
 * @returns {import("../../../types/Room").Room[] | []}
 */

export default function getRoomList(hotelList) {
  const list = [];

  if (!hotelList?.length) {
    return list;
  }

  hotelList?.forEach((hotel) => {
    hotel?.rooms?.forEach((room) => {
      list.push(room);
    });
  });

  return list;
}
