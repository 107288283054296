import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMaintenanceMode } from "@raiden/library-ui/contexts/MaintenanceMode";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

export function MaintenanceModal() {
  const { isOpen, onClose } = useMaintenanceMode();

  const { mutate, isValidating } = useAuth();

  // call mutate every 10 seconds if the modal is open to check if the maintenance is over
  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        mutate();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isOpen, mutate]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered={true}>
      <ModalOverlay />

      <ModalContent mx="1rem">
        <ModalHeader>
          <FormattedMessage defaultMessage="Maintenance en cours" />
        </ModalHeader>

        <ModalBody>
          <Stack spacing="1rem">
            <Text>
              <FormattedMessage defaultMessage="Nous sommes désolés, une maintenance est en cours. Merci d'attendre quelques instants." />
            </Text>

            <Text variant="text-xs" colorScheme="gray">
              <FormattedMessage defaultMessage="Cette fenêtre se fermera automatiquement dès que la maintenance sera terminée." />
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              mutate();
            }}
            colorScheme="brandSecondary"
            isLoading={isValidating}>
            <FormattedMessage defaultMessage="Recharger" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
