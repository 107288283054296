import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import NextLink from "next/link";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} Props
 * @property {import("../../../../../components/Menu/MenuItemCore").MenuItemCore} item
 * @property {boolean} isActive
 * @property {boolean} isActiveDeep
 * @property {boolean} isOpened
 */
/**
 * @param {Props} props
 */
function MenuItemHighlighted({ item, isActive, isActiveDeep, isOpened }) {
  const intl = useIntl();

  const color = useColorModeValue("#002538", "#fff");
  const highlightedColor = useColorModeValue("#002538", "#fff");

  const isHighligthed = (isActive || isActiveDeep) && !isOpened;

  return (
    <NextLink href={item.href ?? ""} passHref>
      <Box flexGrow={1} flexBasis={0} position="relative">
        <Box
          position="absolute"
          left="50%"
          h="2px"
          w="full"
          backgroundColor="brandSecondary.500"
          pointerEvents="none"
          opacity={isHighligthed ? 1 : 0}
          transform={`translateX(-50%) scaleX(${isHighligthed ? 1 : 0})`}
          transition="all .25s ease"
        />

        <Flex
          as="a"
          direction="column"
          position="relative"
          target={item.target}
          justifyItems="stretch"
          alignItems="center"
          color={isHighligthed ? highlightedColor : color}
          overflow="hidden"
          py="0.5rem"
          role="group">
          {item.icon && (
            <Box borderRadius="6px" px="0.75rem" py="0.125rem">
              <Icon
                icon={item.icon}
                color={isHighligthed ? highlightedColor : color}
                size="2rem"
              />
            </Box>
          )}

          <Text
            as="span"
            variant="text-xs"
            color="inherit"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxW="100%"
            mt="0.375rem"
            _groupHover={{
              textDecoration: { lg: "underline" },
            }}>
            {intl.formatMessage(item.title)}
          </Text>
        </Flex>
      </Box>
    </NextLink>
  );
}

export default MenuItemHighlighted;
