import Data from "./Data";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {(fields) => void} onSubmit
 * @property {import("../../../types/TwoFaChallenge").TwoFaChallenge} resource
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export default function Create({ isOpen, onClose, onSubmit, resource }) {
  return (
    <Data
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      resource={resource}
    />
  );
}
