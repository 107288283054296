import { Button } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import { useIntl } from "react-intl";

function SignOutButton() {
  const intl = useIntl();

  const { toastSuccess } = useRequest();

  const { mutate, logout } = useAuth();

  function handleClick() {
    logout().then(function () {
      toastSuccess({
        status: "success",
        title: intl.formatMessage({
          defaultMessage: "Déconnexion réussie",
        }),
      });
      mutate();
    });
  }

  return (
    <Button onClick={handleClick} colorScheme="red" width="full">
      {intl.formatMessage({ defaultMessage: "Déconnexion" })}
    </Button>
  );
}

export default SignOutButton;
