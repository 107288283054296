import { Box, Button, Flex, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { Href } from "../../../../../components/Href";
import { Icon } from "@raiden/library-ui/components/Icon";
import { getColorFromCompletionPercentage } from "@raiden/library-ui/helpers/customers/getColorFromCompletionPercentage";
import { Wrapper } from "../Wrapper";
import { useCookies } from "../../../../../hooks/useCookies";
import cookie from "cookie";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import { COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT } from "@raiden/library-ui/constants/cookies";

/**
 * @typedef Props
 * @property {number} completionPercentage
 */

/**
 * @param {Props} props
 */
export function WarningMissingAttributes({ completionPercentage }) {
  const intl = useIntl();

  const { setCookies } = useCookies();

  const handleClose = () => {
    setCookies((currentValue) => ({
      ...currentValue,
      [COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT]: "1",
    }));
    document.cookie = cookie.serialize(
      COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT,
      "1",
      {
        path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
        secure: true,
      },
    );
  };

  return (
    <>
      <Wrapper
        bgColor={`${getColorFromCompletionPercentage({
          percentage: completionPercentage,
        })}.100`}>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: "1rem", md: "0" }}
          py=".75rem"
          alignItems="center">
          <Box display={{ base: "none", md: "block" }}>
            <Icon
              icon={completionPercentage >= 50 ? "ms_info" : "ms_warning"}
              color={`${getColorFromCompletionPercentage({
                percentage: completionPercentage,
              })}.500`}
              size="24px"
            />
          </Box>

          <Flex flexDir="column" ml=".75rem" gap="0">
            <Text fontWeight="600" color="gray.700">
              {intl.formatMessage(
                {
                  defaultMessage: "Votre profil est complet à {percentage} %",
                },
                { percentage: completionPercentage },
              )}
            </Text>

            <Text color="gray.700">
              {completionPercentage >= 50
                ? intl.formatMessage({
                    defaultMessage:
                      "Afin d’améliorer votre expérience, nous vous conseillons de le compléter en vous rendant dans votre compte.",
                  })
                : intl.formatMessage({
                    defaultMessage:
                      "Certaines informations peuvent être indispensables à la gestion de votre compte, nous vous conseillons de les compléter.",
                  })}
            </Text>
          </Flex>

          <Flex ml={{ base: "0", md: "auto" }} gap="1rem">
            <Href
              href={generateFrontPath({
                id: "@front.account.my-account",
              })}>
              <Button
                backgroundColor="brandPrimary.500"
                color="white"
                _hover={{
                  background: "brandPrimary.600",
                }}>
                {intl.formatMessage({
                  defaultMessage: "Mettre à jour mes informations",
                })}
              </Button>
            </Href>

            <Box cursor="pointer">
              <Button variant="unstyled" onClick={handleClose}>
                <Icon icon="ms_close" color="gray.700" size="24px" />
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
}
