import { Image, Text, VStack } from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library-ui/components/ImageLoaderHandler";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {string} [src]
 * @property {string} [description]
 * @property {string} [imgMaxWidth]
 * @property {React.ReactNode} [addon]
 */
/**
 * @param {Props} props
 */
export function Splash({ title, src, description, addon, imgMaxWidth }) {
  return (
    <VStack spacing="0.75rem">
      {src && (
        <ImageLoaderHandler src={src}>
          {({ ref, src, isLoaded, handleLoad }) => (
            <Image
              ref={ref}
              src={src}
              maxWidth={imgMaxWidth}
              opacity={isLoaded ? 1 : 0}
              onLoad={handleLoad}
              transition="opacity .25s ease"
              alt=""
            />
          )}
        </ImageLoaderHandler>
      )}

      <Text variant="h3" textAlign="center">
        {title}
      </Text>

      {description && (
        <Text variant="h5" textAlign="center" color="gray.500">
          {description}
        </Text>
      )}

      {addon && addon}
    </VStack>
  );
}
