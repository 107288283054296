/**
 * Check if the selected country is France
 * @param {object} params
 * @param {Array<import("../../../types/Country").Country>} params.countries
 * @param {number} params.selectedCountryId
 */

export default function isSelectedCountryFrance({
  countries,
  selectedCountryId,
}) {
  return (
    countries?.find((country) => country.iso_code === "FR")?.id ===
    selectedCountryId
  );
}
