// constants
import {
  POLICIES_POLICY_VALUE_ATTACH_DEPARTMENT,
  POLICIES_POLICY_VALUE_QUOTAS,
  POLICIES_POLICY_VALUE_MERGE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
  POLICIES_POLICY_VALUE_DELETE,
} from "./policies";

import { defineMessage } from "react-intl";

/* Tri */
export const ENVIRONMENTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const ENVIRONMENTS_SORT_VALUE_CREATED_DESC = "created_desc";
export const ENVIRONMENTS_SORT_VALUE_NAME_ASC = "name_asc";
export const ENVIRONMENTS_SORT_VALUE_NAME_DESC = "name_desc";

export const environmentsSortMessage = defineMessage({
  id: "raiden.library.constants.environments.sort",
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} name_asc {Nom croissant} name_desc {Nom décroissant} other {{sort}}}",
});

export const ENVIRONMENTS_SORTS = {
  [ENVIRONMENTS_SORT_VALUE_CREATED_ASC]: {
    id: ENVIRONMENTS_SORT_VALUE_CREATED_ASC,
  },
  [ENVIRONMENTS_SORT_VALUE_CREATED_DESC]: {
    id: ENVIRONMENTS_SORT_VALUE_CREATED_DESC,
  },
  [ENVIRONMENTS_SORT_VALUE_NAME_ASC]: {
    id: ENVIRONMENTS_SORT_VALUE_NAME_ASC,
  },
  [ENVIRONMENTS_SORT_VALUE_NAME_DESC]: {
    id: ENVIRONMENTS_SORT_VALUE_NAME_DESC,
  },
};

export const ENVIRONMENTS_SORT_LIST = Object.values(ENVIRONMENTS_SORTS);

const ENVIRONMENTS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};

const ENVIRONMENTS_SEARCH_TABS_VALUE_TRASH = {
  value: "trash",
  fields: [{ name: "only_trashed", value: 1 }],
  defaultFields: [{ name: "only_trashed", value: undefined }],
  colors: ["red.600", "red.400"],
  metaKey: null,
};

export const ENVIRONMENTS_SEARCH_TABS = [
  ENVIRONMENTS_SEARCH_TABS_VALUE_ALL,
  ENVIRONMENTS_SEARCH_TABS_VALUE_TRASH,
];

export const ENVIRONMENTS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage: "{tab, select, all {Tous} trash {Archive} other {{tab}}}",
  description: "tab",
});

/* Type */
export const ENVIRONMENTS_QUOTAS_TYPE_VALUE_SMS = "sms";
export const ENVIRONMENTS_QUOTAS_TYPE_VALUE_EMAIL = "email";

export const environmentsQuotasTypeMessage = defineMessage({
  defaultMessage: "{type, select, sms {SMS} email {E-mail} other {{type}}}",
});

export const environmentsQuotasTypeCountMessage = defineMessage({
  defaultMessage:
    "{type, select, sms {Nombre de SMS} email {Nombre d’e-mail} other {{type}}}",
});

export const ENVIRONMENTS_QUOTAS_TYPES = {
  [ENVIRONMENTS_QUOTAS_TYPE_VALUE_SMS]: {
    id: ENVIRONMENTS_QUOTAS_TYPE_VALUE_SMS,
  },
  [ENVIRONMENTS_QUOTAS_TYPE_VALUE_EMAIL]: {
    id: ENVIRONMENTS_QUOTAS_TYPE_VALUE_EMAIL,
  },
};

export const ENVIRONMENTS_QUOTAS_TYPE_LIST = Object.values(
  ENVIRONMENTS_QUOTAS_TYPES,
);

/**
 *
 */

export const ENVIRONMENTS_POLICIES_ACTIONS = {
  attachDepartement: {
    policies: POLICIES_POLICY_VALUE_ATTACH_DEPARTMENT,
  },
  quotas: {
    policies: POLICIES_POLICY_VALUE_QUOTAS,
  },
  merge: {
    policies: POLICIES_POLICY_VALUE_MERGE,
  },
  restore: {
    policies: POLICIES_POLICY_VALUE_RESTORE,
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_DELETE,
      POLICIES_POLICY_VALUE_SOFT_DELETE,
    ],
  },
};

/** @type {number} */
export const DEFAULT_ENVIRONMENT_ID = 1;
