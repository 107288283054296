import { extendTheme } from "@chakra-ui/react";
import { ALERT_THEME } from "./components/alert";
import { BUTTON_THEME } from "./components/button";
import { CHECKBOX_THEME } from "./components/checkbox";
import { FORM_THEME } from "./components/form";
import { INPUT_THEME } from "./components/input";
import { MODAL_THEME } from "./components/modal";
import { SELECT_THEME } from "./components/select";
import { TABS_THEME } from "./components/tab";
import { TEXT_THEME } from "./components/text";
import { TEXTAREA_THEME } from "./components/textarea";

export const THEME_PUBLIC = extendTheme({
  colors: {
    brandPrimary: {
      50: "#E5F2F9",
      100: "#C7E8F9",
      200: "#95CCEA",
      300: "#6DB6DE",
      400: "#008BD5",
      500: "#008CD2",
      600: "#0F6999",
      700: "#124B69",
      800: "#052D42",
      900: "#00121C",
    },
    brandSecondary: {
      50: "#F9EBE5",
      100: "#F9D6C7",
      200: "#F4A588",
      300: "#F28A5E",
      400: "#EA7246",
      500: "#F95E24",
      600: "#E24D16",
      700: "#A2350E",
      800: "#752508",
      900: "#461300",
    },
    gray: {
      50: "#FCFCFC",
      100: "#F9F9F9",
      200: "#DBDBDB",
      300: "#CBD7DA",
      400: "#A0AEC0",
      500: "#79879C",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-brandPrimary-500)",
  },
  components: {
    Alert: ALERT_THEME,
    Button: BUTTON_THEME,
    Checkbox: CHECKBOX_THEME,
    Form: FORM_THEME,
    Input: INPUT_THEME,
    Modal: MODAL_THEME,
    Select: SELECT_THEME,
    Tabs: TABS_THEME,
    Text: TEXT_THEME,
    Textarea: TEXTAREA_THEME,
  },
  fonts: {
    heading: "Outfit",
    body: "Outfit",
  },
  styles: {
    global: {
      body: {
        overflowY: "scroll",
      },
      "div[data-live-announcer]": {
        // prevents the live announcer from exceeding the viewport and causing horizontal overflow on smaller screens
        maxW: "100dvw",
      },
      ".renderer h1": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: { base: "1.875rem", lg: "2.5rem" },
        fontWeight: "400",
        lineHeight: { base: "2.3625rem", lg: "3.15rem" },
      },
      ".renderer h2": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: { base: "1.625rem", lg: "2rem" },
        fontWeight: "400",
        lineHeight: { base: "2.0475rem", lg: "2.52rem" },
        letterSpacing: "0.04rem",
      },
      ".renderer h3": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: "1.75rem",
        fontWeight: "300",
        lineHeight: "2.205rem",
        letterSpacing: "0.02em",
      },
      ".renderer h4": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: "1.25rem",
        fontWeight: "400",
        lineHeight: "1.575rem",
        letterSpacing: "0.02em",
      },
      ".renderer h5": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: "1.125rem",
        fontWeight: "400",
      },
      ".renderer h6": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: "1rem",
        fontWeight: "400",
      },
      ".renderer p, .renderer .paragraph, .renderer ul": {
        color: "#002538",
        fontFamily: "'Outfit', sans-serif",
        fontSize: "1rem",
        fontWeight: "400",
        mt: "0.875rem",
        ":first-child": {
          mt: 0,
        },
      },
      ".renderer ul": {
        marginLeft: "1.5rem",
      },
      ".renderer ol": {
        marginLeft: "1.5rem",
      },
      ".renderer p a": {
        color: "#008CD2",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
      },
      // box consent
      "#termsfeed-com---nb .cc-nb-okagree, #termsfeed-com---nb .cc-nb-reject": {
        backgroundColor: "#F95E24",
        color: "#fff",
        borderRadius: "0.25rem",
        padding: "0.5rem 1rem",
        fontSize: "1rem",
        fontWeight: "400",
        cursor: "pointer",
        transition: "background-color 0.2s",
        ":hover": {
          backgroundColor: "#F95E24",
        },
      },
    },
  },
});
