import { Progress } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Z_INDEX } from "../../constants/zIndex";

function ProgressBar() {
  const [isLoading, setisLoading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    const handler = () => {
      setisLoading(true);
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [router.events]);

  useEffect(() => {
    const handler = () => {
      setisLoading(false);
    };
    router.events.on("routeChangeComplete", handler);
    return () => {
      router.events.off("routeChangeComplete", handler);
    };
  }, [router.events]);

  useEffect(() => {
    const handler = () => {
      setisLoading(false);
    };
    router.events.on("routeChangeError", handler);
    return () => {
      router.events.off("routeChangeError", handler);
    };
  }, [router.events]);

  return (
    <Progress
      zIndex={Z_INDEX.header}
      pointerEvents="none"
      colorScheme="brandPrimary"
      isIndeterminate={isLoading}
      opacity={isLoading ? 1 : 0}
      transition="opacity 0.25s ease-in-out"
      position="fixed"
      top="0"
      left="0"
      right="0"
      size="xs"
    />
  );
}

export default ProgressBar;
