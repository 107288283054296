const { useEffect, useState, useCallback } = require("react");

/**
 * Provides a function to start a countdown and a variable updated every second with the remaining time
 */
function useCountDown() {
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const start = useCallback(
    /** @param {number} timeLeft */
    (timeLeft) => {
      setTimeLeft(timeLeft);
    },
    [],
  );

  useEffect(() => {
    setIsCountingDown(timeLeft > 0);
  }, [timeLeft]);

  useEffect(() => {
    let interval = null;
    if (isCountingDown) {
      interval = setInterval(() => {
        setTimeLeft((timeLeft) => {
          const newTimeLeft = timeLeft - 1;
          if (newTimeLeft === 0) {
            setIsCountingDown(false);
          }
          return Math.max(newTimeLeft, 0);
        });
      }, 1000);
    }

    return () => {
      interval !== null && clearInterval(interval);
    };
  }, [isCountingDown]);

  return {
    timeLeft,
    start,
  };
}

export default useCountDown;
