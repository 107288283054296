import { createContext, useContext, useState } from "react";

/**
 * @typedef {object} CookiesContextValue
 * @property {Record<string, any>} cookies
 * @property {import("react").Dispatch<import("react").SetStateAction<Record<string, any>>>} setCookies
 */

/** @type {CookiesContextValue} */
const DEFAULT_VALUE = {
  cookies: {},
  setCookies: () => {},
};

export const CookiesContext = createContext(DEFAULT_VALUE);

/**
 * @returns {CookiesContextValue}
 */
export function useCookies() {
  const cookiesContextValue = useContext(CookiesContext);

  if (cookiesContextValue === DEFAULT_VALUE) {
    throw new Error("useCookies must be used within a CookiesProvider");
  }

  return cookiesContextValue;
}

/**
 * @typedef {object} CookiesProviderProps
 * @property {Record<string, any>} initialCookies
 * @property {React.ReactNode} children
 */
/**
 * @param {CookiesProviderProps} props
 */
export const CookiesProvider = ({ children, initialCookies }) => {
  const [cookies, setCookies] = useState(initialCookies);

  return (
    <CookiesContext.Provider value={{ cookies, setCookies }}>
      {children}
    </CookiesContext.Provider>
  );
};
