// deps
import { useColorMode } from "@chakra-ui/system";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";
import cookie from "cookie";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { createContext, memo, useContext, useMemo, useRef } from "react";
import { COOKIES_NAME_VALUE_PAGINATION_PER_PAGE } from "../../constants/cookies";
import { useConfiguration } from "../../hooks/useConfiguration";

/**
 * @typedef {object} PreferencesContextValue
 * @property {number} paginationPerPage
 * @property {(value: number) => void} setPaginationPerPage
 * @property {import("../../types/Configuration").ConfigurationEnvironment | null} bestEnvironment
 * @property {import("@chakra-ui/react").ColorMode} globalColorMode
 * @property {boolean} shouldRenderEnvironments
 */

/** @type {PreferencesContextValue} */
const DEFAULT_VALUE = {
  paginationPerPage: 25,
  setPaginationPerPage: () => {},
  bestEnvironment: null,
  globalColorMode: "light",
  shouldRenderEnvironments: false,
};

export const PreferencesContext = createContext(DEFAULT_VALUE);

const { Provider } = PreferencesContext;

/**
 * @param {{
 * initialPaginationPerPage: any,
 * children: import("react").ReactNode,
 * }} props
 */
function _PreferencesProvider(props) {
  const { initialPaginationPerPage, children } = props;

  const { configuration } = useConfiguration();

  const environments = configuration?.environments;

  const paginationPerPage = useRef(Number(initialPaginationPerPage));
  const shouldRenderEnvironments = (environments?.length ?? 0) > 1;
  const { colorMode } = useColorMode();

  const bestEnvironment = useMemo(() => {
    return environments?.length === 1 ? environments[0] : null;
  }, [environments]);

  const value = useMemo(
    function () {
      /** @type {PreferencesContextValue} */
      const value = {
        paginationPerPage: paginationPerPage.current,
        setPaginationPerPage(newPaginationPerPage) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
            String(newPaginationPerPage),
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );

          paginationPerPage.current = newPaginationPerPage;
        },
        globalColorMode: colorMode,
        shouldRenderEnvironments,
        bestEnvironment,
      };
      return value;
    },
    [bestEnvironment, shouldRenderEnvironments, colorMode],
  );

  return <Provider value={value}>{children}</Provider>;
}

_PreferencesProvider.displayName = "PreferencesProvider";

_PreferencesProvider.propTypes = {
  initialPaginationPerPage: PropTypes.any,
  children: PropTypes.node,
};

export const PreferencesProvider = memo(_PreferencesProvider);

export const usePreferences = function () {
  return useContext(PreferencesContext);
};
