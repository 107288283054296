import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const bookingBaseUri = new Uri(
  process.env.NEXT_PUBLIC_BOOKING_SCHEME,
  null,
  process.env.NEXT_PUBLIC_BOOKING_HOST,
  null,
  null,
  null,
  null,
);

export const bookingBaseUriWithBasePath = new Uri(
  process.env.NEXT_PUBLIC_BOOKING_SCHEME,
  null,
  process.env.NEXT_PUBLIC_BOOKING_HOST,
  null,
  process.env.NEXT_PUBLIC_BOOKING_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @returns {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default}
 */
function buildRouter(baseUri) {
  const bookingRouter = new Router();

  bookingRouter.group(baseUri, function (bookingRouter) {
    bookingRouter.get("/mon-panier/detail").setId("@booking.cart-show");
    bookingRouter.get("/mon-panier").setId("@booking.cart-update");
  });

  return bookingRouter;
}

export const bookingRouter = buildRouter(bookingBaseUri);
export const bookingRouterWithBasePath = buildRouter(
  bookingBaseUriWithBasePath,
);
