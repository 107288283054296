/**
 * @param {any} value
 * @param {{ fallback?: any }} options
 * @returns {"" | "0" | "1"}
 */
export function initBooleanStr(value, { fallback = "" } = {}) {
  /** @type {"" | "0" | "1"} */
  let normalizedValue = fallback;

  if (value === true) {
    normalizedValue = "1";
  } else if (value === false) {
    normalizedValue = "0";
  }

  return normalizedValue;
}
