import {
  Box,
  Button,
  DarkMode,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

const MenuButton = forwardRef(
  /**
   * @typedef {object} Props
   * @property {boolean} isOpened
   * @property {"LightMode" | "DarkMode"} [variant]
   */
  /**
   * @param {Props & import("@chakra-ui/react").ButtonProps} props
   */
  function MenuButton({ isOpened, variant = "LightMode", ...otherProps }, ref) {
    const intl = useIntl();

    const color = useColorModeValue("brandPrimary.600", "#fff");
    const highlightedColor = useColorModeValue("brandPrimary.600", "#fff");
    const backgroundColor = useColorModeValue(
      "blackAlpha.100",
      "blackAlpha.400",
    );

    return (
      <ConditionalWrapper
        hasWrapper={variant === "DarkMode"}
        wrapper={(children) => <DarkMode>{children}</DarkMode>}>
        <Button
          variant="unstyled"
          display="flex"
          height="auto"
          flexDirection="column"
          fontWeight="normal"
          ref={ref}
          spacing="0"
          color={isOpened ? highlightedColor : color}
          role="group"
          flexBasis="0px"
          flexGrow={1}
          {...otherProps}>
          <Box
            backgroundColor={isOpened ? backgroundColor : "transparent"}
            borderRadius="6px"
            px="0.75rem"
            py="0.125rem">
            <Icon icon="ms_menu" color={color} size="1.5rem" />
          </Box>

          <Text
            as="span"
            variant="text-xs"
            color="inherit"
            _groupHover={{ textDecoration: { lg: "underline" } }}>
            {intl.formatMessage({ defaultMessage: "Plus" })}
          </Text>
        </Button>
      </ConditionalWrapper>
    );
  },
);

export default MenuButton;
