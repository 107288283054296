import { Router } from "next/router";
import { memo, useEffect } from "react";
import {
  getCookieScript,
  getGoogleTagManagerScript,
  trackPageView,
} from "../../helpers/googleTracking";

const GOOGLE_TAG_MANAGER_KEY = process.env.NEXT_PUBLIC_TAG_MANAGER_KEY;

function canTrack() {
  return Boolean(GOOGLE_TAG_MANAGER_KEY);
}

/**
 *
 * @param {object} param0
 * @param {string} param0.locale
 */
function CookiesConsent({ locale }) {
  useEffect(() => {
    if (canTrack) {
      const handler = () => {
        trackPageView(
          window.location.pathname + window.location.search,
          GOOGLE_TAG_MANAGER_KEY,
        );
      };
      if (canTrack()) {
        Router.events.on("routeChangeComplete", handler);
      }
      return () => {
        Router.events.off("routeChangeComplete", handler);
      };
    }
  }, []);

  return (
    <>
      {canTrack() && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_KEY}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      )}

      {canTrack() &&
        GOOGLE_TAG_MANAGER_KEY &&
        getGoogleTagManagerScript(GOOGLE_TAG_MANAGER_KEY)}

      {canTrack() && getCookieScript(locale)}
    </>
  );
}

export default memo(CookiesConsent);
