import { Box, Flex } from "@chakra-ui/react";
import Image from "@raiden/library-ui/components/Image";
import { useRouterString } from "@raiden/library-ui/hooks/router/useRouterString";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useEffect } from "react";
import CookiesConsent from "../../CookiesConsent";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {import("../../../types/Layouts").LayoutProps & Props} props
 */
export function SignedOutLayout({ children }) {
  const { push } = useRouter();

  const { locale } = useLocale();

  const nextUrl = useRouterString("next");

  const { userCustomer } = useAuth();

  useEffect(() => {
    if (userCustomer) {
      push(nextUrl ?? generateFrontPath({ id: "@front.account" }));
    }
  }, [nextUrl, push, userCustomer]);

  return (
    <>
      <Flex minH="100vh">
        <Box flexGrow={1} flexBasis="480px" maxW="480px">
          {children}
        </Box>

        <Box flexGrow={1} h="100vh" position="sticky" top="0">
          <Box position="relative" h="100%">
            <Image
              src={generateFrontPath({
                id: "@front.internal-assets",
                parameters: { filePath: `signed-out-background.png` },
                includeBasePath: true,
              })}
              layout="fill"
              objectFit="cover"
              alt="clévacances"
              loading="lazy"
            />
          </Box>
        </Box>
      </Flex>

      <CookiesConsent locale={locale} />
    </>
  );
}
