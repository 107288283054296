// deps
// eslint-disable-next-line import/no-unresolved
import generateUrl from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/generateUri";

// constants
import {
  frontRouter,
  frontRouterWithBasePath,
} from "../../../constants/routers/front";

/**
 * Retourne l’URI de la route API demandée.
 * @param {object} options
 * @param {string} options.id
 * @param {boolean} [options.includeBasePath]
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default} [options.router]
 * @param {object} [options.parameters]
 * @param {string} [options.scheme]
 * @param {string | {user: string; password: string}} [options.userInfo]
 * @param {string} [options.host]
 * @param {number} [options.port]
 * @param {string | object} [options.query]
 * @param {string} [options.fragment]
 * @returns {string}
 */
export default function generateFrontUrl(options) {
  const { includeBasePath = true, ...otherOptions } = options;

  if (includeBasePath) {
    return generateUrl({
      router: frontRouterWithBasePath,
      ...otherOptions,
    });
  }
  return generateUrl({
    router: frontRouter,
    ...otherOptions,
  });
}
