import { useCallback } from "react";
import useTranslate from "./useTranslate";

/**
 * @typedef {object} RoomValues
 * @property {import("../types/common/TranslatedField").TranslatedField | null} name
 * @property {string} [hlo]
 * @property {string | null} [agr]
 */

/** @typedef {(data: {room: RoomValues, withHlo?: boolean, withAgr?: boolean}) => string | null } roomNameValues */

/**
 * Retourne un string des informations de la room (nom, hlo et agr)
 * @param {object} params
 * @param {RoomValues} params.room
 * @param {(resource: import("../types/common/TranslatedField").TranslatedField | null) => string | undefined} params.translate
 * @param {boolean} [params.withHlo]
 * @param {boolean} [params.withAgr]
 * @returns {string | null}
 */
export function getRoomName({
  room,
  translate,
  withHlo = false,
  withAgr = false,
}) {
  let roomName = room?.name ? translate(room.name) : null;

  if (withHlo || !roomName) {
    if (room?.agr) {
      roomName = roomName ? `${roomName} ${room.hlo}` : room.hlo;
    }
  }
  if (withAgr && room?.agr) {
    roomName = roomName ? `${roomName} - ${room.agr}` : room.agr;
  }

  return roomName ?? null;
}

export function useGetRoomName() {
  const translate = useTranslate();

  /** @type {roomNameValues} */
  const roomName = useCallback(
    /**
     * @type {roomNameValues}
     */
    function ({ room, withHlo, withAgr }) {
      return getRoomName({
        translate,
        room,
        withHlo,
        withAgr,
      });
    },
    [translate],
  );

  return roomName;
}
