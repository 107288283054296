import { Center, Link, Text } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { FormattedMessage } from "react-intl";
import NextLink from "next/link";

export function SignInButton() {
  const { userCustomer } = useAuth();

  return (
    <NextLink
      href={generateFrontPath({
        id: userCustomer ? "@front.account" : "@front.signin",
      })}
      passHref>
      <Link py="0.625rem" px="0.5rem" color="#fff">
        <Center>
          <Text as="span" variant="text-sm">
            {userCustomer ? (
              <FormattedMessage defaultMessage="Mon compte" />
            ) : (
              <FormattedMessage defaultMessage="M'identifier" />
            )}
          </Text>
        </Center>
      </Link>
    </NextLink>
  );
}
