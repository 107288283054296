import { Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

/**
 * @param {import("@chakra-ui/react").TextProps & {
 * error: import("react-hook-form").FieldError,
 * }} param0
 * @returns
 */
function FormErrorMessageRHF({ error, ...otherProps }) {
  return (
    <Text
      fontSize="0.875rem"
      color="#C91A1A"
      pl="1rem"
      transition="opacity .2s ease"
      {...otherProps}>
      {(error?.message?.length ?? 0) > 0 && error?.message}
      {error?.type === "required" && (
        <FormattedMessage defaultMessage="Ce champ est requis" />
      )}
    </Text>
  );
}

export default FormErrorMessageRHF;
