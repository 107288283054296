// libraries
import valueInvoke from "@splitfire-agency/raiden-library/dist/libraries/utils/valueInvoke";
import encodeFormData from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeFormData";

const defaultWhitelist = {
  "data.environment_id": true,
  "data.parent_id": true,
  "data.type": true,
  "data.filename": true,
  "data.visibility": true,
  "data.is_restricted": true,
  file: true,
};

export default function filesSerializeCreateOrUpdate({
  fields,
  whitelist = defaultWhitelist,
}) {
  whitelist = valueInvoke(whitelist, defaultWhitelist);

  return encodeFormData({
    data: {
      environment_id:
        whitelist["data.environment_id"] && fields?.data?.environment_id
          ? fields.data.environment_id
          : undefined,

      parent_id:
        whitelist["data.parent_id"] && fields?.data?.parent_id
          ? fields.data.parent_id
          : undefined,

      type:
        whitelist["data.type"] && fields?.data?.type
          ? fields.data.type
          : undefined,

      filename:
        whitelist["data.filename"] && fields?.data?.filename
          ? fields.data.filename
          : undefined,

      visibility:
        whitelist["data.visibility"] && fields?.data?.visibility
          ? fields.data.visibility
          : undefined,

      is_restricted:
        whitelist["data.is_restricted"] && fields?.data?.is_restricted
          ? fields.data.is_restricted
          : undefined,
    },
    file:
      whitelist["file"] && fields?.file instanceof File
        ? fields.file
        : undefined,
  });
}
