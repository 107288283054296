import { Box, Flex, HStack, Image } from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library-ui/components/ImageLoaderHandler";
import { useConfigurationMenu } from "@raiden/library-ui/hooks/useConfigurationMenu";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { memo, useContext } from "react";
import { CenteredContent } from "../../../../components/CenteredContent";
import { Href } from "../../../../components/Href";
import { CenteredContentContext } from "../../../../contexts/CenteredContentContext";
import { MenuItem } from "./MenuItem";

export const Menu = memo(function Menu() {
  const { menu } = useConfigurationMenu({ alias: "main" });

  const { maxWidth } = useContext(CenteredContentContext);

  return (
    <Box
      display={{ base: "none", lg: "block" }}
      backgroundColor="#fff"
      borderBottomWidth="1px"
      borderBottomColor="gray.200">
      <CenteredContent
        maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
        <nav>
          <Flex maxW={`calc(${maxWidth} + 1rem)`} alignItems="center">
            <Box flexGrow={1}>
              <Flex pl="1rem" justify={{ base: "center", lg: "flex-start" }}>
                <Href href={generateFrontPath({ id: "@front.home" })}>
                  <ImageLoaderHandler
                    src={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "logo.svg" },
                      includeBasePath: true,
                    })}>
                    {({ ref, src, isLoaded, handleLoad }) => (
                      <Image
                        ref={ref}
                        src={src}
                        onLoad={handleLoad}
                        opacity={isLoaded ? 1 : 0}
                        transition="opacity 0.25s"
                        w="12.5rem"
                        py="1rem"
                        alt="Clévacances"
                        loading="lazy"
                      />
                    )}
                  </ImageLoaderHandler>
                </Href>
              </Flex>
            </Box>

            <HStack
              display={{ base: "none", lg: "flex" }}
              spacing="0.75rem"
              ml="0.75rem">
              {menu?.items.map((menuItem, index) => (
                <MenuItem menuItem={menuItem} key={index} />
              ))}
            </HStack>
          </Flex>
        </nav>
      </CenteredContent>
    </Box>
  );
});
