import Script from "next/script";

/**
 * @param {string} key
 * @returns {import("react").ReactNode | null}
 */
export const getGoogleTagManagerScript = (key) => {
  if (!key) {
    return null;
  }

  return (
    <>
      <Script id="google-tag-manager-preload" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          // Set default consent to 'denied' as a placeholder
          // Determine actual values based on your own requirements
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `}
      </Script>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');`}
      </Script>
    </>
  );
};
/**
 * @param {string} locale
 * @returns {import("react").ReactNode | null}
 */
export const getCookieScript = (locale) => {
  return (
    <>
      <Script
        charSet="UTF-8"
        type="text/javascript"
        src="https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js"
        strategy="afterInteractive"
        onLoad={() => {
          // @ts-ignore
          if (typeof cookieconsent !== "undefined") {
            // @ts-ignore
            // eslint-disable-next-line no-undef
            cookieconsent.run({
              notice_banner_type: "simple",
              consent_type: "express",
              palette: "light",
              language: locale,
              page_load_consent_levels: ["strictly-necessary"],
              notice_banner_reject_button_hide: false,
              preferences_center_close_button_hide: false,
              page_refresh_confirmation_buttons: false,
              website_privacy_policy_url:
                "https://www.clevacances.com/fr/pages/10-gestion-des-cookies",
              website_name: "CléVacances",
            });
          } else {
            console.error("cookieconsent is not defined");
          }
        }}></Script>

      <Script
        charSet="UTF-8"
        type="text/javascript"
        id="cookie-consent-functionality"
        strategy="afterInteractive"
        data-cookie-consent="functionality">
        {`
          window.dataLayer.push({
            event: 'fonctionnalites',
          });
          gtag('consent', 'update', {});
        `}
      </Script>

      <Script
        charSet="UTF-8"
        type="text/javascript"
        id="cookie-strictly-necessary"
        strategy="afterInteractive"
        data-cookie-consent="strictly-necessary">
        {`
          window.dataLayer.push({
            event: 'Cookiesnecessaires',
          });
        `}
      </Script>

      <Script
        charSet="UTF-8"
        type="text/javascript"
        id="cookie-tracking"
        strategy="afterInteractive"
        data-cookie-consent="tracking">
        {`
          window.dataLayer.push({
            event: 'CookieAnalytics',
          });

          gtag('consent', 'update', {
            'analytics_storage': 'granted'
          });
        `}
      </Script>

      <Script
        charSet="UTF-8"
        type="text/javascript"
        id="cookie-targeting"
        strategy="afterInteractive"
        data-cookie-consent="targeting">
        {`
          window.dataLayer.push({
            event: 'Remarketing',
          });

          gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
          });
        `}
      </Script>
    </>
  );
};

export const trackPageView = (url, key) => {
  if (!key) {
    console.warn("unable to track, key is missing");
    return;
  }
  if (!window["gtag"]) {
    console.warn("unable to track, gtag is not initialized");
    return;
  }

  try {
    window["gtag"]("config", key, {
      page_path: url,
    });
  } catch (error) {
    console.warn("an error occured while tracking the page", error);
  }
};
