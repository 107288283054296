import { Button, ButtonGroup, ModalBody, ModalFooter } from "@chakra-ui/react";
import { useApiError } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import FormStateRHF from "@raiden/library-ui/components/ReactHookForm/FormStateRHF";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import customersSerializeUpdateLegalConditions from "@raiden/library-ui/normalizers/users/customers/serializeUpdateLegalConditions";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Form } from "./Form";

/**
 * @typedef {object} Params
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 */
function getDefaultValues() {
  return {
    data: {
      legal_accepted: false,
    },
  };
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {() => void} onSuccess
 */
/**
 * @param {Props} props
 */
export function Handler({ customer, onSuccess }) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library-ui/types/Customer").CustomerUpdateLegalConditionsDataFormValues>} */
  const form = useForm({
    defaultValues: getDefaultValues(),
  });
  const { handleSubmit } = form;

  const { request, isLoading, toastSuccess } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  /**
   * Gère la soumission du formulaire.
   */
  function onSubmit(fields) {
    request(
      generateApiUrl({
        id: "@customers.update",
        parameters: { customerId: customer?.id },
      }),
      {
        method: "POST",
        body: customersSerializeUpdateLegalConditions({
          fields,
        }),
      },
    )
      .then(function () {
        resetApiErrors();
        toastSuccess({
          title: intl.formatMessage({
            defaultMessage:
              "Vous avez accepté les Conditions Générales de Vente de Clévacances",
          }),
        });
        onSuccess();
      })

      .catch((response) => {
        resolveApiError({ response, form });
      });
  }
  return (
    <FormProvider {...form}>
      <ModalBody>
        <form
          id="updateCgv"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate>
          <Form />
        </form>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <FormStateRHF
            render={({ isDirty }) => (
              <Button
                form="updateCgv"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
                colorScheme="brandPrimary">
                {intl.formatMessage({
                  defaultMessage: "Confirmer",
                })}
              </Button>
            )}
          />
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
