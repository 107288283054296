import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Href } from "../../../../components/Href";
import { Handler } from "./Handler";
import { SuccessModal } from "./SuccessModal";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const SHARE_ICONS = [
  {
    name: "Facebook",
    icon: "sf_facebook_letter",
    url: "https://www.facebook.com/ClevacancesFrance",
    size: "32px",
  },
  {
    name: "Instagram",
    icon: "sf_instagram",
    url: "https://www.instagram.com/clevacances_france",
    size: "32px",
  },
  {
    name: "Youtube",
    icon: "sf_youtube",
    url: "https://www.youtube.com/channel/UC2UWsgQCMi4lQWtFqDGIzdw/videos",
    size: "45px",
  },
  {
    name: "Linkedin",
    icon: "sf_linkedin",
    url: "https://www.linkedin.com/company/clevacances",
    size: "32px",
  },
];

export function NewsgroupsSubscribersCreate(props) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const intl = useIntl();

  function handleSuccess() {
    setIsSuccessModalOpen(true);
  }

  return (
    <>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />

      <Box backgroundColor="rgba(0,139,213,1)">
        <VStack spacing="1rem" align="start">
          <Text fontSize="1.75rem">
            {intl.formatMessage({
              defaultMessage: "Des pépites dans votre boite mail",
            })}
          </Text>

          <Text fontSize="1rem">
            {intl.formatMessage({
              defaultMessage:
                "Recevez les dernières réductions, bon plans et nouveautés Clévacances avant tout le monde !",
            })}
          </Text>
        </VStack>

        <ApiErrorProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={
              process.env.NEXT_PUBLIC_RECAPTCHA_V3_PUBLIC_KEY ?? ""
            }>
            <Handler onSuccess={handleSuccess} />
          </GoogleReCaptchaProvider>
        </ApiErrorProvider>

        <Flex
          flexDir={{ base: "column", md: "row" }}
          mt="1.5rem"
          gap="1rem"
          justify="space-between">
          <Text
            maxW={{ base: "full", md: "70%" }}
            flexGrow={1}
            variant="text-md-light">
            {intl.formatMessage({
              defaultMessage:
                "Suivez-nous sur les réseaux et partagez vos souvenirs de vacances avec le hashtag #clevacances",
            })}
          </Text>

          <HStack spacing=".5rem">
            {SHARE_ICONS.map((icon) => (
              <Href key={icon.name} href={icon.url} target="_blank">
                <Icon icon={icon.icon} color="white" size={icon.size} />
              </Href>
            ))}
          </HStack>
        </Flex>
      </Box>
    </>
  );
}
