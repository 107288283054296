// constants
import browser from "../../../constants/browser";

// libraries
import noop from "@splitfire-agency/raiden-library/dist/libraries/utils/noop";

/**
 * Effectue une redirection isomophique vers une autre page.
 * @param {object} param0
 * @param {object} param0.code
 * @param {string} param0.redirectionUri
 * @param {import("http").ServerResponse} [param0.res]
 */
export default async function nextRedirect({ code, redirectionUri, res }) {
  if (browser) {
    window.location.href = redirectionUri;

    /*
     * /!\ Promesse qui ne se résoudra jamais.
     * Cela est volontaire car on ne souhaite pas que la suite du `getInitialProps` soit appelé et
     *   comme la redirection est déjà enclenchée, on attend quelle soit pleinement effectuée.
     * @see https://maxschmitt.me/posts/next-js-redirects/
     */
    await new Promise(noop);
  } else {
    res?.setHeader("Location", redirectionUri);
    res?.writeHead(code);
    res?.end();
  }
}
