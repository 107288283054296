// deps
import { useState, useEffect, useRef } from "react";

function useDebounce(value, { delay, mount = false, trailing = true }) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const first = useRef(true);

  useEffect(
    () => {
      if (first.current) {
        first.current = false;

        if (!mount) {
          return;
        }
      }

      if (trailing) {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      }
    },

    [value, mount, delay, trailing], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default useDebounce;
