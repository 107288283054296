import { mode } from "@chakra-ui/theme-tools";

/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const TEXT_THEME = {
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      color: colorScheme
        ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
        : mode("#002538", "#FFFFFF")(props),
    };
  },
  variants: {
    h1: {
      fontSize: { base: "1.875rem", lg: "2.5rem" },
      fontWeight: "400",
      lineHeight: { base: "2.3625rem", lg: "3.15rem" },
    },
    h2: {
      fontSize: { base: "1.625rem", lg: "2rem" },
      fontWeight: "400",
      lineHeight: { base: "2.0475rem", lg: "2.52rem" },
      letterSpacing: "0.04rem",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "300",
      lineHeight: "2.205rem",
      letterSpacing: "0.02em",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.575rem",
      letterSpacing: "0.02em",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.2rem",
    },
    "text-sm": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "text-xs": {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    "text-input": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.3125rem",
    },
    "text-md-light": {
      fontSize: "1rem",
      fontWeight: 300,
      lineHeight: "1.5rem",
    },
    "text-md-link": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    "text-sm-link": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    "text-xs-link": {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "button-lg": {
      fontSize: "1.125rem",
      fontWeight: 400,
    },
    "button-md": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "button-sm": {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
};
