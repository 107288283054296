// deps
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

export default function FilesEditorImageMetadata(props) {
  const { form, language } = props;

  const intl = useIntl();

  return (
    <Grid templateColumns={"repeat(2, 1fr)"} gap="1rem">
      {form.getValue(`${language}[type]`) === "external" && (
        <GridItem gridColumn="span 2">
          <FormControl>
            <FormLabel>
              {intl.formatMessage({
                id: "raiden.library.containers.Files.EditorImage.Metadata.fields.src.label",
                defaultMessage: "URL de l’image",
              })}
            </FormLabel>

            <Input
              {...form.inputProps({ name: `${language}[src]` })}
              placeholder={intl.formatMessage({
                id: "raiden.library.containers.Files.EditorImage.Metadata.fields.src.placeholder",
                defaultMessage: "https://…",
              })}
            />
          </FormControl>
        </GridItem>
      )}

      <GridItem>
        <FormControl>
          <FormLabel>
            {intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.name.label",
              defaultMessage: "Nom de l’image",
            })}
          </FormLabel>

          <Input
            {...form.inputProps({ name: `${language}[name]` })}
            placeholder={intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.name.placeholder",
              defaultMessage: "Plage Marseillan",
            })}
          />
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl>
          <FormLabel>
            {intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.alt.label",
              defaultMessage: "Texte alternatif",
            })}
          </FormLabel>

          <Input
            {...form.inputProps({ name: `${language}[alt]` })}
            placeholder={intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.alt.placeholder",
              defaultMessage: "Vue aérienne de Marseillan Plage",
            })}
          />
        </FormControl>
      </GridItem>

      <GridItem>
        <FormControl>
          <FormLabel>
            {intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.caption.label",
              defaultMessage: "Légende",
            })}
          </FormLabel>

          <Input
            {...form.inputProps({ name: `${language}[caption]` })}
            placeholder={intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.Metadata.fields.caption.placeholder",
              defaultMessage: "Vue aérienne de Marseillan Plage",
            })}
          />
        </FormControl>
      </GridItem>
    </Grid>
  );
}

FilesEditorImageMetadata.propTypes = {
  language: PropTypes.string,
  form: PropTypes.object,
};
