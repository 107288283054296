import { HStack, PinInput, PinInputField } from "@chakra-ui/react";
import { forwardRef } from "react";

export const FormPinInput = forwardRef(
  /**
   * @typedef {object} Props
   * @property {number} [numberOfDigits]
   * @property {() => void} [onComplete]
   * @property {string} value
   * @property {(value: string) => void} onChange
   * @property {boolean} isDisabled
   * @property {boolean} isInvalid
   * @property {boolean} [autoFocus]
   * @property {() => void} onBlur
   */
  /**
   * @param {Props} props
   */
  function FormPinInput(
    {
      numberOfDigits = 5,
      value,
      onChange: _onChange,
      isDisabled,
      isInvalid,
      onBlur,
      onComplete,
      autoFocus = false,
    },
    ref,
  ) {
    const onChange = (value) => {
      _onChange(value);
      if (value.length === numberOfDigits) {
        onComplete?.();
      }
    };

    return (
      <HStack>
        <PinInput
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          isInvalid={isInvalid}>
          {[...Array(numberOfDigits).keys()].map((i) => (
            <PinInputField
              onBlur={onBlur}
              ref={i === 0 ? ref : undefined}
              key={i}
              backgroundColor="gray.50"
            />
          ))}
        </PinInput>
      </HStack>
    );
  },
);
