import { defineMessage } from "react-intl";

// Types
export const FILES_TYPE_VALUE_DIRECTORY = "directory";
export const FILES_TYPE_VALUE_FILE = "file";

export const FILES_TYPES = {
  [FILES_TYPE_VALUE_DIRECTORY]: {
    id: FILES_TYPE_VALUE_DIRECTORY,
  },
  [FILES_TYPE_VALUE_FILE]: {
    id: FILES_TYPE_VALUE_FILE,
  },
};

export const FILES_TYPE_LIST = Object.values(FILES_TYPES);

// Catégories
export const FILES_CATEGORY_VALUE_IMAGE = "images";
export const FILES_CATEGORY_VALUE_AUDIO = "audio";
export const FILES_CATEGORY_VALUE_DOCUMENT = "documents";
export const FILES_CATEGORY_VALUE_VIDEO = "video";
export const FILES_CATEGORY_VALUE_OTHER = "other";

export const FILES_CATEGORIES = {
  [FILES_CATEGORY_VALUE_IMAGE]: {
    id: FILES_CATEGORY_VALUE_IMAGE,
  },
  [FILES_CATEGORY_VALUE_AUDIO]: {
    id: FILES_CATEGORY_VALUE_AUDIO,
  },
  [FILES_CATEGORY_VALUE_DOCUMENT]: {
    id: FILES_CATEGORY_VALUE_DOCUMENT,
  },
  [FILES_CATEGORY_VALUE_VIDEO]: {
    id: FILES_CATEGORY_VALUE_VIDEO,
  },
  [FILES_CATEGORY_VALUE_OTHER]: {
    id: FILES_CATEGORY_VALUE_OTHER,
  },
};

export const FILES_CATEGORY_LIST = Object.values(FILES_CATEGORIES);

export const FILES_SIZE_VALUE_BYTE = "byte";
export const FILES_SIZE_VALUE_KILOBYTE = "kilobyte";
export const FILES_SIZE_VALUE_MEGABYTE = "megabyte";
export const FILES_SIZE_VALUE_GIGABYTE = "gigabyte";
export const FILES_SIZE_VALUE_TERABYTE = "terabyte";

export const FILES_SIZES = {
  [FILES_SIZE_VALUE_BYTE]: {
    id: FILES_SIZE_VALUE_BYTE,
  },
  [FILES_SIZE_VALUE_KILOBYTE]: {
    id: FILES_SIZE_VALUE_KILOBYTE,
  },
  [FILES_SIZE_VALUE_MEGABYTE]: {
    id: FILES_SIZE_VALUE_MEGABYTE,
  },
  [FILES_SIZE_VALUE_GIGABYTE]: {
    id: FILES_SIZE_VALUE_GIGABYTE,
  },
  [FILES_SIZE_VALUE_TERABYTE]: {
    id: FILES_SIZE_VALUE_TERABYTE,
  },
};

export const FILES_SIZE_LIST = Object.values(FILES_SIZES);

export const FILES_SIZE_SHORTENED_MESSAGE = defineMessage({
  defaultMessage:
    "{size, select, byte {B} kilobyte {kB} megabyte {MB} gigabyte {GB} terabyte {TB} other {{size}}}",
});
