import PropTypes from "prop-types";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

/**
 * @param {{
 * title?: import("react").ReactNode,
 * initialFolded?: boolean,
 * isFoldable?: boolean,
 * children: import("react").ReactNode,
 * [key: string]: any,
 * }} param0
 */
export default function ContentBox({
  title,
  initialFolded = false,
  isFoldable = false,
  withBorder = true,
  children,
  ...otherProps
}) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      {isFoldable ? (
        <Accordion
          defaultIndex={initialFolded ? [] : [0]}
          borderWidth={withBorder ? "0.0625rem" : ""}
          borderColor={borderColor}
          allowToggle={true}>
          <AccordionItem border="0">
            <h2>
              <AccordionButton {...otherProps}>
                <Box flex="1" textAlign="left">
                  {title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb="1rem">{children}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <Box
          {...otherProps}
          borderWidth={withBorder ? "0.0625rem" : ""}
          borderColor={borderColor}
          borderStyle="solid">
          {title}

          {children}
        </Box>
      )}
    </>
  );
}

ContentBox.propTypes = {
  isFoldable: PropTypes.bool,
  initialFolded: PropTypes.bool,
  withBorder: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
};
