import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library-ui/components/ImageLoaderHandler";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useEffect, useRef } from "react";
import { Href } from "../../../../components/Href";
import { Z_INDEX } from "../../../../constants/zIndex";
import { useHeader } from "../../../../hooks/useHeader";
import { Menu } from "./Menu";
import { TopBar } from "./TopBar";

export function Header() {
  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const headerRef = useRef(null);

  const { setPageHeaderHeight } = useHeader();

  useEffect(() => {
    const handler = () => {
      const header = headerRef.current;
      if (header) {
        setPageHeaderHeight(header.clientHeight);
      }
    };

    handler();

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, [setPageHeaderHeight]);

  return (
    <>
      <Box
        ref={headerRef}
        position="sticky"
        top="0"
        flexShrink={0}
        zIndex={Z_INDEX.header}>
        <TopBar />

        <Menu />
      </Box>

      {/* top image on mobile only */}

      <Flex
        display={{ base: "flex", lg: "none" }}
        justify={{ base: "center", lg: "flex-start" }}
        backgroundColor="#fff"
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        pl={{ base: "0" }}>
        <Href href={generateFrontPath({ id: "@front.home" })}>
          <Box py="1rem">
            <AspectRatio ratio={260 / 62} w="12.5rem">
              <ImageLoaderHandler
                src={generateFrontPath({
                  id: "@front.internal-assets",
                  parameters: { filePath: "logo.svg" },
                  includeBasePath: true,
                })}>
                {({ ref, src, isLoaded, handleLoad }) => (
                  <Image
                    ref={ref}
                    src={src}
                    onLoad={handleLoad}
                    opacity={isLoaded ? 1 : 0}
                    transition="opacity 0.25s"
                    alt="Clévacances"
                    loading="lazy"
                  />
                )}
              </ImageLoaderHandler>
            </AspectRatio>
          </Box>
        </Href>
      </Flex>
    </>
  );
}
