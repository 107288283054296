import { Box } from "@chakra-ui/react";
import { CenteredContent } from "../../../../components/CenteredContent";

export function Wrapper({ bgColor, children }) {
  return (
    <Box bgColor={bgColor} px="1rem">
      <CenteredContent>{children}</CenteredContent>
    </Box>
  );
}
