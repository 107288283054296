export const COOKIES_NAME_VALUE_PAGINATION_PER_PAGE = "pagination-per-page";
export const COOKIES_NAME_VALUE_NEXT_LOCALE = "NEXT_LOCALE";
export const COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE = "chakra-ui-color-mode";
export const COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS = "default-environments";
export const COOKIES_NAME_VALUE_ACCEPT_COOKIES = "accept-cookies";
export const COOKIES_NAME_VALUE_BYPASS_MAINTENANCE = "bypass-maintenance";

export const COOKIES_NAME_VALUE_CLOSE_ROOM_COMPLETION_ALERT =
  "close-room-completion-alert";
export const COOKIES_NAME_VALUE_CLOSE_HOTEL_COMPLETION_ALERT =
  "close-hotel-completion-alert";
export const COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT =
  "close-validate-email-alert";
export const COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT =
  "close-two-factor-auth-alert";
export const COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT =
  "close-contacts-not-verified-alert";
export const COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT =
  "close-dgfip-missing-alert";
export const COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT =
  "close-missing-attributes-alert";

export const COOKIES_NAMES = {
  [COOKIES_NAME_VALUE_PAGINATION_PER_PAGE]: {
    id: COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  },
  [COOKIES_NAME_VALUE_NEXT_LOCALE]: {
    id: COOKIES_NAME_VALUE_NEXT_LOCALE,
  },
  [COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE]: {
    id: COOKIES_NAME_VALUE_CHAKRA_UI_COLOR_MODE,
  },
  [COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS]: {
    id: COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS,
  },
  [COOKIES_NAME_VALUE_ACCEPT_COOKIES]: {
    id: COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  },
  [COOKIES_NAME_VALUE_BYPASS_MAINTENANCE]: {
    id: COOKIES_NAME_VALUE_BYPASS_MAINTENANCE,
  },
  [COOKIES_NAME_VALUE_CLOSE_ROOM_COMPLETION_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_ROOM_COMPLETION_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_HOTEL_COMPLETION_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_HOTEL_COMPLETION_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT,
  },
  [COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT]: {
    id: COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT,
  },
};

export const COOKIES_NAME_LIST = Object.values(COOKIES_NAMES);
