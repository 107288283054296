/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const MODAL_THEME = {
  parts: ["dialog"],
  baseStyle: {
    dialog: {
      borderRadius: "20px",
    },
  },
  defaultProps: {
    isCentered: true,
  },
};
