import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import { useIntl } from "react-intl";
import Handler from "./Handler";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} [onClose]
 * @property {() => void} onSuccess
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {boolean} [isClosable]
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export function DgFipModal({
  isOpen,
  onClose,
  onSuccess,
  customer,
  isClosable = true,
}) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      scrollBehavior="inside"
      blockScrollOnMount
      closeOnOverlayClick={isClosable}
      size="4xl">
      <ModalOverlay />

      <ModalContent>
        {isClosable && <ModalCloseButton />}

        <ModalHeader>
          {intl.formatMessage({
            defaultMessage: "Informations fiscales manquantes",
          })}
        </ModalHeader>

        <ApiErrorProvider>
          <Handler
            customer={customer}
            onClose={onClose}
            onSuccess={onSuccess}
            isClosable={isClosable}
          />
        </ApiErrorProvider>
      </ModalContent>
    </Modal>
  );
}
