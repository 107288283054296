/* eslint-disable @next/next/no-img-element */
/* istanbul ignore file */

// deps
import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { useIntl } from "react-intl";
import {
  Box,
  Image,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  VStack,
  FormErrorMessage,
} from "@chakra-ui/react";

// helpers
import { filesGetSrc } from "../../../helpers/files";

const RATIO_LIST = [
  { label: "16:9", value: 16 / 9 },
  { label: "4:3", value: 4 / 3 },
  { label: "3:2", value: 3 / 2 },
  { label: "1:1", value: 1 },
];

/**
 * Retourne la taille maximale de l'image appropriée.
 * @param {object} param0
 * @param {number} [param0.ratio]
 * @param {number} param0.naturalWidth
 * @param {number} param0.naturalHeight
 * @returns {{width: number, height: number}}
 */
function getBestDefaultSizes({ ratio, naturalWidth, naturalHeight }) {
  const imageRatio = naturalWidth / naturalHeight;
  const size = {
    width: 100,
    height: 100,
  };
  if (ratio !== undefined) {
    // we determine if the limiting factor is the width or the height
    if (imageRatio > ratio) {
      // the limiting factor is the height
      size.height = 100;
      size.width = (100 * ratio) / imageRatio;
    }
    if (imageRatio < ratio) {
      // the limiting factor is the width
      size.width = 100;
      size.height = (100 * imageRatio) / ratio;
    }
  }
  return size;
}

function computeWithNewValue({
  value,
  crop,
  type,
  naturalWidth,
  width,
  naturalHeight,
  transformationsName,
  ratio,
  formatName,
}) {
  switch (type) {
    case "ratio": {
      const ratio = value;

      const newHeight = Math.round(width / ratio);

      const newValues = [
        {
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[height]`,
          value: newHeight,
        },
        {
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[ratio]`,
          value: ratio,
        },
      ];

      const newCrop = {
        ...crop,
        height: (100 * newHeight) / naturalHeight,
      };

      return { newValues, newCrop };
    }

    case "width": {
      const newWidth = value;

      if (isNaN(newWidth)) {
        const newValues = [
          {
            name: `${transformationsName}${
              formatName ? `[${formatName}]` : ""
            }[width]`,
            value: undefined,
          },
        ];

        const newCrop = {
          width: undefined,
        };

        if (ratio) {
          newCrop.height = undefined;

          newValues.push({
            name: `${transformationsName}${
              formatName ? `[${formatName}]` : ""
            }[height]`,
            value: undefined,
          });
        }

        return {
          newValues,
          newCrop,
        };
      }

      const newValues = [
        {
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[width]`,
          value: newWidth,
        },
      ];

      const newCrop = {
        ...crop,
        width: (100 * newWidth) / naturalWidth,
      };

      if (ratio) {
        const newHeight = Math.round(newWidth / ratio);

        newValues.push({
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[height]`,
          value: newHeight,
        });

        newCrop.height = (100 * newHeight) / naturalHeight;
      }

      return { newValues, newCrop };
    }

    case "height": {
      const newHeight = value;

      if (isNaN(newHeight)) {
        const newValues = [
          {
            name: `${transformationsName}${
              formatName ? `[${formatName}]` : ""
            }[height]`,
            value: undefined,
          },
        ];

        const newCrop = {
          height: undefined,
        };

        if (ratio) {
          newCrop.width = undefined;

          newValues.push({
            name: `${transformationsName}${
              formatName ? `[${formatName}]` : ""
            }[width]`,
            value: undefined,
          });
        }

        return {
          newValues,
          newCrop,
        };
      }

      const newCrop = {
        ...crop,
        height: (100 * newHeight) / naturalHeight,
      };

      const newValues = [
        {
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[height]`,
          value: newHeight,
        },
      ];

      if (ratio) {
        const newWidth = Math.round(newHeight * ratio);

        newValues.push({
          name: `${transformationsName}${
            formatName ? `[${formatName}]` : ""
          }[width]`,
          value: newWidth,
        });

        newCrop.width = (100 * newWidth) / naturalWidth;
      }

      return {
        newValues,
        newCrop,
      };
    }
  }
}

export default function ImagesTransform(props) {
  const {
    form,
    image,
    transformationsName,
    formatName,
    variant,
    format,
    circularCrop,
    isDisabled,
  } = props;

  const intl = useIntl();

  const $img = useRef(/** @type {any} */ (null));

  const { min_width: minWidth, min_height: minHeight } = format;

  const hasRatioField = !format?.ratio;

  const ratio =
    format?.ratio ??
    form.getValue(
      `${transformationsName}${formatName ? `[${formatName}]` : ""}[ratio]`,
    );

  const [crop, setCrop] = useState(/** @type {any} */ (null));

  /**
   * Défini les valeurs du crop.
   * @param {object} param0
   * @param {object} param0.crop
   */
  function setFormValues({ crop }) {
    const naturalWidth = $img.current?.naturalWidth ?? 0;
    const naturalHeight = $img.current?.naturalHeight ?? 0;

    form.setBulkValue([
      {
        name: `${transformationsName}${formatName ? `[${formatName}]` : ""}[x]`,
        value: Math.round(naturalWidth * (crop.x / 100)),
      },
      {
        name: `${transformationsName}${formatName ? `[${formatName}]` : ""}[y]`,
        value: Math.round(naturalHeight * (crop.y / 100)),
      },
      {
        name: `${transformationsName}${
          formatName ? `[${formatName}]` : ""
        }[width]`,
        value: Math.round(naturalWidth * (crop.width / 100)),
      },
      {
        name: `${transformationsName}${
          formatName ? `[${formatName}]` : ""
        }[height]`,
        value: Math.round(naturalHeight * (crop.height / 100)),
      },
    ]);
  }

  const initialized = useRef(false);

  useEffect(
    function () {
      initialized.current = true;
    },
    [crop],
  );

  const widthClientNaturalRatio =
    ($img.current?.clientWidth ?? 1) / ($img.current?.naturalWidth ?? 1);
  const heightClientNaturalRatio =
    ($img.current?.clientHeight ?? 1) / ($img.current?.naturalHeight ?? 1);

  return (
    <Grid
      templateColumns={
        "tight" === variant ? { md: "repeat(7, 1fr)" } : undefined
      }
      gap="1rem">
      <GridItem gridColumn={"tight" === variant ? "1 / span 5" : undefined}>
        <Box display="flex" justifyContent="center" w="full">
          {isDisabled ? (
            <Image
              src={filesGetSrc({ content: form.fields })}
              alt={intl.formatMessage({ defaultMessage: "Image" })}
            />
          ) : (
            <ReactCrop
              crop={{ ...crop, aspect: ratio, unit: "%" }}
              aspect={ratio}
              minWidth={minWidth * widthClientNaturalRatio}
              maxWidth={$img.current?.clientWidth}
              minHeight={minHeight * heightClientNaturalRatio}
              maxHeight={$img.current?.clientHeight}
              circularCrop={circularCrop}
              onChange={function (_, newCrop) {
                if ($img.current && crop && initialized.current) {
                  setCrop(newCrop);
                }
              }}
              onComplete={function (_, newCrop) {
                if ($img.current && crop && initialized.current) {
                  setFormValues({ crop: newCrop });
                }
              }}>
              <img
                alt={intl.formatMessage({
                  defaultMessage: "Image à redimensionner",
                })}
                src={image}
                onLoad={function (event) {
                  $img.current = event.target;

                  const crop = form.getValue(
                    `${transformationsName}${
                      formatName ? `[${formatName}]` : ""
                    }`,
                  );

                  const naturalWidth = $img.current?.naturalWidth ?? 0;
                  const naturalHeight = $img.current?.naturalHeight ?? 0;

                  if (crop) {
                    const newCrop = {
                      x: (100 * crop.x) / naturalWidth,
                      y: (100 * crop.y) / naturalHeight,
                      width: (100 * crop.width) / naturalWidth,
                      height: (100 * crop.height) / naturalHeight,
                    };

                    setCrop(newCrop);

                    setFormValues({ crop: newCrop });

                    initialized.current = false;
                  } else {
                    const { width, height } = getBestDefaultSizes({
                      ratio,
                      naturalWidth,
                      naturalHeight,
                    });

                    const newCrop = {
                      x: 0,
                      y: 0,
                      width,
                      height,
                    };

                    setCrop(newCrop);

                    setFormValues({ crop: newCrop });

                    initialized.current = false;
                  }
                }}
              />
            </ReactCrop>
          )}
        </Box>
      </GridItem>

      <GridItem gridColumn={"tight" === variant ? "span 2" : undefined}>
        <VStack spacing="1rem">
          {hasRatioField && (
            <FormControl
              isDisabled={isDisabled}
              isInvalid={Boolean(
                form.getError(
                  `${transformationsName}${
                    formatName ? `[${formatName}]` : ""
                  }[ratio]`,
                ),
              )}>
              <FormLabel>
                {intl.formatMessage({
                  id: "raiden.library.components.Images.Transform.fields.ratio.label",
                  defaultMessage: "Ratio",
                })}
              </FormLabel>

              <Select
                {...form.inputProps({
                  name: `${transformationsName}${
                    formatName ? `[${formatName}]` : ""
                  }[ratio]`,
                })}
                onChange={function (event) {
                  const naturalWidth = $img.current?.naturalWidth ?? 0;
                  const naturalHeight = $img.current?.naturalHeight ?? 0;

                  const ratio = event.target.value;

                  if ("" !== ratio) {
                    const computedValues = computeWithNewValue({
                      value: ratio,
                      type: "ratio",
                      naturalWidth,
                      naturalHeight,
                      crop,
                      formatName,
                      ratio,
                      transformationsName,
                      width: form.getValue(
                        `${transformationsName}${
                          formatName ? `[${formatName}]` : ""
                        }[width]`,
                      ),
                    });

                    form.setBulkValue(computedValues?.newValues);
                    setCrop(computedValues?.newCrop);
                  } else {
                    form.setValue(
                      `${transformationsName}${
                        formatName ? `[${formatName}]` : ""
                      }[ratio]`,
                      undefined,
                    );
                  }
                }}>
                {RATIO_LIST.map(function (ratio) {
                  return (
                    <option key={ratio.label} value={ratio.value}>
                      {ratio.label}
                    </option>
                  );
                })}

                <option value="">
                  {intl.formatMessage({
                    id: "raiden.library.components.Images.Transform.fields.ratio.value.free",
                    defaultMessage: "Libre",
                  })}
                </option>
              </Select>

              <FormErrorMessage>
                {form.getError(
                  `${transformationsName}${
                    formatName ? `[${formatName}]` : ""
                  }[ratio]`,
                )}
              </FormErrorMessage>
            </FormControl>
          )}

          <FormControl
            isDisabled={isDisabled}
            isInvalid={Boolean(
              form.getError(
                `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[width]`,
              ),
            )}>
            <FormLabel>
              {intl.formatMessage({
                id: "raiden.library.components.Images.Transform.fields.width.label",
                defaultMessage: "Largeur",
              })}
            </FormLabel>

            <NumberInput
              {...form.inputProps({
                name: `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[width]`,
              })}
              min={minWidth}
              max={$img.current?.naturalWidth}
              step={1}
              onChange={function (_, newWidth) {
                const naturalWidth = $img.current?.naturalWidth ?? 0;
                const naturalHeight = $img.current?.naturalHeight ?? 0;

                // @ts-ignore
                const { newValues, newCrop } = computeWithNewValue({
                  value: newWidth,
                  type: "width",
                  naturalWidth,
                  naturalHeight,
                  crop,
                  formatName,
                  ratio,
                  transformationsName,
                });

                form.setBulkValue(newValues);
                setCrop(newCrop);
              }}>
              <NumberInputField />

              <NumberInputStepper>
                <NumberIncrementStepper />

                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {minWidth && (
              <FormHelperText>
                {intl.formatMessage(
                  {
                    id: "raiden.library.components.Images.Transform.fields.width.helper",
                    defaultMessage: "Largeur minimale : {minWidth}px",
                  },
                  {
                    minWidth,
                  },
                )}
              </FormHelperText>
            )}

            <FormErrorMessage>
              {form.getError(
                `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[width]`,
              )}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isDisabled={isDisabled}
            isInvalid={Boolean(
              form.getError(
                `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[height]`,
              ),
            )}>
            <FormLabel>
              {intl.formatMessage({
                id: "raiden.library.components.Images.Transform.fields.height.label",
                defaultMessage: "Hauteur",
              })}
            </FormLabel>

            <NumberInput
              {...form.inputProps({
                name: `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[height]`,
              })}
              min={minHeight}
              max={$img.current?.naturalHeight}
              step={1}
              onChange={function (_, newHeight) {
                const naturalWidth = $img.current?.naturalWidth ?? 0;
                const naturalHeight = $img.current?.naturalHeight ?? 0;

                // @ts-ignore
                const { newValues, newCrop } = computeWithNewValue({
                  value: newHeight,
                  type: "height",
                  naturalWidth,
                  naturalHeight,
                  crop,
                  formatName,
                  ratio,
                  transformationsName,
                });

                form.setBulkValue(newValues);
                setCrop(newCrop);
              }}>
              <NumberInputField />

              <NumberInputStepper>
                <NumberIncrementStepper />

                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            {minHeight && (
              <FormHelperText>
                {intl.formatMessage(
                  {
                    id: "raiden.library.components.Images.Transform.fields.height.helper",
                    defaultMessage: "Hauteur minimale : {minHeight}px",
                  },
                  {
                    minHeight,
                  },
                )}
              </FormHelperText>
            )}

            <FormErrorMessage>
              {form.getError(
                `${transformationsName}${
                  formatName ? `[${formatName}]` : ""
                }[height]`,
              )}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      </GridItem>
    </Grid>
  );
}

ImagesTransform.propTypes = {
  variant: PropTypes.oneOf(["tight", "expanded"]),
  form: PropTypes.object,
  circularCrop: PropTypes.bool,
  image: PropTypes.any,
  transformationsName: PropTypes.string,
  formatName: PropTypes.string,
  format: PropTypes.object,
  isDisabled: PropTypes.bool,
};
