import { Box, HStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { CenteredContent } from "../../../../components/CenteredContent";
import LocaleSelect from "../../../../components/LocaleSelect";
import { Copyright } from "./Copyright";
import { Menu } from "./Menu";
import { NewsLetter } from "./NewsLetter";

export function Footer() {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <>
      <NewsLetter />

      <Box px="1rem" pt="3.125rem">
        <CenteredContent>
          <Menu />

          <HStack mt="3.125rem">
            <Copyright year={currentYear} />

            <LocaleSelect />
          </HStack>
        </CenteredContent>
      </Box>
    </>
  );
}
