import { Box, Button, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { Wrapper } from "../Wrapper";
import { useCallback } from "react";
import { useCookies } from "../../../../../hooks/useCookies";
import { COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT } from "@raiden/library-ui/constants/cookies";
import cookie from "cookie";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 */

/**
 * @param {Props} props
 */
export function WarningEmailVerification({ customer }) {
  const intl = useIntl();

  const { setCookies } = useCookies();

  const handleClose = () => {
    setCookies((currentValue) => ({
      ...currentValue,
      [COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT]: "1",
    }));
    document.cookie = cookie.serialize(
      COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT,
      "1",
      {
        path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
        secure: true,
      },
    );
  };

  const { request, isLoading, toastSuccess, toastError } = useRequest();

  const handleEmailVerification = useCallback(() => {
    request(
      generateApiUrl({
        id: "@customers.notifyEmailVerification",
        parameters: {
          customerId: customer.id,
        },
      }),
      {
        method: "POST",
      },
    )
      .then(() => {
        toastSuccess({
          title: intl.formatMessage({
            defaultMessage:
              "Un email contenant un lien de validation va vous être envoyé.",
          }),
        });
      })
      .catch((error) => {
        const message = apiGetErrorDetail({ error });

        toastError({
          title: intl.formatMessage({
            defaultMessage:
              "Une erreur est survenue lors de l'envoi de l'email.",
          }),
          description: message,
        });
      });
  }, [customer.id, intl, request, toastError, toastSuccess]);

  return (
    <Wrapper bgColor="blue.100">
      <Stack
        direction={{ base: "column", md: "row" }}
        py=".75rem"
        alignItems="center"
        spacing="16px"
        flexGrow={1}>
        <HStack flexGrow={1}>
          <Box flexShrink={0} display={{ base: "none", md: "block" }}>
            <Icon icon="ms_info" color="brandPrimary.500" size="24px" />
          </Box>

          <Flex flexDir="column">
            <Text fontWeight="600" color="gray.700">
              <FormattedMessage defaultMessage="Veuillez valider votre adresse e-mail" />
            </Text>

            <Text color="gray.700">
              <FormattedMessage
                defaultMessage="Merci de cliquer sur le lien de validation dans l’email envoyé à {email}."
                values={{ email: customer.email }}
              />
            </Text>
          </Flex>
        </HStack>

        <Flex ml={{ base: "0", md: "auto" }} gap="1rem">
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            colorScheme="brandPrimary"
            onClick={handleEmailVerification}>
            <FormattedMessage defaultMessage="Recevoir de nouveau l'e-mail" />
          </Button>

          <Box cursor="pointer">
            <Button variant="unstyled" onClick={handleClose}>
              <Icon icon="ms_close" color="gray.700" size="24px" />
            </Button>
          </Box>
        </Flex>
      </Stack>
    </Wrapper>
  );
}
