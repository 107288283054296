import { Image, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { FormattedMessage } from "react-intl";

export function Splash404Public() {
  return (
    <Stack spacing="1.5rem" textAlign="center">
      <Stack spacing="1rem">
        <Image
          src={generateFrontPath({
            id: "@front.internal-assets",
            parameters: { filePath: "illustrations/not-found-public.svg" },
            includeBasePath: true,
          })}
          w="320px"
          maxWidth="100%"
          margin="auto"
          alt=""
        />

        <Text variant="h3">
          <FormattedMessage defaultMessage="Nous sommes désolés, cette page est introuvable ou n'existe pas" />
        </Text>

        <Text variant="h5">
          <FormattedMessage defaultMessage="Cette page n'existe pas ou a été supprimée. Retournez à l'accueil ou commencez une nouvelle recherche." />
        </Text>
      </Stack>

      {/* TODO search engine */}
    </Stack>
  );
}
