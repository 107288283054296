import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { serverApiFetcher } from "../../../hooks/useApiFetcher";
import generateApiUri from "../../../libraries/utils/generateApiUrl";
import { USERS_USER_DATA_WINDOW_VALUE } from "../../../constants/users";

/**
 * @typedef {object} NextGetUserReturn
 * @property {import("../../../types/User").UserBase} [user]
 * @property {import("../../../types/Api/ApiError").ApiError} [error]
 */

/**
 * Récupère les informations sur l’utilisateur courant.
 * @param {object} param0
 * @param {object} param0.cookies
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} param0.baseUri
 * @param {import("http").IncomingMessage} [param0.req] Requête initiale de la page
 * @param {string} [param0.locale]
 * @param {Array<string>} param0.fields
 * @returns {Promise<NextGetUserReturn>}
 */
export default async function nextGetUser({
  cookies,
  baseUri,
  locale,
  req,
  fields,
}) {
  let error;

  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return {
      user: undefined,
      error: undefined,
    };
  }

  /** on vérifie si la valeur est déjà présente, dans ce cas on return cette valeur au lieux de faire une request */
  if (typeof window !== "undefined") {
    if (window[USERS_USER_DATA_WINDOW_VALUE]) {
      return window[USERS_USER_DATA_WINDOW_VALUE];
    }
  }

  /** @type {import("../../../types/Api/ApiResponse").ApiResponse<import("../../../types/User").UserBase | []> | undefined} */
  const response = await serverApiFetcher(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields,
      },
    }),
    undefined,
    {
      cookies,
      origin: baseUri.toString(),
      locale,
      req,
    },
  ).catch(function (tempError) {
    error = tempError;
  });

  return {
    user: Array.isArray(response?.data) ? undefined : response?.data,
    error,
  };
}
