import { forwardRef, RadioGroup } from "@chakra-ui/react";
import { useCallback } from "react";

const RadioGroupAdmin = forwardRef(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props & Omit<import("@chakra-ui/react").RadioGroupProps, "onChange"> & import("../../ReactHookForm/FormControlRHF").FieldProps<string>} props
   */
  function _RadioGroupAdmin(props, ref) {
    const { name, onChange: _onChange, children } = props;
    const normalizedProps = {
      ...props,
      isInvalid: undefined,
      isRequired: undefined,
    };

    const onChange = useCallback(
      (value) => {
        _onChange?.({ target: { name, value } });
      },
      [_onChange, name],
    );
    return (
      <RadioGroup {...normalizedProps} ref={ref} onChange={onChange}>
        {children}
      </RadioGroup>
    );
  },
);
export default RadioGroupAdmin;
