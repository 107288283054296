import generateFrontPath from "../../libraries/utils/generateFrontPath";
import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import { useState } from "react";

const fetcher = (url) => fetch(url).then((res) => res.json());

/**
 * @typedef {object} TranslationMessagesParams
 * @property {any} translationMessages
 */

/**
 * @typedef {object} Props
 * @property {any} initialTranslationMessages
 * @property {(params: TranslationMessagesParams) => React.ReactElement} children
 */
/**
 * @param {Props} props
 */
export function TranslationMessagesProvider({
  initialTranslationMessages: _initialTranslationMessages,
  children,
}) {
  const [initialTranslationMessages] = useState(_initialTranslationMessages);

  const { locale } = useLocale();

  const swrResponse = useSWR(
    locale
      ? generateFrontPath({
          id: "@front.api.translation-messages",
          query: {
            locale,
          },
        })
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
      // TODO enable
      // keepPreviousData: true,
    },
  );

  return (
    <>
      {children({
        translationMessages: swrResponse.data ?? initialTranslationMessages,
      })}
    </>
  );
}
