import { Flex } from "@chakra-ui/react";
import { memo } from "react";

export const PageLayoutPublic = memo(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactElement} [header]
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function PageLayoutPublic({ header, children }) {
    return (
      <Flex flexGrow={1} flexDirection="column-reverse" justify="flex-end">
        <Flex flexGrow={1} flexShrink={0} flexDir="column" py="2rem">
          {children}
        </Flex>

        {header}
      </Flex>
    );
  },
);
