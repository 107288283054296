import { Box, Button, Text, useColorModeValue } from "@chakra-ui/react";
import { forwardRef, memo } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

const MenuButton = memo(
  forwardRef(
    /**
     * @typedef {object} Props
     * @property {boolean} isOpened
     */
    /**
     * @param {Props & import("@chakra-ui/react").ButtonProps} props
     */
    function MenuButton({ isOpened, ...otherProps }, ref) {
      const intl = useIntl();

      const color = useColorModeValue("#002538", "#fff");
      const highlightedColor = useColorModeValue("#002538", "#fff");

      return (
        <Box flexGrow={1} flexBasis={0} position="relative">
          <Box
            position="absolute"
            left="50%"
            h="2px"
            w="full"
            backgroundColor="brandSecondary.500"
            pointerEvents="none"
            opacity={isOpened ? 1 : 0}
            transform={`translateX(-50%) scaleX(${isOpened ? 1 : 0})`}
            transition="all .25s ease"
          />

          <Button
            variant="unstyled"
            display="flex"
            height="auto"
            flexDirection="column"
            fontWeight="normal"
            ref={ref}
            color={isOpened ? highlightedColor : color}
            role="group"
            py=".5rem"
            w="full"
            {...otherProps}>
            <Box borderRadius="6px" px="0.75rem" py="0.125rem">
              <Icon icon="ms_menu" color={color} size="2rem" />
            </Box>

            <Text
              as="span"
              variant="text-xs"
              color="inherit"
              mt="0.375rem"
              _groupHover={{ textDecoration: { lg: "underline" } }}>
              {intl.formatMessage({ defaultMessage: "Menu" })}
            </Text>
          </Button>
        </Box>
      );
    },
  ),
);

export default MenuButton;
