// deps
import { defineMessage } from "react-intl";
import { MdBlock, MdLoop, MdDownloadDone, MdHistory } from "react-icons/md";

/* Customers contacts customer_types */
export const CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR = "particular";
export const CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_COMPANY = "company";
export const CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_ASSOCIATION = "association";
export const CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_INSTITUTION = "institution";

export const CUSTOMERS_CONTACTS_CUSTOMER_TYPES = {
  [CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR]: {
    id: CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR,
    label: defineMessage({ defaultMessage: "particulier" }),
  },
  [CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_COMPANY]: {
    id: CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_COMPANY,
    label: defineMessage({ defaultMessage: "entreprise" }),
  },
  [CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_ASSOCIATION]: {
    id: CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_ASSOCIATION,
    label: defineMessage({ defaultMessage: "association" }),
  },
  [CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_INSTITUTION]: {
    id: CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_INSTITUTION,
    label: defineMessage({ defaultMessage: "institution" }),
  },
};

export const CUSTOMERS_CONTACTS_CUSTOMER_TYPE_LIST = Object.values(
  CUSTOMERS_CONTACTS_CUSTOMER_TYPES,
);

/* Customers contacts state */
export const CUSTOMERS_CONTACTS_STATE_VALUE_PENDING = "pending";
export const CUSTOMERS_CONTACTS_STATE_VALUE_IN_PROGRESS = "in_progress";
export const CUSTOMERS_CONTACTS_STATE_VALUE_COMPLETED = "completed";
export const CUSTOMERS_CONTACTS_STATE_VALUE_CANCELED = "canceled";

export const CUSTOMERS_CONTACTS_STATES = {
  [CUSTOMERS_CONTACTS_STATE_VALUE_PENDING]: {
    id: CUSTOMERS_CONTACTS_STATE_VALUE_PENDING,
    mdIcon: MdLoop,
    description: defineMessage({ defaultMessage: "contact en attente" }),
    label: defineMessage({ defaultMessage: "en attente" }),
    dateLabel: defineMessage({ defaultMessage: "créé le" }),
    responseDate: "created_at",
    colors: ["orange.600", "orange.300"],
  },
  [CUSTOMERS_CONTACTS_STATE_VALUE_IN_PROGRESS]: {
    id: CUSTOMERS_CONTACTS_STATE_VALUE_IN_PROGRESS,
    mdIcon: MdHistory,
    description: defineMessage({ defaultMessage: "contact en cours" }),
    label: defineMessage({ defaultMessage: "en cours" }),
    dateLabel: defineMessage({ defaultMessage: "démarré le" }),
    responseDate: "started_at",
    colors: ["purple.600", "purple.300"],
  },
  [CUSTOMERS_CONTACTS_STATE_VALUE_COMPLETED]: {
    id: CUSTOMERS_CONTACTS_STATE_VALUE_COMPLETED,
    mdIcon: MdDownloadDone,
    description: defineMessage({
      defaultMessage: "contact traité et finalisé",
    }),
    label: defineMessage({ defaultMessage: "traité" }),
    dateLabel: defineMessage({ defaultMessage: "terminé le" }),
    responseDate: "completed_at",
    colors: ["green.600", "green.300"],
  },
  [CUSTOMERS_CONTACTS_STATE_VALUE_CANCELED]: {
    id: CUSTOMERS_CONTACTS_STATE_VALUE_CANCELED,
    mdIcon: MdBlock,
    description: defineMessage({ defaultMessage: "contact abandonné" }),
    label: defineMessage({ defaultMessage: "abandonné" }),
    dateLabel: defineMessage({ defaultMessage: "abandonné le" }),
    responseDate: "canceled_at",
    colors: ["red.600", "red.300"],
  },
};

export const CUSTOMERS_CONTACTS_STATE_LIST = Object.values(
  CUSTOMERS_CONTACTS_STATES,
);

/* Customers contacts sorts */
export const CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_DESC = "created_desc";

export const CUSTOMERS_CONTACTS_SORTS = {
  [CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_ASC]: {
    id: CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_ASC,
    label: defineMessage({ defaultMessage: "date de création croissante" }),
  },
  [CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_DESC]: {
    id: CUSTOMERS_CONTACTS_SORT_VALUE_CREATED_DESC,
    label: defineMessage({ defaultMessage: "date de création décroissante" }),
  },
};

export const CUSTOMERS_CONTACTS_SORT_LIST = Object.values(
  CUSTOMERS_CONTACTS_SORTS,
);
