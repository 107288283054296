import {
  CUSTOMERS_WARNING_CONTACTS_NOT_VERIFIED,
  CUSTOMERS_WARNING_DGFIP_MISSING,
  CUSTOMERS_WARNING_TWO_FACTOR_NOT_ACTIVATED,
  CUSTOMERS_WARNING_VALUE_EMAIL_NOT_VERIFIED,
} from "@raiden/library-ui/constants/customers";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import { getCompletionPercentage } from "@raiden/library-ui/helpers/customers/getCompletionPercentage";
import { CUSTOMER_ALL_FORM_FIELDS } from "@raiden/library-ui/constants/customer/profileCompletionFields";
import { WarningContactsNotVerified } from "./Types/WarningContactsNotVerified";
import { WarningDgfipMissing } from "./Types/WarningDgfipMissing";
import { WarningEmailVerification } from "./Types/WarningEmailVerification";
import { WarningMissingAttributes } from "./Types/WarningMissingAttributes";
import { WarningTwoFactorAuthNotActivated } from "./Types/WarningTwoFactorAuthNotActivated";
import { useCookies } from "../../../../hooks/useCookies";
import {
  COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT,
  COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT,
  COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT,
  COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT,
  COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT,
} from "@raiden/library-ui/constants/cookies";

export function Warning() {
  const { userCustomer, mutate } = useAuth();
  const warnings = userCustomer?.meta?.warnings ?? [];

  const missingAttributes = userCustomer?.meta?.missing_attributes ?? [];

  const { cookies } = useCookies();

  if (!userCustomer) {
    return null;
  }

  if (
    warnings.includes(CUSTOMERS_WARNING_VALUE_EMAIL_NOT_VERIFIED) &&
    !cookies[COOKIES_NAME_VALUE_CLOSE_VALIDATE_EMAIL_ALERT]
  ) {
    return <WarningEmailVerification customer={userCustomer} />;
  }
  if (
    warnings.includes(CUSTOMERS_WARNING_CONTACTS_NOT_VERIFIED) &&
    !cookies[COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT]
  ) {
    return <WarningContactsNotVerified />;
  }

  if (
    warnings.includes(CUSTOMERS_WARNING_DGFIP_MISSING) &&
    !cookies[COOKIES_NAME_VALUE_CLOSE_DGFIP_MISSING_ALERT]
  ) {
    return <WarningDgfipMissing customer={userCustomer} onSuccess={mutate} />;
  }

  if (
    (missingAttributes?.length ?? 0) > 0 &&
    !cookies[COOKIES_NAME_VALUE_CLOSE_MISSING_ATTRIBUTES_ALERT]
  ) {
    return (
      <WarningMissingAttributes
        completionPercentage={getCompletionPercentage({
          formFields: CUSTOMER_ALL_FORM_FIELDS,
          missingAttributes,
        })}
      />
    );
  }

  if (
    warnings.includes(CUSTOMERS_WARNING_TWO_FACTOR_NOT_ACTIVATED) &&
    !cookies[COOKIES_NAME_VALUE_CLOSE_TWO_FACTOR_AUTH_ALERT]
  ) {
    return <WarningTwoFactorAuthNotActivated />;
  }

  return null;
}
