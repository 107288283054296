import { Box, Spinner, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { INPUT_AUTO_FILL_MIN_CHARACTERS } from ".";
import { useApi } from "../../../hooks/useApi";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { DataHandler } from "../../DataHandler";
import { InputAddressResults } from "./Results";

/**
 * @typedef {object} Props
 * @property {string} term
 * @property {import("./Place").InputAutoFillOnClickCallback} onClickPlace
 * @property {import("react").MutableRefObject<HTMLElement[]>} refOptions
 * @property {import("react").MutableRefObject<HTMLInputElement | null>} inputRef
 */
/**
 * @param {Props} props
 */
export function InputAddressBody({ term, onClickPlace, refOptions, inputRef }) {
  const url = useMemo(() => {
    return term.length >= INPUT_AUTO_FILL_MIN_CHARACTERS
      ? generateApiUrl({
          id: "@places.autocomplete",
          query: {
            term: term,
            mode: "address",
          },
        })
      : null;
  }, [term]);

  /** @type {import("../../../hooks/useApi").UseApi<import("../../../types/PlaceAutocomplete.js").PlaceAutocomplete[]>} */
  const { swrResponse } = useApi(url, {
    swrConfig: {
      keepPreviousData: true,
    },
  });

  return (
    <Box minH="38px">
      {(swrResponse.isValidating || url === null) && (
        <Box position="absolute" top="6px" right="6px">
          <Spinner />
        </Box>
      )}
      <DataHandler
        swrResponse={swrResponse}
        loading={null}
        keepDataOnRevalidation={true}
        noResult={
          <Box py="7px" px="15px">
            <Text color="gray.500">
              <FormattedMessage defaultMessage="Aucun résultat" />
            </Text>
          </Box>
        }>
        {({ data: placesAutocomplete }) => (
          <InputAddressResults
            onClickPlace={onClickPlace}
            term={term}
            placesAutocomplete={placesAutocomplete}
            refOptions={refOptions}
            inputRef={inputRef}
          />
        )}
      </DataHandler>
    </Box>
  );
}
