import { Box } from "@chakra-ui/react";
import {
  BlockButton,
  BlockButtonParams,
  BlockButtonTopEditor,
} from "@splitfire-agency/raiden-le-editeur/dist/BlockButton";
import BlockButtonChakra from "../components/BlockButtonChakra";
import {
  BlockCode,
  BlockCodeEditor,
} from "@splitfire-agency/raiden-le-editeur/dist/BlockCode";
import BlockCodeChakra from "@splitfire-agency/raiden-le-editeur/dist/BlockCodeChakra";
import {
  BLOCK_BUTTON,
  BLOCK_CODE,
  BLOCK_SPACER,
  BLOCK_TEXT,
  BLOCK_VIDEO,
} from "@splitfire-agency/raiden-le-editeur/dist/blocks";
import {
  BlockSpacer,
  BlockSpacerEditorTop,
} from "@splitfire-agency/raiden-le-editeur/dist/BlockSpacer";
import BlockSpacerChakra from "@splitfire-agency/raiden-le-editeur/dist/BlockSpacerChakra";
import { BlockText } from "@splitfire-agency/raiden-le-editeur/dist/BlockText";
import BlockTextChakra from "@splitfire-agency/raiden-le-editeur/dist/BlockTextChakra";
import {
  BlockVideo,
  BlockVideoParams,
  BlockVideoTopEditor,
} from "@splitfire-agency/raiden-le-editeur/dist/BlockVideo";
import BlockVideoChakra from "@splitfire-agency/raiden-le-editeur/dist/BlockVideoChakra";
import dynamic from "next/dynamic";
import { defineMessage } from "react-intl";
import { Icon } from "../components/Icon";
import {
  FilesEditorImageBlock,
  FilesEditorImageEditor,
} from "../containers/Files/EditorImage";
import { FilesEditorImageBlockChakra } from "../containers/Files/EditorImage/BlockChakra";
import { BLOCK_FILES_IMAGE } from "../containers/Files/EditorImage/BlockData";
import { BLOCK_LEAD_FORM_LABEL } from "../containers/Files/LeadsCreate/BlockDataLabel";
import { BLOCK_LEAD_FORM_RANKING } from "../containers/Files/LeadsCreate/BlockDataRanking";
import { getLeadsCreateBlock } from "../containers/Files/LeadsCreate/getLeadsCreateBlock";

const BlockTextEditor = dynamic(
  () => import("@splitfire-agency/raiden-le-editeur/dist/BlockTextEditor"),
  {
    ssr: false,
  },
);

export const POSTS_EDITOR_TOOLS = [
  {
    name: "Bouton",
    block: BLOCK_BUTTON,
    component: BlockButton,
    editorTop: BlockButtonTopEditor,
    params: BlockButtonParams,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_link" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  {
    name: "Vidéo",
    block: BLOCK_VIDEO,
    component: BlockVideo,
    editorTop: BlockVideoTopEditor,
    params: BlockVideoParams,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_movie" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  {
    name: "Code",
    block: BLOCK_CODE,
    component: BlockCode,
    editor: BlockCodeEditor,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_code" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  {
    name: "Éspace",
    block: BLOCK_SPACER,
    component: BlockSpacer,
    editorTop: BlockSpacerEditorTop,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_expand" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  {
    name: "Texte",
    block: BLOCK_TEXT,
    component: BlockText,
    editor: BlockTextEditor,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_subject" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  {
    name: "Image",
    block: BLOCK_FILES_IMAGE,
    component: FilesEditorImageBlock,
    editorTop: FilesEditorImageEditor,
    icon: (
      <Box flexShrink={0}>
        <Icon icon="ms_photo" size="1.5rem" color="brandPrimary.400" />
      </Box>
    ),
  },
  // les formulaires de label et de classement sont ajoutés conditionnellement dans le module de posts
];

export const POSTS_RENDERER_TOOLS = [
  {
    name: "Button",
    block: BLOCK_BUTTON,
    component: BlockButtonChakra,
  },
  {
    name: "Video",
    block: BLOCK_VIDEO,
    component: BlockVideoChakra,
  },
  {
    name: "Code",
    block: BLOCK_CODE,
    component: BlockCodeChakra,
  },
  {
    name: "Spacer",
    block: BLOCK_SPACER,
    component: BlockSpacerChakra,
  },
  {
    name: "TextEditor",
    block: BLOCK_TEXT,
    component: BlockTextChakra,
  },
  {
    name: "Image",
    block: BLOCK_FILES_IMAGE,
    component: FilesEditorImageBlockChakra,
  },
  {
    name: "Formulaire label",
    block: BLOCK_LEAD_FORM_LABEL,
    component: getLeadsCreateBlock({ type: "label", isEditor: false }),
  },
  {
    name: "Formulaire classement",
    block: BLOCK_LEAD_FORM_RANKING,
    component: getLeadsCreateBlock({ type: "ranking", isEditor: false }),
  },
];

export const postsFrontEditorToolMessage = defineMessage({
  id: "raiden.library.constants.posts.frontEditorTool",
  defaultMessage:
    "{tool, select, Button {Bouton} Code {Code} Image {Image} Spacer {Espacement} TextEditor {Éditeur de texte} Video {Vidéo} other {{type}}}",
});

export const POSTS_FRONT_EDITOR_COLOR_PALETTE_LIST = [
  "#2D3748",
  "#CBD5E0",
  "#FFFFFF",
  "#2C75BA",
  "#EAA82F",
];
