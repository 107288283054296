import { useRouterString } from "@raiden/library-ui/hooks/router/useRouterString";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import { useRouter } from "next/router";
import { memo, useCallback, useEffect, useState } from "react";
import { DgFipModal } from "../../../Customer/DgFipModal";
import { LegalConditionsModal } from "../../../Customer/LegalConditionsModal";
import { PasswordExpiredModal } from "../../../Customer/PasswordExpiredModal";

const MANDATORY_MODAL_DGFIP = "dgfip";
const MANDATORY_MODAL_PASSWORD_EXPIRED = "password_expired";
const MANDATORY_MODAL_LEGAL_CONDITIONS = "legal_conditions";

export const MandatoryModals = memo(function MandatoryModals() {
  const { userCustomer, mutate } = useAuth();

  const router = useRouter();

  const [modalsOpen, setModalsOpen] = useState(
    /** @type {Array<string>} */ ([]),
  );

  const verificationToken = useRouterString("verificationToken");

  const verifyPossibleException = useCallback(() => {
    // If a verification token is present in the URL, we should not display the mandatory modal because another modal will be displayed before
    if (verificationToken) {
      return false;
    }

    return true;
  }, [verificationToken]);

  useEffect(() => {
    const mustFillDgFipAttributes =
      userCustomer?.fiscal?.must_fill_dgfip_attributes ?? false;

    const isPasswordExpired = userCustomer?.is_password_expired ?? false;

    const isLegalAccepted = userCustomer?.is_legal_accepted ?? false;

    if (verifyPossibleException()) {
      if (isPasswordExpired) {
        openModal(MANDATORY_MODAL_PASSWORD_EXPIRED);
      }

      if (!isLegalAccepted) {
        openModal(MANDATORY_MODAL_LEGAL_CONDITIONS);
      }

      if (mustFillDgFipAttributes) {
        openModal(MANDATORY_MODAL_DGFIP);
      }
    }
  }, [router.query.verificationToken, userCustomer, verificationToken, verifyPossibleException]);

  function openModal(modal) {
    setModalsOpen((prev) => [...prev, modal]);
  }

  function closeModal(modal) {
    setModalsOpen((prev) => prev.filter((m) => m !== modal));
  }

  function onSuccess(modal) {
    mutate();
    closeModal(modal);
  }

  if (!userCustomer) {
    return null;
  }

  return (
    <>
      <DgFipModal
        customer={userCustomer}
        isOpen={modalsOpen.includes(MANDATORY_MODAL_DGFIP)}
        onSuccess={() => onSuccess(MANDATORY_MODAL_DGFIP)}
        isClosable={false}
      />

      <PasswordExpiredModal
        customer={userCustomer}
        isOpen={modalsOpen.includes(MANDATORY_MODAL_PASSWORD_EXPIRED)}
        onSuccess={() => onSuccess(MANDATORY_MODAL_PASSWORD_EXPIRED)}
        isClosable={false}
      />

      <LegalConditionsModal
        customer={userCustomer}
        isOpen={modalsOpen.includes(MANDATORY_MODAL_LEGAL_CONDITIONS)}
        onSuccess={() => onSuccess(MANDATORY_MODAL_LEGAL_CONDITIONS)}
        isClosable={false}
      />
    </>
  );
});
