import { LOCAL_STORAGE_PREFIX } from "../../constants/localStorage";

/**
 * @param {string} key
 * @param {any} value
 */
export function localStorageSetItem(key, value) {
  // server-side security
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, JSON.stringify(value));
}
