import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/DepartmentAutocomplete").DepartmentAutocomplete} param0.department
 * @returns {string}
 */
function getLabel({ department }) {
  let label = "";

  if (department?.name) {
    label = department.name;
  }

  return label;
}

export const DepartmentsAutocomplete = forwardRef(
  /**
   * @typedef {object} Props
   * @property {Array.<string>} [environmentId]
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function DepartmentsAutocomplete(
    { environmentId, queryParams = {}, ...otherProps },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getLabel({ department: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@departments.autocomplete",
          query: {
            page,
            per_page: 25,
            environment_id: environmentId,
            ...fields,
            ...queryParams,
          },
        }),
      [environmentId, queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@departments.autocomplete",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        isMultiple={false}
        {...otherProps}
      />
    );
  },
);
