// deps
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoPauseOutline,
  IoPlayOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

const CUSTOMERS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};

export const CUSTOMERS_SEARCH_TABS = [CUSTOMERS_SEARCH_TABS_VALUE_ALL];

export const CUSTOMERS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage: "{tab, select, all {Tous} trash {Archive} other {{tab}}}",
  description: "tab",
});

/* Tri */
export const CUSTOMERS_SORT_VALUE_CREATED_ASC = "created_asc";
export const CUSTOMERS_SORT_VALUE_CREATED_DESC = "created_desc";

export const customersSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} name_asc {Nom croissant} name_desc {Nom décroissant} other {{sort}}}",
});

export const CUSTOMERS_SORTS = {
  [CUSTOMERS_SORT_VALUE_CREATED_ASC]: {
    id: CUSTOMERS_SORT_VALUE_CREATED_ASC,
  },
  [CUSTOMERS_SORT_VALUE_CREATED_DESC]: {
    id: CUSTOMERS_SORT_VALUE_CREATED_DESC,
  },
};

export const CUSTOMERS_SORT_LIST = Object.values(CUSTOMERS_SORTS);

export const CUSTOMERS_STATES_VALUE_LEAD = "lead";
export const CUSTOMERS_STATES_VALUE_ENABLED = "enabled";
export const CUSTOMERS_STATES_VALUE_DISABLED = "disabled";

export const CUSTOMERS_STATES = {
  [CUSTOMERS_STATES_VALUE_ENABLED]: {
    id: CUSTOMERS_STATES_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    colorChart: "#2F855A",
    icon: IoPlayOutline,
  },
  [CUSTOMERS_STATES_VALUE_LEAD]: {
    id: CUSTOMERS_STATES_VALUE_LEAD,
    colors: ["blue.600", "blue.300"],
    color: "blue",
    colorChart: "#C05621",
    icon: IoAlertCircleOutline,
  },
  [CUSTOMERS_STATES_VALUE_DISABLED]: {
    id: CUSTOMERS_STATES_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    colorChart: "#664BBA",
    icon: IoPauseOutline,
  },
};

export const CUSTOMERS_STATE_LIST = Object.values(CUSTOMERS_STATES);

export const CUSTOMERS_STATES_MESSAGE = defineMessage({
  defaultMessage:
    "{state, select, lead {Prospect} enabled {Activé} disabled {Désactivé} other {inconnu}}",
});

export const CUSTOMERS_DG_FIP_CONFIG_STATES = {
  true: {
    colors: ["orange.600", "orange.300"],
    color: "orange",
    icon: IoAlertCircleOutline,
  },
  false: {
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoCheckmarkCircleOutline,
  },
};

export const customerDgFipConfigurationStatesMessage = defineMessage({
  defaultMessage: "{state, select, true {Incomplète} other {Complète}}",
});

export const CUSTOMERS_FISCAL_VALUE_PRO = "pro";
export const CUSTOMERS_FISCAL_VALUE_PAR = "par";

export const CUSTOMERS_FISCAL_VALUES = {
  [CUSTOMERS_FISCAL_VALUE_PRO]: {
    id: CUSTOMERS_FISCAL_VALUE_PRO,
  },
  [CUSTOMERS_FISCAL_VALUE_PAR]: {
    id: CUSTOMERS_FISCAL_VALUE_PAR,
  },
};

export const customersFiscalMessage = defineMessage({
  defaultMessage:
    "{isPro, select, true {professionnel} false {particulier} other {Non renseigné}}",
});

export const CUSTOMERS_TYPE_OF_PERSON_VALUE_PHYSICAL = "physique";
export const CUSTOMERS_TYPE_OF_PERSON_VALUE_MORALE = "morale";

export const CUSTOMERS_TYPES_OF_PERSON = {
  [CUSTOMERS_TYPE_OF_PERSON_VALUE_PHYSICAL]: {
    id: CUSTOMERS_TYPE_OF_PERSON_VALUE_PHYSICAL,
  },
  [CUSTOMERS_TYPE_OF_PERSON_VALUE_MORALE]: {
    id: CUSTOMERS_TYPE_OF_PERSON_VALUE_MORALE,
  },
};

export const CUSTOMERS_TYPES_OF_PERSON_LIST = Object.values(
  CUSTOMERS_TYPES_OF_PERSON,
);

export const customersTypeOfPersonMessage = defineMessage({
  defaultMessage:
    "{type, select, physique {Personne physique exerçant à titre professionnel} morale {Personne morale} other {type}}",
});

export const CUSTOMERS_CONTACT_USAGE_VALUE_DISPLAY = "display";
export const CUSTOMERS_CONTACT_USAGE_VALUE_CONTACT = "contact";

export const CUSTOMERS_CONTACT_TYPE_VALUE_MAIL = "mail";
export const CUSTOMERS_CONTACT_TYPE_VALUE_PHONE = "phone";

export const CUSTOMERS_CONTACT_STATE_VALUE_PENDING = "pending";
export const CUSTOMERS_CONTACT_STATE_VALUE_VERIFIED = "verified";
export const CUSTOMERS_CONTACT_STATE_VALUE_UNVERIFIED = "unverified";
export const CUSTOMERS_CONTACT_STATE_VALUE_EXPIRED = "expired";

export const CUSTOMERS_WARNING_VALUE_EMAIL_NOT_VERIFIED = "email_not_verified";
export const CUSTOMERS_WARNING_CONTACTS_NOT_VERIFIED = "contacts_not_verified";
export const CUSTOMERS_WARNING_DGFIP_MISSING = "dgfip_missing";
export const CUSTOMERS_WARNING_TWO_FACTOR_NOT_ACTIVATED =
  "two_factor_not_activated";

export const CUSTOMERS_WARNINGS = {
  [CUSTOMERS_WARNING_VALUE_EMAIL_NOT_VERIFIED]: {
    id: CUSTOMERS_WARNING_VALUE_EMAIL_NOT_VERIFIED,
  },
  [CUSTOMERS_WARNING_CONTACTS_NOT_VERIFIED]: {
    id: CUSTOMERS_WARNING_CONTACTS_NOT_VERIFIED,
  },
  [CUSTOMERS_WARNING_DGFIP_MISSING]: {
    id: CUSTOMERS_WARNING_DGFIP_MISSING,
  },
  [CUSTOMERS_WARNING_TWO_FACTOR_NOT_ACTIVATED]: {
    id: CUSTOMERS_WARNING_TWO_FACTOR_NOT_ACTIVATED,
  },
};

export const CUSTOMERS_WARNINGS_LIST = Object.values(CUSTOMERS_WARNINGS);

// Type is a legacy field, this is why theres no intl message with translation
export const CUSTOMERS_TYPE_VALUE_CUSTOMER = "Propriétaire";
export const CUSTOMERS_TYPE_VALUE_AGENCY = "Agence";
export const CUSTOMERS_TYPE_VALUE_DEPARTMENT = "Département";
export const CUSTOMERS_TYPE_VALUE_MANDATORY = "Mandataire non professionnel";
export const CUSTOMERS_TYPE_VALUE_CENTRAL = "Centrale de réservation";
export const CUSTOMERS_TYPE_VALUE_TOURISM = "Office de tourisme";
export const CUSTOMERS_TYPE_VALUE_SCI = "Indivision SCI";
export const CUSTOMERS_TYPE_VALUE_CONCIERGERIE = "Conciergerie";
export const CUSTOMERS_TYPE_VALUE_COUPLE = "Ménage";
export const CUSTOMERS_TYPE_VALUE_CLEVACANCES = "Clévacances France";
export const CUSTOMERS_TYPE_VALUE_ASSOCIATION = "Associations Clévacances";
export const CUSTOMERS_TYPE_VALUE_CDT = "CDT / ADT";

export const CUSTOMERS_TYPES = {
  [CUSTOMERS_TYPE_VALUE_CUSTOMER]: {
    id: CUSTOMERS_TYPE_VALUE_CUSTOMER,
  },
  [CUSTOMERS_TYPE_VALUE_AGENCY]: {
    id: CUSTOMERS_TYPE_VALUE_AGENCY,
  },
  [CUSTOMERS_TYPE_VALUE_DEPARTMENT]: {
    id: CUSTOMERS_TYPE_VALUE_DEPARTMENT,
  },
  [CUSTOMERS_TYPE_VALUE_MANDATORY]: {
    id: CUSTOMERS_TYPE_VALUE_MANDATORY,
  },
  [CUSTOMERS_TYPE_VALUE_CENTRAL]: {
    id: CUSTOMERS_TYPE_VALUE_CENTRAL,
  },
  [CUSTOMERS_TYPE_VALUE_TOURISM]: {
    id: CUSTOMERS_TYPE_VALUE_TOURISM,
  },
  [CUSTOMERS_TYPE_VALUE_SCI]: {
    id: CUSTOMERS_TYPE_VALUE_SCI,
  },
  [CUSTOMERS_TYPE_VALUE_CONCIERGERIE]: {
    id: CUSTOMERS_TYPE_VALUE_CONCIERGERIE,
  },
  [CUSTOMERS_TYPE_VALUE_COUPLE]: {
    id: CUSTOMERS_TYPE_VALUE_COUPLE,
  },
  [CUSTOMERS_TYPE_VALUE_CLEVACANCES]: {
    id: CUSTOMERS_TYPE_VALUE_CLEVACANCES,
  },
  [CUSTOMERS_TYPE_VALUE_ASSOCIATION]: {
    id: CUSTOMERS_TYPE_VALUE_ASSOCIATION,
  },
  [CUSTOMERS_TYPE_VALUE_CDT]: {
    id: CUSTOMERS_TYPE_VALUE_CDT,
  },
};

export const CUSTOMERS_TYPES_LIST = Object.values(CUSTOMERS_TYPES);

export const CUSTOMERS_ROLE_VALUE_OWNER = "owner";

export const CUSTOMERS_ROLE_VALUE_CUSTOMER = "customer";

export const CUSTOMERS_ROLES = {
  [CUSTOMERS_ROLE_VALUE_OWNER]: {
    id: CUSTOMERS_ROLE_VALUE_OWNER,
  },
  [CUSTOMERS_ROLE_VALUE_CUSTOMER]: {
    id: CUSTOMERS_ROLE_VALUE_CUSTOMER,
  },
};

export const customersSearchTabMessage = defineMessage({
  defaultMessage:
    "{tab, select, lead {Prospect} enabled {Activé} disabled {Désactivé} other {{tab}}}",
  description: "tab",
});

export const CUSTOMER_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};
