import { Center, Spinner } from "@chakra-ui/react";
import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { Splash } from "../components/Splash";

function SplashNoResult() {
  const intl = useIntl();

  return (
    <Splash
      src={generateFrontPath({
        id: "@front.internal-assets",
        parameters: { filePath: "illustrations/not-found.svg" },
        includeBasePath: true,
      })}
      title={intl.formatMessage({ defaultMessage: "Aucun résultat" })}
    />
  );
}

/**
 * @param {object} params
 * @param {import('@raiden/library-ui/types/Api/ApiError').ApiError} params.error
 */
function SplashError({ error }) {
  const intl = useIntl();

  return (
    <Splash
      src={generateFrontPath({
        id: "@front.internal-assets",
        parameters: { filePath: "illustrations/error.svg" },
        includeBasePath: true,
      })}
      title={intl.formatMessage({
        defaultMessage: "Une erreur est survenue",
      })}
      description={apiGetErrorDetail({ error })}
    />
  );
}

const loadingElement = (
  <Center>
    <Spinner size="lg" />
  </Center>
);

/** @type {import("@raiden/library-ui/components/DataHandler").DataHandlerContextValue} */
export const DATA_HANDLER_CONTEXT_VALUE = {
  loadingElement,
  SplashError,
  SplashNoResult,
};
