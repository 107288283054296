import { Box, HStack, useBreakpoint } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseOnNavigation from "../../../components/CloseOnNavigation";
import {
  HIGHLIGHTED_MENU_ITEMS,
  HIGHLITHED_MENU_ITEMS_MIN_WIDTH,
} from "../../../constants/menu";
import { Z_INDEX } from "../../../constants/zIndex";
import useBottomNavBar from "../../../hooks/useNavBar";
import { useScrollBlocker } from "../../../hooks/useScrollBlocker";
import MenuHighlighted from "./Menu/MenuHighlighted";
import MenuStandard from "./Menu/MenuStandard";
import MenuButton from "./MenuButton";

/**
 * @typedef {object} Props
 */
/**
 * @param {Props} props
 */
function BottomNavBar(props) {
  const [isOpened, setIsOpened] = useState(false);

  const { navBarHeight, setNavBarHeight } = useBottomNavBar();

  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const navbarRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setNavBarHeight(entries[0].target["offsetHeight"]);
    });
    let currentRef = navbarRef?.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [navbarRef, setNavBarHeight]);

  const { block, unblock } = useScrollBlocker();

  const breakpoint = useBreakpoint("base");

  const open = useCallback(() => {
    block();
    setIsOpened(true);
  }, [block]);

  const close = useCallback(() => {
    unblock();
    setIsOpened(false);
  }, [unblock]);

  const onToggle = useCallback(() => {
    isOpened ? close() : open();
  }, [close, isOpened, open]);

  useEffect(() => {
    if (breakpoint !== "base") {
      close();
    }
  }, [breakpoint, close]);

  return (
    <>
      <CloseOnNavigation onClose={close} />

      <Box
        ref={navbarRef}
        display={{ base: "block", md: "none" }}
        position="sticky"
        left="0"
        right="0"
        bottom="0"
        zIndex={1}
        backgroundColor="#fff"
        borderTopWidth="1px"
        padding=".5rem">
        <HStack as="nav" justify="space-between" spacing="0">
          <MenuHighlighted items={HIGHLIGHTED_MENU_ITEMS} />

          <MenuButton
            onClick={onToggle}
            isOpened={isOpened}
            flexGrow={1}
            minW={HIGHLITHED_MENU_ITEMS_MIN_WIDTH}
          />
        </HStack>
      </Box>

      {isOpened && (
        <Box // this duplicated navbar is used to keep it being displayed when the menu is opened
          display={{ base: "block", md: "none" }}
          position="fixed"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="#fff"
          borderTopWidth="1px"
          padding=".5rem">
          <HStack spacing="0">
            <MenuHighlighted items={HIGHLIGHTED_MENU_ITEMS} />

            <MenuButton
              onClick={onToggle}
              isOpened={isOpened}
              flexGrow={1}
              minW={HIGHLITHED_MENU_ITEMS_MIN_WIDTH}
            />
          </HStack>
        </Box>
      )}

      <Box
        visibility={isOpened ? "visible" : "hidden"}
        position="fixed"
        zIndex={Z_INDEX.bottomNavBar}
        top="0"
        left="0"
        right="0"
        bottom={`${navBarHeight}px`}
        overflowY="scroll"
        backgroundColor="#fff"
        opacity={isOpened ? "1" : "0"}
        pointerEvents={isOpened ? "auto" : "none"}
        transition={
          isOpened
            ? "opacity .25s ease"
            : "all .25s ease, visibility .25s .25s ease"
        }>
        <MenuStandard />
      </Box>
    </>
  );
}

export default BottomNavBar;
