import { Box, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { Href } from "../../../../../components/Href";
import {
  getMenuItemAdapterType,
  getMenuItemAdapterValues,
} from "../../../../../helpers/menus/getMenuItemAdapterValues";

/**
 * @param {object} params
 * @param {string | null} params.role
 * @returns {import("@chakra-ui/react").BoxProps}
 */
function getMenuItemProps({ role }) {
  /** @type {import("@chakra-ui/react").BoxProps} */
  const defaultProps = {
    py: "0.625rem",
    px: "0.5rem",
    color: "#fff",
    _hover: {
      textDecoration: "underline",
    },
  };
  switch (role) {
    default: {
      return defaultProps;
    }
  }
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationMenuItem} menuItem
 */
/**
 * @param {Props} props
 */
export function MenuItem({ menuItem }) {
  const translate = useTranslate();

  if (getMenuItemAdapterType({ menuItem }) === undefined) {
    return null;
  }

  const { title, href, target, role } = getMenuItemAdapterValues({
    menuItem,
    translate,
  });

  return (
    <>
      {href !== undefined ? (
        <Href href={href}>
          <Box
            whiteSpace="nowrap"
            target={target}
            {...getMenuItemProps({ role })}>
            <Text variant="text-sm">{title}</Text>
          </Box>
        </Href>
      ) : (
        <Box whiteSpace="nowrap" {...getMenuItemProps({ role })}>
          <Text variant="text-sm">{title}</Text>
        </Box>
      )}

      {menuItem.children.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </>
  );
}
