import { Box, Button, Flex, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { Href } from "../../../../../components/Href";
import { Icon } from "@raiden/library-ui/components/Icon";
import { Wrapper } from "../Wrapper";
import { useCookies } from "../../../../../hooks/useCookies";
import { COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT } from "@raiden/library-ui/constants/cookies";
import cookie from "cookie";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

export function WarningContactsNotVerified() {
  const intl = useIntl();

  const { setCookies } = useCookies();

  const handleClose = () => {
    setCookies((currentValue) => ({
      ...currentValue,
      [COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT]: "1",
    }));
    document.cookie = cookie.serialize(
      COOKIES_NAME_VALUE_CLOSE_CONTACTS_NOT_VERIFIED_ALERT,
      "1",
      {
        path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
        secure: true,
      },
    );
  };

  return (
    <Wrapper bgColor="blue.100">
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "1rem", md: "0" }}
        py=".75rem"
        alignItems="center">
        <Box display={{ base: "none", md: "block" }}>
          <Icon icon="ms_info" color="brandPrimary.500" size="24px" />
        </Box>

        <Flex flexDir="column" ml=".75rem" gap="0">
          <Text fontWeight="600" color="gray.700">
            {intl.formatMessage({
              defaultMessage: "Veuillez valider vos informations de contact",
            })}
          </Text>

          <Text color="gray.700">
            {intl.formatMessage({
              defaultMessage:
                "Votre e-mail et numéro de téléphone nous permettent de vous transmettre des informations importantes pour la gestion de votre compte.",
            })}
          </Text>
        </Flex>

        <Flex ml={{ base: "0", md: "auto" }} gap="1rem">
          <Href
            href={generateFrontPath({
              id: "@front.account.my-account.contact-coordinates",
              fragment: "validateContacts",
            })}>
            <Button
              backgroundColor="brandPrimary.500"
              color="white"
              _hover={{
                background: "brandPrimary.600",
              }}>
              {intl.formatMessage({
                defaultMessage: "Valider mes informations",
              })}
            </Button>
          </Href>

          <Box cursor="pointer">
            <Button variant="unstyled" onClick={handleClose}>
              <Icon icon="ms_close" color="gray.700" size="24px" />
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  );
}
