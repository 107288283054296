import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { useCallback } from "react";

/**
 * @param {import(".").AutocompleteRenderTagParams} props
 */
export function DefaultTag({
  item,
  itemValue,
  resolveItemName,
  isHydrationDone,
  removeValue,
  isMultiple,
}) {
  const handleClickRemove = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      removeValue({ value: itemValue });
    },
    [itemValue, removeValue],
  );

  return (
    <Tag
      flexGrow={isMultiple ? 0 : 1}
      flexShrink={0}
      overflow="hidden"
      minW={isHydrationDone ? undefined : "75px"}>
      <TagLabel textOverflow="ellipsis" flexGrow={1}>
        {(item && resolveItemName({ item })) ?? (isHydrationDone && itemValue)}
      </TagLabel>

      {isMultiple && <TagCloseButton onClick={handleClickRemove} />}
    </Tag>
  );
}
