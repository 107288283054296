import { defineMessage } from "react-intl";

export const MATERIAL_SYMBOLS_CODEPOINTS_URL =
  "https://raw.githubusercontent.com/google/material-design-icons/master/variablefont/MaterialSymbolsOutlined%5BFILL%2CGRAD%2Copsz%2Cwght%5D.codepoints";

/** @typedef {"materialSymbols" | "internal"} IconTypeValue */

/**
 * @typedef {{name: import("react-intl").MessageDescriptor, type: IconTypeValue, prefix: string}} IconType
 */

/** @type {IconType[]} */
export const ICON_TYPES_LIST = [
  {
    name: defineMessage({ defaultMessage: "Material Symbols" }),
    type: "materialSymbols",
    prefix: "ms_",
  },
  {
    name: defineMessage({ defaultMessage: "Icônes internes" }),
    type: "internal",
    prefix: "sf_",
  },
];

export const ICON_TYPES_MAP = new Map(
  ICON_TYPES_LIST.map((item) => [item.type, item]),
);

export const ICON_TYPES_PREFIX_MAP = new Map(
  ICON_TYPES_LIST.map((item) => [item.prefix, item]),
);

/**
 * @typedef {{icon: string, label: import("react-intl").MessageDescriptor}} InternalIcon
 */

/** @type {InternalIcon[]} */
export const INTERNAL_ICONS_LIST = [
  {
    icon: "sf_adult",
    label: defineMessage({ defaultMessage: "Adulte" }),
  },
  {
    icon: "sf_amenity_animaux_acceptes",
    label: defineMessage({ defaultMessage: "Animaux acceptés" }),
  },
  {
    icon: "sf_amenity_a_cheval",
    label: defineMessage({ defaultMessage: "À cheval" }),
  },
  {
    icon: "sf_amenity_a_moto",
    label: defineMessage({ defaultMessage: "À moto" }),
  },
  {
    icon: "sf_amenity_a_velo",
    label: defineMessage({ defaultMessage: "À vélo" }),
  },
  {
    icon: "sf_amenity_au_pied_des_pistes",
    label: defineMessage({ defaultMessage: "Au pied des pistes" }),
  },
  {
    icon: "sf_amenity_bienvenue_a_la_ferme",
    label: defineMessage({ defaultMessage: "Bienvenue à la ferme" }),
  },
  {
    icon: "sf_amenity_cle_verte",
    label: defineMessage({ defaultMessage: "Clé verte" }),
  },
  {
    icon: "sf_amenity_famille_et_amis",
    label: defineMessage({ defaultMessage: "Famille et amis" }),
  },
  {
    icon: "sf_amenity_handicap_auditif",
    label: defineMessage({ defaultMessage: "Handicap auditif" }),
  },
  {
    icon: "sf_amenity_handicap_mental",
    label: defineMessage({ defaultMessage: "Handicap mental" }),
  },
  {
    icon: "sf_amenity_handicap_moteur",
    label: defineMessage({ defaultMessage: "Handicap moteur" }),
  },
  {
    icon: "sf_amenity_handicap_visuel",
    label: defineMessage({ defaultMessage: "Handicap visuel" }),
  },
  {
    icon: "sf_amenity_hebergement_peche",
    label: defineMessage({ defaultMessage: "Hébergement pêche" }),
  },
  {
    icon: "sf_amenity_initiativ",
    label: defineMessage({ defaultMessage: "Initiativ" }),
  },
  {
    icon: "sf_amenity_itinerance",
    label: defineMessage({ defaultMessage: "Itinérance" }),
  },
  {
    icon: "sf_amenity_nature",
    label: defineMessage({ defaultMessage: "Nature" }),
  },
  {
    icon: "sf_amenity_pieds_dans_l_eau",
    label: defineMessage({ defaultMessage: "Pieds dans l'eau" }),
  },
  {
    icon: "sf_amenity_romantique",
    label: defineMessage({ defaultMessage: "Romantique" }),
  },
  {
    icon: "sf_amenity_sejour_oenologique",
    label: defineMessage({ defaultMessage: "Séjour oenologique" }),
  },
  {
    icon: "sf_amenity_sejour_pro",
    label: defineMessage({ defaultMessage: "Séjour professionnel" }),
  },
  {
    icon: "sf_amenity_spa_et_bien_être",
    label: defineMessage({ defaultMessage: "Spa et bien être" }),
  },
  {
    icon: "sf_amenity_thermalisme",
    label: defineMessage({ defaultMessage: "Thermalisme" }),
  },
  {
    icon: "sf_amenity_vacances_ecologiques",
    label: defineMessage({ defaultMessage: "Vacances écologiques" }),
  },
  {
    icon: "sf_amenity_vue_panoramique",
    label: defineMessage({ defaultMessage: "Vue panoramique" }),
  },
  {
    icon: "sf_amenity_a_la_ferme",
    label: defineMessage({ defaultMessage: "À la ferme" }),
  },
  {
    icon: "sf_amenity_destination_peche",
    label: defineMessage({ defaultMessage: "Destination pêche" }),
  },
  {
    icon: "sf_amenity_kit_couture",
    label: defineMessage({ defaultMessage: "Kit de couture" }),
  },
  {
    icon: "sf_amenity_linge_bain",
    label: defineMessage({ defaultMessage: "Linge de bain" }),
  },
  {
    icon: "sf_amenity_monter_a_cheval",
    label: defineMessage({ defaultMessage: "Monter à cheval" }),
  },
  {
    icon: "sf_amenity_mur_escalade",
    label: defineMessage({ defaultMessage: "Mur d'escalade" }),
  },
  {
    icon: "sf_amenity_papier_toilette",
    label: defineMessage({ defaultMessage: "Papier toilette" }),
  },
  {
    icon: "sf_amenity_presse_agrumes",
    label: defineMessage({ defaultMessage: "Presse-agrumes" }),
  },
  {
    icon: "sf_amenity_seche_cheveux",
    label: defineMessage({ defaultMessage: "Sèche-cheveux" }),
  },
  {
    icon: "sf_amenity_telex",
    label: defineMessage({ defaultMessage: "Télex" }),
  },
  {
    icon: "sf_baby",
    label: defineMessage({ defaultMessage: "Bébé" }),
  },
  {
    icon: "sf_child",
    label: defineMessage({ defaultMessage: "Enfant" }),
  },
  {
    icon: "sf_facebook",
    label: defineMessage({ defaultMessage: "Facebook" }),
  },
  {
    icon: "sf_key",
    label: defineMessage({ defaultMessage: "Clé" }),
  },
  {
    icon: "sf_pet",
    label: defineMessage({ defaultMessage: "Animal" }),
  },
  {
    icon: "sf_search",
    label: defineMessage({ defaultMessage: "Rechercher" }),
  },
  {
    icon: "sf_whatsapp",
    label: defineMessage({ defaultMessage: "Whatsapp" }),
  },
];

export const INTERNAL_ICONS_MAP = new Map(
  INTERNAL_ICONS_LIST.map((item) => [item.icon, item]),
);
