import { Box, HStack, Text } from "@chakra-ui/react";
import { LightMode } from "@chakra-ui/system";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { AnimatePresence, motion } from "framer-motion";
import NextLink from "next/link";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import MenuItemCore from "../../../../../components/Menu/MenuItemCore";

const ANIMATION = {
  visible: {
    opacity: 1,
    height: "auto",
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};

/**
 * @typedef {object} Props
 * @property {import("../../../../../components/Menu/MenuItemCore").MenuItemCore} item
 * @property {boolean} isActive
 * @property {boolean} isActiveDeep
 * @property {boolean} isOpened
 * @property {() => void} handleClickChevron
 * @property {string[]} openedMenuItemsIds
 * @property {(item: import("../../../../../components/Menu/MenuItemCore").MenuItemCore) => void} openMenuItem
 * @property {(item: import("../../../../../components/Menu/MenuItemCore").MenuItemCore) => void} closeMenuItem
 */
/**
 * @param {Props} props
 */
function MenuItemStandard({
  item,
  isActive,
  isActiveDeep,
  isOpened,
  handleClickChevron,
  openedMenuItemsIds,
  openMenuItem,
  closeMenuItem,
}) {
  const intl = useIntl();

  const [isHovered, setIsHovered] = useState(false);

  const itemDescription = useMemo(() => {
    if (item.description !== undefined) {
      return intl.formatMessage(item.description);
    }
    if ((item.children ?? [])?.length > 0) {
      return item.children
        ?.map((itemChild) => intl.formatMessage(itemChild.title))
        .join(", ");
    }
    return null;
  }, [intl, item.children, item.description]);

  return (
    <LightMode>
      <Box
        onMouseEnter={useCallback(() => {
          setIsHovered(true);
        }, [])}
        onMouseLeave={useCallback(() => {
          setIsHovered(false);
        }, [])}
        role="group">
        <HStack flexShrink={0} spacing="0" position="relative" role="menuitem">
          <Box
            display={isActive ? "block" : "none"}
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            w="4px"
            backgroundColor="brandPrimary.600"
            borderRightRadius="full"
            pointerEvents="none"
          />

          <ConditionalWrapper
            hasWrapper={typeof item.href === "string"}
            wrapper={(children) => (
              <NextLink href={item.href ?? ""} passHref>
                {children}
              </NextLink>
            )}>
            <Box
              flexGrow={1}
              as={item.href ? "a" : "button"}
              // @ts-ignore
              target={item.target}
              onClick={
                item.onClick ??
                ((item.children ?? []).length > 0 && !item.href
                  ? handleClickChevron
                  : undefined)
              }
              pl={`${40 * (item.depth ?? 0)}px`}
              aria-expanded={
                (item.children ?? []).length > 0 ? isOpened : null
              }>
              <Box pl="1rem" pr="1rem" py="0.5rem">
                <HStack spacing="16px" width="full">
                  {item.icon && (
                    <Icon
                      icon={item.icon}
                      color={
                        isHovered
                          ? "brandPrimary.500"
                          : isActiveDeep
                          ? "brandPrimary.600"
                          : undefined
                      }
                      size="24px"
                    />
                  )}

                  <Box>
                    <Text
                      textAlign="left"
                      colorScheme={isActiveDeep ? "brandPrimary" : undefined}
                      _groupHover={{
                        color: "brandPrimary.500",
                      }}>
                      {intl.formatMessage(item.title)}
                    </Text>

                    {itemDescription && (
                      <Text
                        textAlign="left"
                        fontSize="0.75rem"
                        color="gray.700">
                        {itemDescription}
                      </Text>
                    )}
                  </Box>
                </HStack>
              </Box>
            </Box>
          </ConditionalWrapper>

          {(item.children ?? []).length > 0 && (
            <Box
              as="button"
              onClick={handleClickChevron}
              px="1rem"
              py=".5rem"
              transform={`rotate(${isOpened ? 180 : 0}deg)`}
              transition="transform 0.25s ease-in-out"
              aria-expanded={isOpened}
              _hover={{ color: "brandSecondary.500" }}>
              <Icon icon="ms_expand_more" size="24px" />
            </Box>
          )}
        </HStack>
      </Box>

      <AnimatePresence initial={false}>
        {(item.children ?? []).length > 0 && isOpened && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={ANIMATION}
            style={{ flexShrink: 0, overflow: "hidden" }}>
            <Box backgroundColor="blackAlpha.50">
              {item.children?.map?.((item) => (
                <MenuItemCore
                  item={item}
                  openedMenuItemsIds={openedMenuItemsIds}
                  openMenuItem={openMenuItem}
                  closeMenuItem={closeMenuItem}
                  render={(params) => <MenuItemStandard {...params} />}
                  key={item.id}
                />
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </LightMode>
  );
}

export default MenuItemStandard;
