import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { MdSearch } from "react-icons/md";
import { useIntl } from "react-intl";
import useCombinedRefs from "../../../hooks/useCombinedRefs";

/**
 * @typedef {object} Props
 * @property {import("react").MutableRefObject<any>} inputRef
 * @property {import("react").MutableRefObject<any[]>} optionsRef
 * @property {() => void} toggleFirstOption
 * @property {boolean} isValidating
 * @property {object} submittedFields
 */
/**
 * @param {Props} props
 */
function SearchInput({
  inputRef: _inputRef,
  optionsRef,
  toggleFirstOption,
  isValidating,
  submittedFields,
}) {
  const intl = useIntl();

  const { field } = useController({ name: "term" });

  const inputRef = useCombinedRefs(field.ref, _inputRef);

  const { getValues, setValue } = useFormContext();

  return (
    <InputGroup>
      <InputLeftElement>
        <Icon as={MdSearch} />
      </InputLeftElement>

      <Input
        {...field}
        ref={inputRef}
        placeholder={intl.formatMessage({
          defaultMessage: "Rechercher...",
        })}
        flexGrow={1}
        autoComplete="off"
        variant="flushed"
        pr="1rem"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (getValues("term") === submittedFields.term && !isValidating) {
              toggleFirstOption();
            }
            event.preventDefault();
          }
          if (event.key === "Escape") {
            if (getValues("term") !== "") {
              setValue("term", "", { shouldValidate: true });
              event.preventDefault();
              event.stopPropagation();
            }
          }
          if (event.key === "ArrowDown") {
            event.preventDefault();
            optionsRef?.current?.[0]?.focus?.();
          }
        }}
      />
    </InputGroup>
  );
}

export default SearchInput;
