import { useCallback } from "react";

let scrollY = 0;

export function useScrollBlocker() {
  const block = useCallback(() => {
    const root = document.documentElement;
    const newScrollY = root.scrollTop;
    scrollY = newScrollY;
    root.style.width = "100%";
    root.style.position = "fixed";
    root.style.overflowY = "scroll";
    root.style.top = `${(-newScrollY).toString()}px`;
  }, []);

  const unblock = useCallback(() => {
    const root = document.documentElement;
    // only unblock the body if it is currently blocked
    if (root.style.position === "fixed") {
      root.style.removeProperty("width");
      root.style.removeProperty("position");
      root.style.removeProperty("overflow-y");
      root.style.removeProperty("top");
      root.style.scrollBehavior = "initial";
      window.scrollBy(0, scrollY);

      // let shouldScrollToBottom = false;
      // if (root.scrollHeight - root.scrollTop === root.clientHeight) {
      //   shouldScrollToBottom = true;
      // }
      requestAnimationFrame(() => {
        root.style.removeProperty("scroll-behavior");
        // if (shouldScrollToBottom) {
        //   window.scrollTo(0, root.scrollHeight);
        // }
      });
    }
  }, []);

  return {
    block,
    unblock,
  };
}
