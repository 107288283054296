export const CUSTOMER_CONTACT_COORDINATES_FORM_FIELDS = [
  "contact_email",
  "contact_phone",
  "display_phone",
];

export const CUSTOMER_PERSONAL_INFORMATION_FORM_FIELDS = [
  "first_name",
  "last_name",
  "gender",
  "address_1",
  "zipcode",
  "city",
  "country_id",
];

export const CUSTOMER_PUBLIC_PROFILE_FORM_FIELDS = [
  "public_name",
  "public_infos",
  "languages",
  "avatar",
];

export const CUSTOMER_LOGIN_CREDENTIALS_FORM_FIELDS = ["email"];

export const CUSTOMER_ALL_FORM_FIELDS = [
  ...CUSTOMER_CONTACT_COORDINATES_FORM_FIELDS,
  ...CUSTOMER_PERSONAL_INFORMATION_FORM_FIELDS,
  ...CUSTOMER_LOGIN_CREDENTIALS_FORM_FIELDS,
  ...CUSTOMER_PUBLIC_PROFILE_FORM_FIELDS,
];
