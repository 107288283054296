import { Button, Center, Image, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

export function Splash500Public() {
  const router = useRouter();

  return (
    <Stack spacing="1.5rem" textAlign="center">
      <Image
        src={generateFrontPath({
          id: "@front.internal-assets",
          parameters: { filePath: "illustrations/error-public.svg" },
          includeBasePath: true,
        })}
        w="320px"
        maxWidth="100%"
        margin="auto"
        alt=""
      />

      <Text variant="h3">
        <FormattedMessage defaultMessage="Nous sommes désolés, nous rencontrons un problème de connexion" />
      </Text>

      <Text variant="h5">
        <FormattedMessage defaultMessage="Veuillez recharger la page ou réessayer plus tard" />
      </Text>

      <Center>
        <Button
          onClick={useCallback(() => {
            router.reload();
          }, [router])}
          colorScheme="brandSecondary">
          <Text as="span" color="inherit" variant="button-md">
            <FormattedMessage defaultMessage="Recharger la page" />
          </Text>
        </Button>
      </Center>
    </Stack>
  );
}
