import {
  Input,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { forwardRef, memo, useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { apiGetErrorDetail } from "../../../helpers/api";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { InputAddressBody } from "./Body";

/**
 * @callback InputAddressOnAutoFillCallback
 * @param {InputAddressOnAutoFillCallbackParams} params
 */
/**
 * @typedef {object} InputAddressOnAutoFillCallbackParams
 * @property {string | null} address
 * @property {number | null} zipcode
 * @property {string | null} city
 * @property {number | null} longitude
 * @property {number | null} latitude
 */

export const INPUT_AUTO_FILL_MIN_CHARACTERS = 3;

export const InputAddress = memo(
  forwardRef(
    /**
     * @typedef {object} Props
     * @property {InputAddressOnAutoFillCallback} onAutoFill
     * @property {string} placeholder
     */
    /**
     * @param {Props & import("../../ReactHookForm/FormControlRHF").FieldProps<string>} props
     */
    function InputAddress(
      { onAutoFill, value, onChange, onBlur, ...otherProps },
      ref,
    ) {
      const popoverContentRef = useRef(
        /** @type {HTMLElement | null} */ (null),
      );

      const { isOpen, onOpen, onClose } = useDisclosure();

      const inputRef = useRef(/** @type {HTMLInputElement | null} */ (null));
      const mergedRef = useMergeRefs(inputRef, ref);

      const debouncedTerm = useDebounce(value, { delay: 250, mount: true });

      const intl = useIntl();

      const { request, toastError } = useRequest();

      // #region handleKeyDown
      const handleBlur = useCallback(
        (e) => {
          // if the new element focus is inside the popover, don't close it
          if (popoverContentRef.current?.contains(e.relatedTarget)) {
            return;
          } else {
            onClose();
            onBlur?.();
          }
        },
        [onBlur, onClose],
      );

      const refOptions = useRef(/** @type {HTMLElement[]} */ ([]));

      // #region handleKeyDown
      const handleKeyDown = useCallback(
        /** @type {import("react").KeyboardEventHandler<HTMLInputElement>} */
        (e) => {
          if (e.key === "Escape") {
            onClose();
            return;
          }
          if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault();
            e.stopPropagation();
            if (refOptions.current.length > 0) {
              refOptions.current[0].focus();
            }
            return;
          }
        },
        [onClose],
      );

      // #region handleClickPlace
      const handleClickPlace = useCallback(
        /** @type {import("./Place").InputAutoFillOnClickCallback} */
        ({ placeAutocomplete }) => {
          onClose();
          request(
            generateApiUrl({
              id: "@places.show",
              parameters: { placeId: placeAutocomplete.id },
            }),
          )
            .then((response) => {
              /** @type {import("../../../types/Place").Place} */
              const place = response.data;

              const address =
                place.address !== null
                  ? [place.street_number, place.address]
                      .filter((value) => value ?? null !== null)
                      .join(" ")
                  : null;
              onAutoFill({
                address,
                city: place.city ?? null,
                zipcode: place.zipcode ?? null,
                longitude: place.longitude ?? null,
                latitude: place.latitude ?? null,
              });
            })
            .catch((error) => {
              const message = apiGetErrorDetail({ error });
              toastError({
                title: intl.formatMessage({
                  defaultMessage: "Une erreur est survenue",
                }),
                description: message,
              });
            });
        },
        [intl, onAutoFill, onClose, request, toastError],
      );

      // #region handleChange
      const handleChange = useCallback(
        /** @type {import("react").ChangeEventHandler<HTMLInputElement>} */
        (e) => {
          onChange(e);
          if (e.target.value.length >= INPUT_AUTO_FILL_MIN_CHARACTERS) {
            onOpen();
          } else {
            onClose();
          }
        },
        [onChange, onClose, onOpen],
      );

      const isValueLongEnough = value.length >= INPUT_AUTO_FILL_MIN_CHARACTERS;

      // #region handleClickOrFocus
      const handleClickOrFocus = useCallback(() => {
        if (isValueLongEnough) {
          onOpen();
        }
      }, [onOpen, isValueLongEnough]);

      // #region return
      return (
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          autoFocus={false}
          placement="bottom-start"
          isLazy={true}
          matchWidth={true}
          lazyBehavior="unmount">
          <PopoverAnchor>
            <Input
              {...otherProps}
              autoComplete="off"
              value={value}
              onChange={handleChange}
              onClick={handleClickOrFocus}
              onFocus={handleClickOrFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              ref={mergedRef}
            />
          </PopoverAnchor>
          <PopoverContent ref={popoverContentRef} w="auto" borderRadius="10px">
            <PopoverBody w="full" overflow="hidden" p="0">
              <InputAddressBody
                term={debouncedTerm}
                onClickPlace={handleClickPlace}
                refOptions={refOptions}
                inputRef={inputRef}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    },
  ),
);
