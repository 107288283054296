import { Box } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import { CenteredContent } from "../../components/CenteredContent";
import { PageHeaderPublic } from "../../components/PageHeaderPublic";
import { PageLayoutPublic } from "../../components/PageLayoutPublic";
import { Splash404Public } from "../../components/Splash404Public";

function Error410Public() {
  const intl = useIntl();

  return (
    <PageLayoutPublic
      header={
        <PageHeaderPublic
          title={intl.formatMessage({
            defaultMessage: "La page demandée n'existe pas",
          })}
          breadCrumbItems={[
            {
              title: intl.formatMessage({ defaultMessage: "Accueil" }),
              url: generateFrontPath({ id: "@front.home" }),
            },
            {
              title: intl.formatMessage({ defaultMessage: "Erreur 410" }),
            },
          ]}
        />
      }>
      <Box px="1rem" pb="6.25rem">
        <CenteredContent>
          <Splash404Public />
        </CenteredContent>
      </Box>
    </PageLayoutPublic>
  );
}

export default Error410Public;
