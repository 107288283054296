import { useCallback } from "react";
import { TextWithHighlight } from "../../TextWithHighlight";

const HIGHLIGHT_STYLE = {
  backgroundColor: "brandPrimary.600",
  color: "#fff",
};

/**
 * @callback InputAutoFillOnClickCallback
 * @param {{ placeAutocomplete: import("../../../types/PlaceAutocomplete").PlaceAutocomplete }} params
 * @returns {void}
 */

/**
 * @typedef {object} Props
 * @property {string} id
 * @property {import("../../../types/PlaceAutocomplete").PlaceAutocomplete} placeAutocomplete
 * @property {string} term
 * @property {InputAutoFillOnClickCallback} onClick
 * @property {import("react").MutableRefObject<HTMLElement[]>} refOptions
 * @property {number} index
 * @property {import("react").MutableRefObject<HTMLInputElement | null>} inputRef
 */
/**
 * @param {Props} props
 */
export function InputAddressPlace({
  id,
  placeAutocomplete,
  term,
  onClick,
  refOptions,
  index,
  inputRef,
}) {
  const handleClick = useCallback(() => {
    onClick({ placeAutocomplete });
  }, [onClick, placeAutocomplete]);

  const handleKeyDown = useCallback(
    /** @type {import("react").KeyboardEventHandler<HTMLInputElement>} */
    (e) => {
      if (e.key === "Enter" || e.key === " ") {
        handleClick();
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (e.key === "Escape") {
        if (inputRef.current) {
          inputRef.current?.focus();
        }
        return;
      }
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === "ArrowUp" && index === 0) {
          if (inputRef.current) {
            inputRef.current?.focus();
          }
          return;
        }
        const nextIndex =
          e.key === "ArrowDown"
            ? Math.min(index + 1, refOptions.current.length - 1)
            : Math.max(index - 1, 0);
        refOptions.current[nextIndex].focus();
      }
    },
    [handleClick, index, refOptions, inputRef],
  );

  return (
    <TextWithHighlight
      ref={(el) => (refOptions.current[index] = el)}
      id={id}
      tabIndex={0}
      text={placeAutocomplete.name}
      keywords={term}
      onClick={handleClick}
      py="2px"
      px="16px"
      borderRadius="10px"
      cursor="pointer"
      onKeyDown={handleKeyDown}
      _hover={HIGHLIGHT_STYLE}
      key={placeAutocomplete.id}
    />
  );
}
