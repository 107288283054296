export const ALERT_THEME = {
  variants: {
    subtle: {
      container: {
        borderRadius: "6px",
      },
    },
  },
  defaultProps: {},
};
