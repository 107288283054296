import { mode } from "@chakra-ui/theme-tools";

export const TEXT_THEME = {
  baseStyle(props) {
    const { colorScheme } = props;
    return {
      color: colorScheme
        ? mode(`${colorScheme}.600`, `${colorScheme}.300`)(props)
        : mode("gray.700", "#FFFFFF")(props),
    };
  },
  variants: {
    h1: {
      fontSize: { base: "1.875rem", xl: "2rem" },
      fontWeight: "300",
      lineHeight: "normal",
      letterSpacing: { base: "0.0375rem", xl: "0.045rem" },
    },
    h2: {
      fontSize: { base: "1.625rem", lg: "2rem" },
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: { base: "0.04rem", lg: "0.045rem" },
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "300",
      lineHeight: "normal",
      letterSpacing: "0.035rem",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.75rem",
      letterSpacing: "0.035rem",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "text-md": {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
    },
    "text-md-bold": {
      fontSize: "1rem",
      fontWeight: "500",
      lineHeight: "1.3125rem",
    },
    "text-sm": {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "text-xs": {
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
};
