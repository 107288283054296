/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const SELECT_THEME = {
  sizes: {
    xs: {
      field: {
        borderRadius: "0.625rem",
      },
    },
    sm: {
      field: {
        borderRadius: "0.625rem",
      },
    },
    md: {
      field: {
        borderRadius: "0.625rem",
      },
    },
    lg: {
      field: {
        borderRadius: "0.625rem",
      },
    },
  },
};
