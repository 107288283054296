import { Box, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import NextLink from "next/link";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { HIGHLITHED_MENU_ITEMS_MIN_WIDTH } from "../../../../../constants/menu";

/**
 * @typedef {object} Props
 * @property {import("../../../../../components/Menu/MenuItemCore").MenuItemCore} item
 * @property {boolean} isActive
 * @property {boolean} isActiveDeep
 */
/**
 * @param {Props} props
 */
function MenuItemHighlighted({ item, isActive, isActiveDeep }) {
  const intl = useIntl();

  const color = useColorModeValue("brandPrimary.600", "#fff");
  const highlightedColor = useColorModeValue("brandPrimary.600", "#fff");
  const backgroundColor = useColorModeValue("blackAlpha.100", "blackAlpha.400");

  return (
    <NextLink href={item.href ?? ""} passHref>
      <VStack
        as="a"
        flexGrow={1}
        flexBasis={0}
        target={item.target}
        justifyItems="stretch"
        color={isActive || isActiveDeep ? highlightedColor : color}
        spacing="0"
        overflow="hidden"
        role="group"
        minW={HIGHLITHED_MENU_ITEMS_MIN_WIDTH}>
        {item.icon && (
          <Box
            backgroundColor={
              isActive || isActiveDeep ? backgroundColor : "transparent"
            }
            borderRadius="6px"
            px="0.75rem"
            py="0.125rem">
            <Icon
              icon={item.icon}
              color={isActive || isActiveDeep ? highlightedColor : color}
              size="1.5rem"
            />
          </Box>
        )}

        <Text
          as="span"
          variant="text-xs"
          color="inherit"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxW="100%"
          _groupHover={{
            textDecoration: { lg: "underline" },
          }}>
          {intl.formatMessage(item.title)}
        </Text>
      </VStack>
    </NextLink>
  );
}

export default MenuItemHighlighted;
