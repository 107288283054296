/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {import("../../../types/Layouts").LayoutProps & Props} props
 */
export function BlankLayout({ children }) {
  return <>{children}</>;
}

export default BlankLayout;
