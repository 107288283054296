/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const BUTTON_THEME = {
  baseStyle: {
    position: "initial",
  },
  variants: {
    link: {
      color: "gray.700",
      textDecoration: "underline",
      fontWeight: "normal",
    },
  },
};
