// deps
import PropTypes from "prop-types";

// components
import ImagesTransform from "../../../components/Images/Transform";

// libraries
import { filesGetSrc } from "../../../helpers/files";

export default function FilesEditorImageTransform(props) {
  const { form, language, isDisabled } = props;

  const src = filesGetSrc({
    content: form.fields?.[language],
    applyTransforms: false,
  });

  return (
    <ImagesTransform
      image={src}
      form={form}
      transformationsName={`${language}[transformations]`}
      format={{}}
      isDisabled={isDisabled}
    />
  );
}

FilesEditorImageTransform.propTypes = {
  language: PropTypes.string,
  isDisabled: PropTypes.bool,
  form: PropTypes.object,
};
