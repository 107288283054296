import { createContext, useContext, useMemo } from "react";

/**
 * @typedef {object} LeadsCreateContextValue
 * @property {boolean} isEditor
 */

/** @type {LeadsCreateContextValue} */
const DEFAULT_VALUE = {
  isEditor: true,
};

const LeadsCreateContext = createContext(DEFAULT_VALUE);

/**
 * @typedef {object} Props
 * @property {boolean} isEditor determines if the form is editable or not
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export const LeadsCreateContextProvider = ({ isEditor, children }) => {
  const value = useMemo(() => {
    return {
      isEditor,
    };
  }, [isEditor]);

  return (
    <LeadsCreateContext.Provider value={value}>
      {children}
    </LeadsCreateContext.Provider>
  );
};

export const useLeadsCreateContext = () => {
  const value = useContext(LeadsCreateContext);

  if (value === DEFAULT_VALUE) {
    throw new Error(
      "useLeadsCreateContext must be used within a LeadsCreateContextProvider",
    );
  }

  return value;
};
