import { HStack, Text } from "@chakra-ui/react";
import { Fragment, useMemo } from "react";
import { Href } from "../Href";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { StructuredData } from "../Seo/StructuredData";

/**
 * @typedef {object} BreadCrumbItemPublic
 * @property {string} title
 * @property {string} [url]
 */
/**
 * @typedef {object} Props
 * @property {BreadCrumbItemPublic[]} [items]
 */
/**
 * @param {Props} props
 */
export function BreadcrumbsPublic({ items = [] }) {
  const structuredData = useMemo(() => {
    const root = generateFrontUrl({ id: "@front.home" });
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: item.title,
        item: root + item.url,
      })),
    };
  }, [items]);

  return items.length > 0 ? (
    <>
      {structuredData && <StructuredData data={structuredData} />}

      <HStack
        spacing="0.125rem"
        border="1px solid #eee"
        w="max-content"
        maxW="full"
        borderRadius="full"
        overflow="hidden">
        {items.map?.((item, index) => (
          <Fragment key={index}>
            <Href href={item.url}>
              <Text
                as="span"
                display="block"
                variant="text-xs"
                backgroundColor="#fff"
                borderLeftRadius={index === 0 ? "full" : undefined}
                borderRightRadius={
                  index === items.length - 1 ? "full" : undefined
                }
                py="0.25rem"
                px="0.5rem"
                cursor={item?.url ? "pointer" : undefined}
                color="gray.600"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                _hover={{
                  textDecoration: item?.url ? "underline" : undefined,
                }}>
                {item.title}
              </Text>
            </Href>
          </Fragment>
        ))}
      </HStack>
    </>
  ) : null;
}
