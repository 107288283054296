/* istanbul ignore file */

// deps
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  HStack,
  Image,
  StackDivider,
  Text,
  VStack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import {
  IoAlbumsOutline,
  IoCloudUploadOutline,
  IoGlobeOutline,
} from "react-icons/io5";

// components
import ContentBox from "../../../../components/ContentBox";
import ResourceLoading from "@splitfire-agency/raiden-library/dist/components/Resource/Loading";

// containers
import FilesEditorImageDragAndDropButton from "./Button";
import FilesEditorImageTransform from "../Transform";
import FilesEditorImageMetadata from "../Metadata";

// contexts
import { configurationGet } from "../../../../helpers/configuration";
import { useEnvironmentId } from "../EnvironmentIdContext";
import { useConfiguration } from "../../../../hooks/useConfiguration";

// hooks
import useApiFetcher from "../../../../hooks/useApiFetcher";
import useCanPerform from "../../../../hooks/useCanPerform";
import useLocale from "../../../../hooks/useLocale";

// normalizers
import { filesSerializeCreate } from "../../../../normalizers/files";

// libraries
import generateApiUri from "../../../../libraries/utils/generateApiUrl";

// helpers
import { filesGetSrc } from "../../../../helpers/files";
import { apiGetErrors } from "../../../../helpers/api";

// constants
import {
  RESOURCES_MEDIA_TYPES,
  RESOURCES_VISIBILITY_VALUE_PUBLIC,
} from "../../../../constants/resources";
import {
  FILES_CATEGORY_VALUE_IMAGE,
  FILES_TYPE_VALUE_FILE,
} from "../../../../constants/files";
import { AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_CREATE } from "../../../../constants/authorizations";

export default function FilesEditorImageDragAndDrop(props) {
  const { form, language } = props;

  const [dragOver, setDragOver] = useState(false);
  const [dragging, setDragging] = useState(false);

  const environmentId = useEnvironmentId();

  const [filesCreateResponse, setFilesCreateResponse] = useState(
    /** @type {{ loading: boolean, errors: any }} */ ({
      loading: false,
      errors: null,
    }),
  );

  const { locales } = useLocale();

  const intl = useIntl();

  const $input = useRef(/** @type {any} */ (null));

  const apiFetcher = useApiFetcher();

  const canPerform = useCanPerform();

  /**
   * Gère l’envoi de l’image dans la GED.
   * @param {File} file
   */
  async function uploadFile(file) {
    setFilesCreateResponse({
      loading: true,
      errors: null,
    });

    apiFetcher(
      generateApiUri({
        id: "@files.create",
      }),
      {
        body: filesSerializeCreate({
          fields: {
            data: {
              type: FILES_TYPE_VALUE_FILE,
              visibility: RESOURCES_VISIBILITY_VALUE_PUBLIC,
              environment_id: environmentId,
            },
            file,
          },
        }),
        method: "POST",
      },
      {
        inputMode: "formData",
      },
    )
      .then(function (data) {
        setFilesCreateResponse({ loading: false, errors: null });

        const newValues = [];

        locales.forEach(function (locale) {
          if (
            // Si aucune image n’est présente pour la langue courante, on l’hydrate ou que la langue d’affichage est la langue courante.
            !form.getValue(`${locale}[src]` || locale === language)
          ) {
            newValues.push(
              ...[
                { name: `${locale}[src]`, value: data?.data?.pampa_uri },
                { name: `${locale}[type]`, value: "files" },
                { name: `${locale}[transformations]`, value: undefined },
              ],
            );
          }
        });

        form.setBulkValue(newValues);
      })
      .catch(function (error) {
        setFilesCreateResponse({
          loading: false,
          errors: apiGetErrors({
            error,
            detailFallback: intl.formatMessage({
              id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.fileCreateError",
              defaultMessage: "Impossible de téléverser l’image.",
            }),
          }),
        });
      });
  }

  /**
   * Gère le changement de fichier à l’aide de l’explorateur natif.
   * @type {import("react").ChangeEventHandler<HTMLInputElement>}
   */
  function handleChangeInput(event) {
    if (event.currentTarget.files === null) {
      return;
    }
    const files = Array.from(event.currentTarget.files);

    const file = files[0];

    uploadFile(file);
  }

  /**
   * Gère le clic sur le bouton de téléchargement de l’image depuis l’ordinateur.
   */
  function handleClickFromComputer() {
    $input.current?.click();
  }

  /**
   * Gère le clic sur le bouton de récupération de l’image depuis le module Files.
   */
  function handleClickFromFiles() {}

  /**
   * Gère le clic sur le bouton de récupération de l’image depuis un lien.
   */
  function handleClickFromUrl() {
    form.setBulkValue([
      { name: `${language}[type]`, value: "external" },
      { name: `${language}[transformations]`, value: undefined },
    ]);
  }

  /**
   * @type {import("react").DragEventHandler<HTMLDivElement>}
   */
  async function handleDrop(event) {
    setDragOver(false);

    if (
      /* Si le drag'n'drop est un fichier. */
      event.dataTransfer.types.includes("Files")
    ) {
      const files = Array.from(event.dataTransfer.files);
      const file = files[0];

      uploadFile(file);
    } else if (
      /* Si le drag'n'drop est un lien */
      event.dataTransfer.types.includes("text/plain")
    ) {
      const url = await new Promise(function (resolve) {
        // @ts-ignore
        Array.from(event.dataTransfer.items)
          .find(function (item) {
            return item.type === "text/plain";
          })
          .getAsString(resolve);
      });

      form.setBulkValue([
        { name: `${language}[src]`, value: url },
        { name: `${language}[type]`, value: "external" },
        { name: `${language}[transformations]`, value: undefined },
      ]);
    }
  }

  function handleReset() {
    form.setBulkValue([
      { name: `${language}[src]`, value: undefined },
      { name: `${language}[type]`, value: undefined },
      { name: `${language}[transformations]`, value: undefined },
    ]);
  }

  useEffect(function () {
    let counter = 0;

    function handleDragEnter() {
      counter += 1;
      setDragging(true);
    }

    function handleDragLeave() {
      counter -= 1;

      if (counter === 0) {
        setDragging(false);
      }
    }

    function handleDrop() {
      counter = 0;
      setDragging(false);
    }

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop);

    return function () {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);

  const { configuration } = useConfiguration();

  const mime_types =
    configurationGet({
      configuration,
      key: (c) =>
        c?.files?.categories?.[FILES_CATEGORY_VALUE_IMAGE]?.mime_types,
      environmentId,
    }) ?? [];

  const strMimeTypes = mime_types
    .map(function (mimeType) {
      return RESOURCES_MEDIA_TYPES?.[mimeType]?.friendlyName ?? mimeType;
    })
    .join(", ");

  const src = form.getValue(`${language}[src]`);
  const type = form.getValue(`${language}[type]`);

  const { errors } = filesCreateResponse;

  return (
    <>
      {errors?._detail && (
        <Box mt="1rem">
          <Alert status="error">
            <AlertIcon />

            {errors._detail}
          </Alert>
        </Box>
      )}

      <input
        ref={$input}
        type="file"
        style={{ width: 0, height: 0, visibility: "hidden" }}
        onChange={handleChangeInput}
      />

      <VStack alignItems="stretch" w="full">
        <Text color="gray.400" fontSize="0.875rem" mb="1rem">
          {intl.formatMessage(
            {
              id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.cover.constraints",
              defaultMessage: "Formats supportés : {mimeTypes}.",
            },
            {
              mimeTypes: strMimeTypes,
            },
          )}
        </Text>

        <Box
          w="full"
          borderWidth="0.125rem"
          borderStyle="dashed"
          borderColor={"gray.500"}
          transition="background-color 160ms linear 0ms"
          bgColor={dragOver ? "orange.100" : undefined}
          p="1rem"
          onDragEnter={function () {
            setDragOver(true);
          }}
          onDragLeave={function () {
            setDragOver(false);
          }}
          onDrop={handleDrop}>
          {!src ? (
            <HStack
              pointerEvents={dragging ? "none" : "all"}
              spacing="1rem"
              divider={<StackDivider borderColor="gray.200" />}
              align="stretch">
              <FilesEditorImageDragAndDropButton
                label={intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.actions.fromComputer.label",
                  defaultMessage: "À partir de mon ordinateur",
                })}
                icon={<IoCloudUploadOutline />}
                isDisabled={
                  !canPerform({
                    authorizations:
                      AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_CREATE,
                  })
                }
                onClick={handleClickFromComputer}
              />

              <FilesEditorImageDragAndDropButton
                label={intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.actions.fromFiles.label",
                  defaultMessage: "À partir de la médiathèque",
                })}
                icon={<IoAlbumsOutline />}
                isDisabled={true}
                onClick={handleClickFromFiles}
              />

              <FilesEditorImageDragAndDropButton
                label={intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.actions.fromLink.label",
                  defaultMessage: "À partir d’un lien HTTP",
                })}
                icon={<IoGlobeOutline />}
                onClick={handleClickFromUrl}
              />
            </HStack>
          ) : (
            <VStack spacing="1rem">
              <Box d="flex" justifyContent="center">
                <Image
                  src={filesGetSrc({
                    content: form.fields?.[language],
                    applyTransforms: false,
                  })}
                  maxW="21.875rem"
                  maxH="12.3046875rem"
                  alt={intl.formatMessage({ defaultMessage: "Image" })}
                />
              </Box>

              <Text fontWeight="bold">
                {intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.add",
                  defaultMessage: "Ajouter une image",
                })}
              </Text>

              <Text color="gray.500">
                {intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.current",
                  defaultMessage: "Photo actuelle",
                })}
              </Text>

              <Button
                variant="outline"
                colorScheme="white"
                onClick={handleReset}>
                {intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.actions.replace",
                  defaultMessage: "Remplacer",
                })}
              </Button>
            </VStack>
          )}
        </Box>

        {filesCreateResponse.loading && (
          <Box pt="2rem">
            <ResourceLoading
              label={intl.formatMessage({
                id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.loading.message",
                defaultMessage: "Chargement de l’image en cours…",
              })}
            />
          </Box>
        )}
      </VStack>

      {((type === "files" && src) || type === "external") && (
        <VStack spacing="1rem" mt="2rem">
          {type === "files" && (
            <ContentBox
              w="full"
              p="1rem"
              title={
                <Text fontWeight="bold" fontSize="1.125rem" mb="1.5rem">
                  {intl.formatMessage({
                    id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.transform.title",
                    defaultMessage: "Format de l’image",
                  })}
                </Text>
              }>
              <FilesEditorImageTransform form={form} language={language} />
            </ContentBox>
          )}

          <ContentBox
            w="full"
            p="1rem"
            title={
              <Text fontWeight="bold" fontSize="1.125rem" mb="1.5rem">
                {intl.formatMessage({
                  id: "raiden.library.containers.Files.EditorImage.DragAndDrop.texts.metadata.title",
                  defaultMessage: "Métadonnées",
                })}
              </Text>
            }>
            <FilesEditorImageMetadata form={form} language={language} />
          </ContentBox>
        </VStack>
      )}
    </>
  );
}

FilesEditorImageDragAndDrop.propTypes = {
  language: PropTypes.string,
  form: PropTypes.object,
};
