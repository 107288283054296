import { Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

/**
 * @typedef {object} Props
 * @property {number} year
 */
/**
 * @param {Props} props
 */
export function Copyright({ year }) {
  return (
    <Text flexGrow={1} variant="text-xs">
      <FormattedMessage
        defaultMessage="© {year} Clévacances. Tous droits réservés."
        values={{ year }}
      />
    </Text>
  );
}
