import { Button } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { RendererContext } from "@splitfire-agency/raiden-le-editeur/dist/renderers";

const BlockButtonChakra = ({ block }) => {
  const { document, language } = useContext(RendererContext);

  const text =
    block.content.intl[language.key]?.text ??
    block.content.intl[document.language]?.text;

  const url =
    block.content.intl[language.key]?.url ??
    block.content.intl[document.language]?.url;

  const useCustomPaddings = useMemo(() => {
    return block.content.useCustomPaddings ?? true;
  }, [block.content.useCustomPaddings]);

  const height = useMemo(() => {
    return useCustomPaddings ? "auto" : undefined;
  }, [useCustomPaddings]);

  return (
    <Button
      as={url ? "a" : undefined}
      cursor="pointer"
      href={url ? url : undefined}
      width="100%"
      height={height}
      variant={block.content.variant}
      size={block.content.size}
      colorScheme={block.content.colorScheme}
      paddingTop={
        useCustomPaddings && block.content.paddingTop
          ? `${block.content.paddingTop}px`
          : undefined
      }
      paddingRight={
        useCustomPaddings && block.content.paddingRight
          ? `${block.content.paddingRight}px`
          : undefined
      }
      paddingBottom={
        useCustomPaddings && block.content.paddingBottom
          ? `${block.content.paddingBottom}px`
          : undefined
      }
      paddingLeft={
        useCustomPaddings && block.content.paddingLeft
          ? `${block.content.paddingLeft}px`
          : undefined
      }
      backgroundColor={block.content.backgroundColor}
      _hover={{ backgroundColor: block.content.backgroundColor }}
      borderRadius={
        block.content.borderRadius
          ? `${block.content.borderRadius}px`
          : undefined
      }
      borderWidth={
        block.content.borderWidth ? `${block.content.borderWidth}px` : undefined
      }
      borderColor={
        block.content.borderWidth ? block.content.borderColor : undefined
      }
      whiteSpace={block.content.whiteSpace ?? "normal"}
      color={block.content.color}>
      {text}
    </Button>
  );
};

BlockButtonChakra.displayName = "BlockButtonChakra";

export default BlockButtonChakra;
