import { createContext, useContext } from "react";

/**
 * @typedef {object} DataHandlerContextValue
 * @property {import("react").FunctionComponent} SplashNoResult
 * @property {import("react").FC<{ error: import("../../types/Api/ApiError").ApiError}>} SplashError
 * @property {import("react").ReactElement} loadingElement
 */

/**
 * @template [T=any]
 * @param {object} props
 * @param {import("swr").SWRResponse<T>} props.swrResponse the SWR response
 * @param {boolean} [props.keepDataOnRevalidation] if true, the children will be rendered even if the data is being revalidated
 * @param {boolean} [props.shouldRenderWithEmptyData] if true, the children will be rendered even if the data is empty
 * @param {import("react").ReactElement} [props.noResult] the element to render if the data is empty
 * @param {boolean} [props.renderNoResultSplash] if true, the no result splash will be rendered
 * @param {import("react").ReactElement | null} [props.loading] the element to render if the data is loading
 * @param {(params: {children: import("react").ReactElement}) => import("react").ReactElement | null} [props.wrapper] the wrapper that will be used to render any interal elements
 * @param {import("react").FunctionComponent<{ error: import("../../types/Api/ApiError").ApiError}>} [props.ErrorComponent] the element to render if there is an error
 * @param {(response: Exclude<T & {}, undefined>) => import("react").ReactNode} props.children
 */
export function DataHandler({
  swrResponse,
  keepDataOnRevalidation = false,
  shouldRenderWithEmptyData = false,
  renderNoResultSplash = true,
  noResult,
  loading,
  wrapper = ({ children }) => children,
  ErrorComponent,
  children,
}) {
  const { SplashNoResult, SplashError, loadingElement } = useDataHandler();

  if (
    swrResponse.data !== undefined &&
    swrResponse.data !== null &&
    swrResponse.error === undefined &&
    (keepDataOnRevalidation || swrResponse.isValidating === false)
  ) {
    const data = swrResponse.data["data"];
    if (
      Array.isArray(data) &&
      data.length === 0 &&
      !shouldRenderWithEmptyData
    ) {
      return renderNoResultSplash
        ? noResult ?? wrapper({ children: <SplashNoResult /> })
        : null;
    }
    return <>{children(swrResponse.data)}</>;
  }

  if (!swrResponse.isValidating && swrResponse.error) {
    return wrapper({
      children:
        ErrorComponent !== undefined ? (
          <ErrorComponent error={swrResponse.error} />
        ) : (
          <SplashError error={swrResponse.error} />
        ),
    });
  }

  if (swrResponse.isValidating) {
    return loading !== undefined
      ? loading
      : wrapper({ children: loadingElement });
  }

  return null;
}

/** @type {DataHandlerContextValue} */
const DEFAULT_VALUE = {
  loadingElement: <></>,
  SplashError: () => null,
  SplashNoResult: () => null,
};

const DataHandlerContext = createContext(DEFAULT_VALUE);

/**
 * @returns {DataHandlerContextValue}
 */
export function useDataHandler() {
  const value = useContext(DataHandlerContext);
  if (value === DEFAULT_VALUE) {
    throw new Error("useDataHandler must be used within a DataHandlerProvider");
  }
  return value;
}

/**
 * @typedef {object} Props
 * @property {DataHandlerContextValue} value
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function DataHandlerProvider({ value, children }) {
  return <DataHandlerContext.Provider value={value} children={children} />;
}
