import pkg from "../../../../package.json";

function errorBoundaryGetAuthor() {
  return encodeURIComponent(pkg.author);
}

/**
 * @param {object} params
 * @param {any} params.error
 */
function errorBoundaryFormatMailBody({ error }) {
  return encodeURIComponent(
    `Informations techniques\n\nURL de la page\u202f: ${
      window.location.href
    }\nAgent utilisateur\u202f: ${navigator?.userAgent}\nType d'erreur\u202f: ${
      error?.name
    }\nMessage d'erreur\u202f: ${
      error?.message
    }\nContenu de l'erreur\u202f: ${error?.toString?.()}`,
  );
}

export function errorBoundaryGetHrefContact({ error }) {
  return `mailto:${errorBoundaryGetAuthor()}?subject=Erreur%20applicative%20Raiden&body=${errorBoundaryFormatMailBody(
    {
      error,
    },
  )}`;
}
