import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * @typedef {object} Props
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
function CloseOnNavigation({ onClose }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      onClose();
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [onClose, router.events]);

  return null;
}

export default CloseOnNavigation;
