import { Button, ButtonGroup, ModalBody, ModalFooter } from "@chakra-ui/react";
import { useApiError } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import FormStateRHF from "@raiden/library-ui/components/ReactHookForm/FormStateRHF";
import { initBooleanStr } from "@raiden/library-ui/helpers/form/initBooleanStr";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import Form from "./Form";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import customersSerializeUpdateFiscal from "@raiden/library-ui/normalizers/users/customers/serializeUpdateFiscal";
import getRoomList from "@raiden/library-ui/helpers/hotels/getRoomList";
import { getFiscalNumber } from "@raiden/library-ui/helpers/users/customers/fiscal/getFiscalNumber";
import useSWR from "swr";
import useApiFetcher from "@raiden/library-ui/hooks/useApiFetcher";
import isSelectedCountryFrance from "@raiden/library-ui/helpers/countries/isSelectedCountryFrance";
import { useLocalesGetDefaultValuesKey } from "@raiden/library-ui/hooks/useLocalesGetDefaultValuesKey";

/**
 * @typedef {object} Params
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {import("@raiden/library-ui/types/Room").Room[]} roomList
 * @property {import("@raiden/library-ui/hooks/useLocalesGetDefaultValuesKey").localesGetDefaultValuesKey} localesGetDefaultValuesKey
 */
/**
 * @param {Params} params
 * @returns {import("@raiden/library-ui/types/Customer").CustomerFiscalDataFormValues}
 */
function getDefaultValues({ customer, roomList, localesGetDefaultValuesKey }) {
  const isPro = customer?.fiscal?.is_pro;

  return {
    data: {
      is_pro: initBooleanStr(isPro),

      fiscal_company: isPro
        ? customer.fiscal?.fiscal_company
          ? customer.fiscal?.fiscal_company
          : ""
        : "",
      fiscal_siren: isPro
        ? customer.fiscal?.fiscal_siren
          ? customer.fiscal?.fiscal_siren
          : ""
        : "",
      fiscal_number: customer?.fiscal ? getFiscalNumber(customer?.fiscal) : "",
      birthdate: isPro ? "" : customer?.fiscal?.birthdate ?? "",
      person_type: isPro
        ? customer.fiscal?.person_type
          ? customer.fiscal?.person_type
          : ""
        : "",

      first_name: customer?.first_name ?? "",
      last_name: customer?.last_name ?? "",
      gender: customer?.gender ?? "",

      address_1: customer?.address?.address_1 ?? "",
      address_2: customer?.address?.address_2 ?? "",
      city: customer?.address?.city ?? "",
      country_id: customer?.address?.country?.id ?? "",
      zipcode: customer?.address?.zipcode ?? "",
      rooms: roomList.map((room) => ({
        agr: room.agr ?? "",
        room_id: room.id ?? "",
        name: localesGetDefaultValuesKey(room.name),
        fiscal_number: room.fiscal_number ?? "",
      })),
    },
  };
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Customer").Customer} customer
 * @property {() => void} onSuccess
 * @property {() => void} [onClose]
 * @property {boolean} isClosable
 */
/**
 * @param {Props} props
 */
export default function Handler({ customer, onSuccess, onClose, isClosable }) {
  const intl = useIntl();

  const roomList = getRoomList(customer?.hotels ?? []);
  const localesGetDefaultValuesKey = useLocalesGetDefaultValuesKey();

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library-ui/types/Customer").CustomerFiscalDataFormValues>} */
  const form = useForm({
    defaultValues: getDefaultValues({
      customer,
      roomList,
      localesGetDefaultValuesKey,
    }),
  });
  const { handleSubmit, getValues } = form;

  const { request, isLoading, toastSuccess } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const apiFetcher = useApiFetcher();

  const { data: countriesResponse } = useSWR(
    generateApiUrl({
      id: "@countries.search",
      query: { per_page: 250 },
    }),
    apiFetcher,
  );

  const countries = countriesResponse?.data ?? [];

  /**
   * Gère la soumission du formulaire.
   */
  function onSubmit(fields) {
    request(
      generateApiUrl({
        id: "@customers.update",
        parameters: { customerId: customer?.id },
      }),
      {
        method: "POST",
        body: customersSerializeUpdateFiscal({
          fields,
          isCustomerFrench: isSelectedCountryFrance({
            countries,
            selectedCountryId: Number(getValues("data.country_id")),
          }),
        }),
      },
    )
      .then(function () {
        resetApiErrors();
        toastSuccess({
          title: intl.formatMessage({
            defaultMessage:
              "Vos informations fiscales ont bien été mises à jour.",
          }),
        });
        onSuccess();
      })

      .catch((response) => {
        resolveApiError({ response, form });
      });
  }
  return (
    <FormProvider {...form}>
      <ModalBody>
        <form
          id="updateDgFip"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate>
          <Form countries={countries} />
        </form>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          {isClosable && (
            <Button variant="outline" onClick={onClose}>
              {intl.formatMessage({
                defaultMessage: "Annuler",
              })}
            </Button>
          )}

          <FormStateRHF
            render={({ isDirty }) => (
              <Button
                form="updateDgFip"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
                colorScheme="brandPrimary">
                {intl.formatMessage({
                  defaultMessage: "Envoyer",
                })}
              </Button>
            )}
          />
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
