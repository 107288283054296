import { useFormState } from "react-hook-form";

/**
 * A component that exposes a form state via a render function
 * @param {{
 * render: (formState: import("react-hook-form").UseFormStateReturn<import("react-hook-form").FieldValues>) => import("react").ReactNode
 * }} param0
 */
function FormStateRHF({ render }) {
  const formState = useFormState();

  return <>{render?.(formState)}</>;
}

export default FormStateRHF;
