import { Component } from "react";
import { errorBoundaryGetHrefContact } from "../../helpers/errorBoundary/getHrefContact";

export class ErrorBoundaryDegraded extends Component {
  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  /**
   *
   * @returns {import("react").ReactNode}
   */
  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div>
          <p>Une erreur s&apos;est produite.</p>

          <p>
            Si le problème persiste, merci de prendre le temps de nous en
            informer en envoyant un email.
          </p>

          <a href={errorBoundaryGetHrefContact({ error })}>Nous contacter</a>
        </div>
      );
    }

    return children;
  }
}
