import { Box, Button } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { CenteredContent } from "../../components/CenteredContent";
import { PageHeader } from "../../components/PageHeader";
import { PageLayout } from "../../components/PageLayout";
import { Splash } from "../../components/Splash";

export function Error500(props) {
  const intl = useIntl();

  const router = useRouter();

  return (
    <PageLayout
      header={
        <PageHeader
          title={intl.formatMessage({
            defaultMessage: "La page demandée n'existe pas",
          })}
          breadCrumbItems={[
            {
              title: intl.formatMessage({ defaultMessage: "Accueil" }),
              url: generateFrontPath({
                id: "@front.account",
              }),
            },
            { title: intl.formatMessage({ defaultMessage: "Erreur 500" }) },
          ]}
        />
      }>
      <Box px="1rem" py="1rem">
        <CenteredContent>
          <Splash
            src={generateFrontPath({
              id: "@front.internal-assets",
              parameters: { filePath: "illustrations/error.svg" },
              includeBasePath: true,
            })}
            title={intl.formatMessage({
              defaultMessage:
                "Nous sommes désolés, nous rencontrons un problème de connexion",
            })}
            description={intl.formatMessage({
              defaultMessage:
                "Veuillez recharger la page ou réessayer plus tard",
            })}
            addon={
              <Button
                onClick={useCallback(() => {
                  router.reload();
                }, [router])}
                size="lg"
                colorScheme="brandPrimary">
                {intl.formatMessage({
                  defaultMessage: "Recharger la page",
                })}
              </Button>
            }
          />
        </CenteredContent>
      </Box>
    </PageLayout>
  );
}
