import { Box, Stack } from "@chakra-ui/react";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { useGetRoomName } from "@raiden/library-ui/hooks/useGetRoomName";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import Dac7Form from "../../../components/Customer/Dac7/Form";
import useAuth from "@raiden/library-ui/hooks/useAuth";

/**
 * @typedef {object} Props
 * @property {Array<import("@raiden/library-ui/types/Country").Country>} countries
 */

/**
 * @param {Props} props
 */
export default function Form({ countries }) {
  const intl = useIntl();

  const { userCustomer } = useAuth();

  const missingAttributes = userCustomer?.meta?.missing_attributes ?? [];

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library-ui/types/Customer").CustomerFiscalDataFormValues>} */
  const { control } = useFormContext();

  const getRoomName = useGetRoomName();

  /** @type {import("react-hook-form").UseFieldArrayReturn<import("@raiden/library-ui/types/Customer").CustomerFiscalDataFormValues, "data.rooms", "id">} */
  const { fields: rooms } = useFieldArray({
    name: "data.rooms",
  });

  return (
    <Box px="1rem">
      <ApiErrorMessage />

      <Dac7Form missingAttributes={missingAttributes} countries={countries} />

      <Stack spacing="1rem">
        {rooms.map((room, index) => {
          const name = getRoomName({ room, withAgr: true });
          const label = name
            ? intl.formatMessage(
                {
                  defaultMessage: "Numéro fiscal du local ({name})",
                },
                {
                  name,
                },
              )
            : intl.formatMessage({ defaultMessage: "Numéro fiscal du local" });
          return (
            <FormControlRHF
              key={index}
              control={control}
              label={label}
              helperText={intl.formatMessage({
                defaultMessage:
                  "Le numéro fiscal du local se compose de 12 caractères numériques dont les 2 premiers chiffres correspondent au numéro du département.",
              })}
              name={`data.rooms.${index}.fiscal_number`}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
