import { Box, Flex } from "@chakra-ui/react";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import { useMemo } from "react";
import { CenteredContentContext } from "../../../contexts/CenteredContentContext";
import { CartProvider } from "../../../hooks/useCart";
import { HeaderContextProvider } from "../../../hooks/useHeader";
import { NavBarContextProvider } from "../../../hooks/useNavBar";
import CookiesConsent from "../../CookiesConsent";
import BottomNavBar from "./BottomNavBar";
import { Footer } from "./Footer";
import { Header } from "./Header";

/**
 * @typedef {object} Props
 * @property {React.ReactNode} children
 */
/**
 * @param {import("../../../types/Layouts").LayoutProps & Props} props
 */
export function PublicLayout({ children }) {
  const { locale } = useLocale();
  return (
    <CenteredContentContext.Provider
      value={useMemo(() => ({ maxWidth: "1080px" }), [])}>
      <CartProvider>
        <HeaderContextProvider>
          <NavBarContextProvider>
            <Flex flexDir="column" justifyContent="stretch" minH="100vh">
              <Header />

              <Flex
                backgroundColor="gray.100"
                flexDir="column"
                justifyContent="stretch"
                flexGrow={1}
                minH="100vh">
                {children}
              </Flex>

              <Box flexShrink={0}>
                <Footer />
              </Box>

              <BottomNavBar />
            </Flex>

            <CookiesConsent locale={locale} />
          </NavBarContextProvider>
        </HeaderContextProvider>
      </CartProvider>
    </CenteredContentContext.Provider>
  );
}
