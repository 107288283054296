import {
  Box,
  Button,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Wrap,
} from "@chakra-ui/react";
import SplashImage from "@raiden/library-ui/components/Splash/Image";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function SuccessModal(props) {
  const { isOpen, onClose } = props;

  const intl = useIntl();

  setTimeout(() => {
    onClose();
  }, 5000);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      scrollBehavior="inside"
      isCentered>
      <ModalOverlay />

      <ModalContent bg="gray.100">
        <LightMode>
          <ModalCloseButton />

          <ModalHeader>
            <Text>
              {intl.formatMessage({
                defaultMessage: "Création réussie",
              })}
            </Text>
          </ModalHeader>

          <ModalBody>
            <Box maxW="18.75rem" mx="auto">
              <SplashImage image="done" />
            </Box>

            <Text align="center">
              {intl.formatMessage({
                defaultMessage:
                  "Le compte a été créé avec succès. Un email de confirmation vas vous être envoyé.",
              })}
            </Text>
          </ModalBody>

          <ModalFooter mb="1rem">
            <Wrap>
              <Button variant="outline" onClick={onClose}>
                {intl.formatMessage({
                  defaultMessage: "Fermer",
                })}
              </Button>
            </Wrap>
          </ModalFooter>
        </LightMode>
      </ModalContent>
    </Modal>
  );
}
