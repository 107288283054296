import useLocale from "@raiden/library-ui/hooks/useLocale";
import Head from "next/head";
import { useIntl } from "react-intl";
import { StructuredData } from "./StructuredData";

/**
 * @param {import("react-intl").IntlShape} intl
 * @param {string} [title]
 */
function formatTitle(intl, title) {
  return title
    ? `${title} - Clévacances`
    : intl.formatMessage({
        defaultMessage:
          "Clévacances : Location de vacances, gîte et chambre d'hôtes en France",
      });
}

/**
 * @typedef {object} Props
 * @property {string} [title]
 * @property {string} [description]
 * @property {string} [keywords]
 * @property {boolean} [noIndex]
 * @property {(locale: string) => string} [links]
 * @property {(locale: string) => string} [canonicalLink]
 * @property {(locale: string) => string} [alternateLinks]
 * @property {"music.song" | "music.album" | "music.playlist" | "music.radio_station" | "video.movie" | "video.episode" | "video.tv_show" | "video.other" | "article" | "book" | "profile" | "website" | "product"} [ogType]
 * @property {string} [imageUrl]
 * @property {string} [ogUrl]
 * @property {object} [structuredData]
 */
/**
 * @param {Props} props
 */
function Seo({
  title,
  description,
  keywords,
  noIndex,
  links,
  canonicalLink,
  alternateLinks,
  ogType,
  imageUrl,
  ogUrl,
  structuredData,
}) {
  const { locale: _currentLocale, locales, defaultLocale } = useLocale();

  const currentLocale =
    _currentLocale !== "default" ? _currentLocale : defaultLocale;
  const otherLocales = locales.filter(
    (locale) => locale !== "default" && locale !== currentLocale,
  );

  const intl = useIntl();

  const formattedTitle = formatTitle(intl, title);

  return (
    <>
      {structuredData && <StructuredData data={structuredData} />}

      <Head>
        <title key="title">{formattedTitle}</title>

        <meta property="og:title" content={formattedTitle} key="og-title" />

        <meta name="twitter:card" content="summary" key="twitter-card" />

        <meta
          name="twitter:title"
          content={formattedTitle}
          key="twitter-title"
        />

        {description && (
          <>
            <meta name="description" content={description} key="description" />

            <meta
              property="og:description"
              content={description}
              key="og-description"
            />

            <meta
              name="twitter:description"
              content={description}
              key="twitter-description"
            />
          </>
        )}

        {ogType && <meta property="og:type" content={ogType} key="og-type" />}

        {imageUrl && (
          <>
            <meta property="og:image" content={imageUrl} key="og-image" />

            <meta name="twitter:image" content={imageUrl} key="twitter-image" />
          </>
        )}

        {ogUrl && <meta property="og:url" content={ogUrl} key="og-url" />}

        {keywords && <meta name="keywords" content={keywords} key="keywords" />}

        {noIndex && <meta name="robots" content="noindex" key="noindex" />}

        {(links || canonicalLink) && currentLocale && (
          <link
            rel="canonical"
            href={(links ?? canonicalLink)?.(currentLocale)}
            key="canonical"
          />
        )}

        {(links || alternateLinks) &&
          otherLocales.map?.((locale, index) => (
            <link
              rel="alternate"
              href={(links ?? alternateLinks)?.(locale)}
              hrefLang={locale}
              key={index}
            />
          ))}
      </Head>
    </>
  );
}

export default Seo;
