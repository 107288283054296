import { Box, Stack } from "@chakra-ui/react";
import { InputAddressPlace } from "./Place";

/**
 * @typedef {object} Props
 * @property {string} term
 * @property {import("../../../types/PlaceAutocomplete.js").PlaceAutocomplete[]} placesAutocomplete
 * @property {import("./Place").InputAutoFillOnClickCallback} onClickPlace
 * @property {import("react").MutableRefObject<HTMLElement[]>} refOptions
 * @property {import("react").MutableRefObject<HTMLInputElement | null>} inputRef
 */
/**
 * @param {Props} props
 */
export function InputAddressResults({
  term,
  placesAutocomplete,
  onClickPlace,
  refOptions,
  inputRef,
}) {
  return (
    <Box>
      {placesAutocomplete.length > 0 && (
        <Stack spacing="4px">
          {placesAutocomplete.map((placeAutocomplete, index) => (
            <InputAddressPlace
              id={`places-search-result-${index}`}
              placeAutocomplete={placeAutocomplete}
              term={term}
              onClick={onClickPlace}
              key={placeAutocomplete.id}
              refOptions={refOptions}
              index={index}
              inputRef={inputRef}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
