// deps
import { Box } from "@chakra-ui/react";
import { useDatePicker } from "@react-aria/datepicker";
import { useDatePickerState } from "@react-stately/datepicker";
import { forwardRef, useRef } from "react";

// components
import CalendarDateTime from "./CalendarDateTime";
import PopoverContainer from "./Popover";

const DatePicker = forwardRef(
  /**
   * @typedef Props
   * @property {string} name
   * @property {import("@internationalized/date").DateValue | null} value
   * @property {string} [placeholder]
   * @property {"day" | "hour" | "minute" | "second"} granularity
   * @property {import("@internationalized/date").DateValue | null} minValue
   * @property {import("@internationalized/date").DateValue | null} maxValue
   * @property {boolean} [shouldCloseOnSelect]
   * @property {boolean} [shouldCloseOnDaySelect]
   * @property {boolean} [shouldAutoOpenPicker]
   * @property {boolean} [enableKeyboardInput]
   * @property {boolean} [shouldRenderClearButton]
   * @property {boolean} [shouldRenderTodayButton]
   * @property {boolean} [isDisabled]
   * @property {string} [minWidth]
   * @property {(event: any) => void} onChange
   * @property {(event: any) => void} [onFocus]
   * @property {import("@chakra-ui/react").BoxProps} [boxProps]
   */

  /**
   * @param {Props} props
   * @returns {import("react").ReactElement}
   */
  function DatePicker(props, ref) {
    // @ts-ignore
    // doc said that value is DateValue | null but not 0_0 if we passed undefined field, it cannot be emptied.
    let state = useDatePickerState(props);

    let inputGroupRef = useRef(null);
    // doc said that value is DateValue | null but not 0_0 if we passed undefined field, it cannot be emptied.
    let { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } =
      // @ts-ignore
      useDatePicker(props, state, inputGroupRef);

    function handleFocus(event) {
      if (props.shouldAutoOpenPicker && !props.enableKeyboardInput) {
        state.setOpen(true);
      }

      props?.onFocus?.(event);
    }

    return (
      <Box
        ref={ref}
        {...props.boxProps}
        position="relative"
        display="inline-flex"
        flexDirection="column"
        minWidth={props.minWidth}>
        <PopoverContainer
          {...dialogProps}
          groupProps={groupProps}
          inputGroupRef={inputGroupRef}
          fieldProps={fieldProps}
          buttonProps={buttonProps}
          handleFocus={handleFocus}
          state={state}
          isOpen={state.isOpen}
          isDisabled={props.isDisabled}
          onClose={() => state.setOpen(false)}>
          <CalendarDateTime
            state={state}
            calendarProps={calendarProps}
            onChange={props.onChange}
            shouldCloseOnDaySelect={props.shouldCloseOnDaySelect}
            shouldRenderClearButton={props.shouldRenderClearButton}
            shouldRenderTodayButton={props.shouldRenderTodayButton}
          />
        </PopoverContainer>
      </Box>
    );
  },
);

export default DatePicker;
