import { Flex, Stack, Text } from "@chakra-ui/react";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { PasswordTag } from "./PasswordTag";
import InputPassword from "../../Form/InputPassword";

export function PasswordForm() {
  const { control, getValues } = useFormContext();

  const intl = useIntl();

  const validatePasswordTenCharacters = (value) => value?.length >= 10;

  const validatePasswordContainsLowercase = (value) => /[a-z]/.test(value);

  const validatePasswordContainsUppercase = (value) => /[A-Z]/.test(value);

  const validatePasswordContainsFigure = (value) => /[0-9]/.test(value);

  const validatePasswordContainsSpecialCharacter = (value) =>
    /[^a-zA-Z0-9]/.test(value);

  const passwordRules = [
    {
      label: intl.formatMessage({
        defaultMessage: "10 caractères min.",
      }),
      rule: (value) => validatePasswordTenCharacters(value),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "1 minuscule",
      }),
      rule: (value) => validatePasswordContainsLowercase(value),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "1 majuscule",
      }),
      rule: (value) => validatePasswordContainsUppercase(value),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "1 chiffre",
      }),
      rule: (value) => validatePasswordContainsFigure(value),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "1 caractère spécial",
      }),
      rule: (value) => validatePasswordContainsSpecialCharacter(value),
    },
  ];

  function validatePassword(value) {
    // Check if the password is not empty (field isn't required but if it's filled, it must be valid)
    if (value.length > 0) {
      if (!validatePasswordTenCharacters(value)) {
        return intl.formatMessage({
          defaultMessage:
            "Le mot de passe doit contenir au moins 10 caractères.",
        });
      }

      if (!validatePasswordContainsLowercase(value)) {
        return intl.formatMessage({
          defaultMessage:
            "Le mot de passe doit contenir au moins une minuscule.",
        });
      }

      if (!validatePasswordContainsUppercase(value)) {
        return intl.formatMessage({
          defaultMessage:
            "Le mot de passe doit contenir au moins une majuscule.",
        });
      }

      if (!validatePasswordContainsFigure(value)) {
        return intl.formatMessage({
          defaultMessage: "Le mot de passe doit contenir au moins un chiffre.",
        });
      }

      if (!validatePasswordContainsSpecialCharacter(value)) {
        return intl.formatMessage({
          defaultMessage:
            "Le mot de passe doit contenir au moins un caractère spécial.",
        });
      }
    }

    return true;
  }

  function validatePasswordConfirmation(value) {
    if (value.length > 0 && value !== getValues("data.password")) {
      return intl.formatMessage({
        defaultMessage: "Les mots de passe ne correspondent pas",
      });
    }
    return true;
  }

  return (
    <Stack spacing="1rem">
      <Text fontSize="1.25rem" color="gray.500">
        {intl.formatMessage({
          defaultMessage: "Modifier votre mot de passe",
        })}
      </Text>

      <FormControlRHF
        control={control}
        label={intl.formatMessage({
          defaultMessage: "Saissisez votre nouveau mot de passe",
        })}
        rules={{
          validate: (value) => validatePassword(value),
        }}
        name="data.password"
        renderWithFormControl={(field) => (
          <>
            <InputPassword
              {...field}
              autoComplete="new-password"
              placeholder={intl.formatMessage({
                defaultMessage: "Votre nouveau mot de passe",
              })}
            />

            <Flex gap=".5rem" mt=".5rem" flexWrap="wrap">
              {passwordRules.map(({ label, rule }, index) => (
                <PasswordTag
                  key={index}
                  label={label}
                  condition={rule(field.value)}
                />
              ))}
            </Flex>
          </>
        )}
      />

      <FormControlRHF
        control={control}
        label={intl.formatMessage({
          defaultMessage: "Confirmez votre nouveau mot de passe",
        })}
        name="data.password_confirmation"
        rules={{
          validate: (value) => validatePasswordConfirmation(value),
        }}
        renderWithFormControl={(field) => (
          <InputPassword
            {...field}
            placeholder={intl.formatMessage({
              defaultMessage: "Répétez votre mot de passe",
            })}
          />
        )}
      />
    </Stack>
  );
}
