import nextRedirect from "@raiden/library-ui/helpers/next/redirect";
import { USERS_USER_TYPE_VALUE_CUSTOMER } from "@raiden/library-ui/constants/users";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { nextGetUri } from "@raiden/library-ui/helpers/next";

/**
 * Récupère la layout à afficher ou effectuer une redirection si nécessaire.
 * L’utilisateur sera redirigé vers le bon layout si besoin (à condition qu’il n’y ait pas d’erreur de type rate-limiter).
 * @param {object} param0
 * @param {import("next").NextPageContext} [param0.nextPageContext]
 * @param {import("../../../types/Layouts").LayoutName} [param0.pageLayout]
 * @param {boolean} param0.tooManyRequest
 * @param {import("@raiden/library-ui/types/User").UserBase} [param0.user]
 * @returns {Promise<import("../../../types/Layouts").LayoutName | undefined>}
 */
export async function nextGetFrontLayout({
  nextPageContext,
  pageLayout,
  tooManyRequest,
  user,
}) {
  let layout = undefined;

  if (
    "signedIn" === pageLayout &&
    user?.user_type !== USERS_USER_TYPE_VALUE_CUSTOMER &&
    !tooManyRequest &&
    nextPageContext?.res
  ) {
    const uri = nextGetUri({ req: nextPageContext?.req });
    await nextRedirect({
      code: 302,
      redirectionUri: generateFrontUrl({
        id: "@front.signin",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      res: nextPageContext?.res,
    });
  } else if (
    "signedOut" === pageLayout &&
    user?.user_type === USERS_USER_TYPE_VALUE_CUSTOMER &&
    !tooManyRequest &&
    nextPageContext?.res
  ) {
    await nextRedirect({
      code: 302,
      redirectionUri: generateFrontUrl({
        id: "@front.account",
        includeBasePath: true,
      }),
      res: nextPageContext?.res,
    });
  } else {
    layout = tooManyRequest ? "signedOut" : pageLayout;
  }

  return layout;
}
