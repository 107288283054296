import { Text } from "@chakra-ui/react";
import { forwardRef } from "react";

function normalizeText(string) {
  return string
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export const TextWithHighlight = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} text the text to be displayed
   * @property {string} keywords the text that should be highlighted
   */
  /**
   * @param {Props & import("@chakra-ui/react").TextProps} props
   */
  function TextWithHighlight({ text, keywords, ...otherProps }, ref) {
    // Skip processing if keywords is empty
    if (!keywords.trim()) {
      return (
        <Text ref={ref} {...otherProps}>
          {text}
        </Text>
      );
    }

    // Split keywords by space character and normalize each keyword
    const normalizedKeywords = normalizeText(
      keywords.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    ).split(" ");

    const normalizedText = normalizeText(text);

    // Create a pattern that matches the keyword
    const pattern = new RegExp(`(${normalizedKeywords.join("|")})`, "giu");

    // Split the text using the pattern
    const parts = [];
    let match;
    let lastIndex = 0;
    while ((match = pattern.exec(normalizedText)) !== null) {
      // Push the text before the match
      parts.push(text.substring(lastIndex, match.index));
      // Push the match
      parts.push(text.substring(match.index, match.index + match[0].length));
      lastIndex = pattern.lastIndex;
    }
    // Push the remaining text if any
    parts.push(text.substring(lastIndex));

    return (
      <Text ref={ref} {...otherProps}>
        {parts.map((part, index) => (
          <span
            style={
              normalizeText(part).match(pattern)
                ? { color: "var(--chakra-colors-brandSecondary-500)" }
                : undefined
            }
            key={index}>
            {part}
          </span>
        ))}
      </Text>
    );
  },
);
