import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { useIntl } from "react-intl";
import Seo from "..";

function DefaultSeo() {
  const intl = useIntl();

  return (
    <Seo
      description={intl.formatMessage({
        defaultMessage:
          "Clévacances: Location de vacances et chambre d'hôtes, chambres d'hôtes, gîtes, maisons & villas de vacances labellisées partout en France. Réservez avec Clévacances.",
      })}
      imageUrl={generateFrontUrl({
        id: "@front.internal-assets",
        parameters: {
          filePath: "logo.svg",
        },
        includeBasePath: true,
      })}
    />
  );
}

export default DefaultSeo;
