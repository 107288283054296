import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { CenteredContentContext } from "../../contexts/CenteredContentContext";

/**
 * @typedef {object} Props
 * @property {string | ((params: { defaultMaxWidth: string}) => string)} [maxWidth] css max-width property
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
export function CenteredContent({ maxWidth, children }) {
  const { maxWidth: defaultMaxWidth } = useContext(CenteredContentContext);

  return (
    <Box
      maxW={
        typeof maxWidth === "function"
          ? maxWidth({ defaultMaxWidth })
          : maxWidth ?? defaultMaxWidth
      }
      h="full"
      mx="auto">
      {children}
    </Box>
  );
}
