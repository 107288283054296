export const BLOCK_FILES_IMAGE = {
  type: "files-image",
  uid: "",
  config: {},
  allowHorizontalPositioning: true,
  alignment: "flex-start",
  fullWidth: false,
  paddingTop: 0,
  paddingRight: 16,
  paddingLeft: 16,
  paddingBottom: 0,
  backgroundType: "transparent",
  backgroundColor: "#FFFFFF",
  backgroundSrc: "",
  backgroundRepeat: "no-repeat",
  content: {
    href: undefined,
    openInNewTab: false,
    intl: {
      fr: {},
    },
  },
};
