import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { forwardRef, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

const InputPassword = forwardRef(
  /**
   * @typedef {object} Props
   */
  /**
   * @param {Props & import("@chakra-ui/react").InputProps} props
   */
  function InputPassword({ ...otherProps }, ref) {
    const [isVisible, setIsVisible] = useState(false);

    const intl = useIntl();

    const error = otherProps?.error;

    const toggle = useCallback(() => {
      setIsVisible((isVisible) => !isVisible);
    }, []);

    return (
      <InputGroup>
        <Input
          {...otherProps}
          ref={ref}
          type={isVisible ? "text" : "password"}
        />

        <InputRightElement zIndex="initial">
          <HStack gap=".5rem" mr={error ? "1.5rem" : ".5rem"}>
            {error && <Icon icon="ms_error" color="red.500" />}

            <IconButton
              icon={
                <Icon
                  icon={isVisible ? "ms_visibility_off" : "ms_visibility"}
                />
              }
              onClick={toggle}
              size="sm"
              aria-label={intl.formatMessage(
                {
                  defaultMessage:
                    "{isVisible, select, true {Masquer le mot de passse} other {Afficher le mot de passe}}",
                },
                {
                  isVisible,
                },
              )}
            />
          </HStack>
        </InputRightElement>
      </InputGroup>
    );
  },
);

export default InputPassword;
