import { useConfigurationMenu } from "@raiden/library-ui/hooks/useConfigurationMenu";
import { memo } from "react";
import { MenuItem } from "./MenuItem";
import { RGrid } from "@raiden/library-ui/components/RGrid";

export const Menu = memo(function Menu() {
  const { menu } = useConfigurationMenu({ alias: "footer" });

  return (
    <RGrid minCellWidth="200px" gridGap="2rem">
      {menu?.items?.map((menuItem, index) => {
        return <MenuItem menuItem={menuItem} key={index} />;
      })}
    </RGrid>
  );
});
