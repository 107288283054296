/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const BUTTON_THEME = {
  baseStyle: {
    position: "initial",
  },
  variants: {
    outline: {
      borderRadius: "0.625rem",
    },
    solid: {
      borderRadius: "0.625rem",
    },
  },
};
