import {
  Box,
  DarkMode,
  HStack,
  Image,
  LightMode,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library-ui/components/ImageLoaderHandler";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import NextLink from "next/link";
import { forwardRef, useCallback, useContext, useEffect, useRef } from "react";
import CloseOnNavigation from "../../../../components/CloseOnNavigation";
import {
  HIGHLIGHTED_MENU_ITEMS,
  HIGHLITHED_MENU_ITEMS_MIN_WIDTH,
} from "../../../../constants/menu";
import { CenteredContentContext } from "../../../../contexts/CenteredContentContext";
import { useHeader } from "../../../../hooks/useHeader";
import { MandatoryModals } from "../MandatoryModals";
import MenuHighlighted from "../Menu/MenuHighlighted";
import MenuStandard from "../Menu/MenuStandard";
import MenuButton from "../MenuButton";
import { Warning } from "../Warning";

const MenuButtonContainer = forwardRef(
  /**
   * @typedef {object} ContainerProps
   * @property {boolean} isOpened
   */
  /**
   * @param {ContainerProps} props
   */
  function MenuButtonContainer(props, ref) {
    return (
      <DarkMode>
        <MenuButton
          ref={ref}
          minWidth={HIGHLITHED_MENU_ITEMS_MIN_WIDTH}
          {...props}
        />
      </DarkMode>
    );
  },
);

function Header() {
  const headerRef = useRef(/** @type {HTMLDivElement | null} */ (null));

  const { setHeaderHeight } = useHeader();

  const updateHeaderHeight = useCallback(() => {
    const header = headerRef.current;
    if (header) {
      requestAnimationFrame(() => {
        setHeaderHeight(header.offsetHeight);
      });
    }
  }, [setHeaderHeight]);

  // on mount and unmount
  useEffect(() => {
    updateHeaderHeight();
    window.addEventListener("resize", updateHeaderHeight);

    return () => {
      setHeaderHeight(0);
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, [setHeaderHeight, updateHeaderHeight]);

  const maxWidth = useContext(CenteredContentContext).maxWidth;

  return (
    <Box>
      <Warning />

      <MandatoryModals />

      <DarkMode>
        <Box
          ref={headerRef}
          display={{ base: "none", md: "block" }}
          backgroundColor="brandPrimary.500"
          p="10px">
          <HStack spacing="0" maxWidth={maxWidth} margin="auto">
            <HStack flexGrow={1}>
              <NextLink
                href={generateFrontPath({
                  id: "@front.account",
                })}
                passHref>
                <Link as="a" display="block" overflow="hi">
                  <ImageLoaderHandler
                    src={generateFrontPath({
                      id: "@front.internal-assets",
                      parameters: { filePath: "logo-white.svg" },
                      includeBasePath: true,
                    })}>
                    {({ ref, src, isLoaded, handleLoad }) => (
                      <Image
                        ref={ref}
                        src={src}
                        onLoad={handleLoad}
                        opacity={isLoaded ? 1 : 0}
                        transition="opacity 0.25s"
                        alt="logo"
                        loading="lazy"
                      />
                    )}
                  </ImageLoaderHandler>
                </Link>
              </NextLink>
            </HStack>

            <HStack as="nav" spacing="16px">
              <MenuHighlighted items={HIGHLIGHTED_MENU_ITEMS} />

              <LightMode>
                <Popover
                  modifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ]}>
                  {({ isOpen, onClose }) => (
                    <>
                      <CloseOnNavigation onClose={onClose} />

                      <PopoverTrigger>
                        <MenuButtonContainer isOpened={isOpen} />
                      </PopoverTrigger>

                      <PopoverContent borderTopRadius="none">
                        <PopoverBody p="0">
                          <MenuStandard />
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              </LightMode>
            </HStack>
          </HStack>
        </Box>
      </DarkMode>
    </Box>
  );
}

export default Header;
