import { Box, Link, Text, useColorModeValue, Wrap } from "@chakra-ui/react";
import { Fragment, useMemo } from "react";
import NextLink from "next/link";
import { Icon } from "@raiden/library-ui/components/Icon";
import Head from "next/head";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";

/**
 * @typedef {object} BreadCrumbItem
 * @property {string} title
 * @property {string} [url]
 */
/**
 * @typedef {object} Props
 * @property {BreadCrumbItem[]} [items]
 * @property {boolean} [renderOnMobile]
 */
/**
 * @param {Props} props
 */
export function Breadcrumbs({ items = [], renderOnMobile = false }) {
  const color = useColorModeValue("gray.500", "#fff");
  const colorHover = useColorModeValue("brandPrimary.600", "#fff");

  const structuredData = useMemo(() => {
    const root = generateFrontUrl({ id: "@front.home" });
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: item.title,
        item: root + item.url,
      })),
    };
  }, [items]);

  return items.length > 0 ? (
    <>
      {structuredData && (
        <Head>
          <script type="application/ld+json" key="structured-data-breadcrumb">
            {JSON.stringify(structuredData)}
          </script>
        </Head>
      )}

      <Box display={renderOnMobile ? undefined : { base: "none", md: "block" }}>
        <Wrap align="center">
          {items.map?.((item, index) => (
            <Fragment key={index}>
              {item.url !== undefined ? (
                <NextLink href={item.url} passHref>
                  <Link
                    color={color}
                    _hover={{ color: colorHover, textDecoration: "underline" }}>
                    {item.title}
                  </Link>
                </NextLink>
              ) : (
                <Text as="span" color={color}>
                  {item.title}
                </Text>
              )}

              {index < items.length - 1 && (
                <Icon icon="ms_chevron_forward" color={color} />
              )}
            </Fragment>
          ))}
        </Wrap>
      </Box>
    </>
  ) : null;
}
