import { LightMode, Link, Tag, Text } from "@chakra-ui/react";
import generateBookingUrl from "@raiden/library-ui/libraries/utils/generateBookingUrl";
import { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Cart").Cart} cart
 */
/**
 * @param {Props} props
 */
export function Cart({ cart }) {
  const tripAmount = useMemo(() => {
    return cart.bookings.reduce((acc, booking) => {
      return acc + booking.trip_amount;
    }, 0);
  }, [cart.bookings]);

  return (
    <Link
      href={generateBookingUrl({
        id: "@booking.cart-update",
        query: {
          cartId: cart.id,
          cartToken: cart.access_token,
        },
      })}
      color="#fff">
      <Text display="inline" variant="text-sm">
        <FormattedMessage defaultMessage="Mon panier" />
      </Text>

      <LightMode>
        <Tag borderRadius="full" ml="8px">
          <Text as="span" variant="text-sm">
            <FormattedNumber
              value={tripAmount}
              style="currency"
              currency="EUR"
            />
          </Text>
        </Tag>
      </LightMode>
    </Link>
  );
}
