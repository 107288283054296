import { Box, HStack, Text } from "@chakra-ui/react";
import { DarkMode } from "@chakra-ui/system";
import { useConfigurationMenu } from "@raiden/library-ui/hooks/useConfigurationMenu";
import { FormattedMessage } from "react-intl";
import { CenteredContent } from "../../../../../components/CenteredContent";
import { useCart } from "../../../../../hooks/useCart";
import { Cart } from "../../../../Cart";
import { SignInButton } from "../SignInButton";
import { MenuItem } from "./MenuItem";

export function TopBar() {
  const { menu } = useConfigurationMenu({ alias: "top" });

  const { cart } = useCart();

  return (
    <DarkMode>
      <Box
        display={{ base: "none", lg: "block" }}
        backgroundColor="brandPrimary.500"
        borderBottomWidth="1px"
        borderColor="brandPrimary.300"
        px="1rem">
        <CenteredContent>
          <HStack spacing="1rem">
            <Text variant="text-sm" flexGrow={1}>
              <FormattedMessage defaultMessage="Label Confiance, contrôle tous les 5 ans" />
            </Text>

            {cart && <Cart cart={cart} />}

            <HStack spacing="0.625rem">
              {menu?.items.map((menuItem, index) => (
                <MenuItem menuItem={menuItem} key={index} />
              ))}

              <SignInButton />
            </HStack>
          </HStack>
        </CenteredContent>
      </Box>
    </DarkMode>
  );
}
