import {
  Box,
  Flex,
  HStack,
  useBreakpoint,
  useDimensions,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseOnNavigation from "../../../../components/CloseOnNavigation";
import { HIGHLIGHTED_MENU_ITEMS_PUBLIC } from "../../../../constants/menuPublic";
import { Z_INDEX } from "../../../../constants/zIndex";
import useBottomNavBar from "../../../../hooks/useNavBar";
import { useScrollBlocker } from "../../../../hooks/useScrollBlocker";
import MenuHighlighted from "./MenuHighlighted";
import PopoverMenu from "./PopoverMenu";
import MenuButton from "./MenuButton";

function BottomNavBar() {
  const [isOpened, setIsOpened] = useState(false);

  const { navBarHeight, setNavBarHeight } = useBottomNavBar();

  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const navbarRef = useRef(null);

  const dimensions = useDimensions(navbarRef, true);

  const { block, unblock } = useScrollBlocker();

  const breakpoint = useBreakpoint("base");

  useEffect(() => {
    dimensions && setNavBarHeight(dimensions.borderBox.height);
  }, [dimensions, setNavBarHeight]);

  const open = useCallback(() => {
    block();
    setIsOpened(true);
  }, [block]);

  const close = useCallback(() => {
    unblock();
    setIsOpened(false);
  }, [unblock]);

  const onToggle = useCallback(() => {
    isOpened ? close() : open();
  }, [close, isOpened, open]);

  useEffect(() => {
    if (breakpoint !== "base") {
      close();
    }
  }, [breakpoint, close]);

  return (
    <>
      <CloseOnNavigation onClose={close} />

      <Box
        ref={navbarRef}
        zIndex={Z_INDEX.bottomNavBar}
        display={{ base: "block", lg: "none" }}
        position={isOpened ? "fixed" : "sticky"}
        left="0"
        right="0"
        bottom="0"
        backgroundColor="#fff"
        borderTopWidth="1px">
        <HStack as="nav" justify="space-between" spacing="1rem">
          <MenuHighlighted
            items={HIGHLIGHTED_MENU_ITEMS_PUBLIC}
            isOpened={isOpened}
          />

          <MenuButton onClick={onToggle} isOpened={isOpened} />
        </HStack>
      </Box>

      <Flex
        direction="column"
        visibility={isOpened ? "visible" : "hidden"}
        position="fixed"
        zIndex={Z_INDEX.bottomNavBar}
        top="0"
        left="0"
        right="0"
        bottom={`${navBarHeight}px`}
        overflowY="scroll"
        backgroundColor="#fff"
        opacity={isOpened ? "1" : "0"}
        pointerEvents={isOpened ? "auto" : "none"}
        transition={
          isOpened
            ? "opacity .25s ease"
            : "all .25s ease, visibility .25s .25s ease"
        }>
        <PopoverMenu />
      </Flex>
    </>
  );
}

export default BottomNavBar;
