import { createContext, useContext, useState } from "react";

/**
 * This component is used on public and account parts of the app.
 * @type {React.Context<{
 * pageHeaderHeight: number,
 * setPageHeaderHeight: import("react").Dispatch<import("react").SetStateAction<number>>
 * headerHeight: number,
 * setHeaderHeight: import("react").Dispatch<import("react").SetStateAction<number>>
 * }>}
 */
const HeaderContext = createContext({
  pageHeaderHeight: 0,
  setPageHeaderHeight: (height) => {},
  headerHeight: 0,
  setHeaderHeight: (height) => {},
});

export function useHeader() {
  const {
    pageHeaderHeight,
    setPageHeaderHeight,
    headerHeight,
    setHeaderHeight,
  } = useContext(HeaderContext);

  const finalHeaderHeight = headerHeight + pageHeaderHeight;

  return {
    headerHeight: finalHeaderHeight,
    setPageHeaderHeight,
    setHeaderHeight,
  };
}

/**
 * @typedef {object} Props
 * @property {number} [initialHeaderHeight]
 * @property {number} [initialPageHeaderHeight]
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function HeaderContextProvider({
  initialHeaderHeight,
  initialPageHeaderHeight,
  children,
}) {
  const [pageHeaderHeight, setPageHeaderHeight] = useState(
    initialPageHeaderHeight ?? 0,
  );

  const [headerHeight, setHeaderHeight] = useState(initialHeaderHeight ?? 0);

  return (
    <HeaderContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        pageHeaderHeight,
        setPageHeaderHeight,
      }}>
      {children}
    </HeaderContext.Provider>
  );
}
