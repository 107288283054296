import { USERS_USER_TYPE_VALUE_CUSTOMER } from "@raiden/library-ui/constants/users";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { LAYOUTS } from "../constants/layouts";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/User").UserBase} [user]
 */
/**
 * @param {Props} props
 */
export function useAutoLayout({ user }) {
  const { asPath } = useRouter();

  const logged = user?.user_type === USERS_USER_TYPE_VALUE_CUSTOMER;

  const layout = useMemo(() => {
    if (asPath.startsWith(generateFrontPath({ id: "@front.signin" }))) {
      return LAYOUTS.signedOut;
    }

    if (asPath.startsWith(generateFrontPath({ id: "@front.account" }))) {
      return logged ? LAYOUTS.signedIn : LAYOUTS.signedOut;
    }

    return LAYOUTS.public;
  }, [asPath, logged]);

  return layout;
}
