// deps
import { useRef } from "react";
import { useLocale } from "@react-aria/i18n";
import { useDateFieldState } from "@react-stately/datepicker";
import { useDateField } from "@react-aria/datepicker";
import { Box } from "@chakra-ui/react";

// components
import DateSegment from "./DateSegment";
import createCalendar from "./createCalendar";

function DateField(props) {
  let { locale } = useLocale();
  let state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  let ref = useRef(null);
  let { fieldProps } = useDateField(props, state, ref);

  return (
    <Box {...fieldProps} ref={ref} display="flex">
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </Box>
  );
}

export default DateField;
