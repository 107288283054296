/** @type {import("@chakra-ui/react").ComponentStyleConfig} */
export const FORM_THEME = {
  parts: ["container"],
  baseStyle: {
    container: {
      label: {
        color: "#002538",
        fontSize: "1rem !important",
        fontWeight: "300",
        lineHeight: "1.3125rem",
      },
    },
  },
};
