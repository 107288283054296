import { useConfiguration } from "./useConfiguration";

/**
 * @param {object} params
 * @param {string} params.alias
 */
export function useConfigurationMenu({ alias }) {
  const { configuration } = useConfiguration();

  const menu = configuration.menus?.find((menu) => menu.alias === alias);

  return { menu };
}
