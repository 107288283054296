import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { Href } from "../../../components/Href";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {import("../../../types/Layouts").LayoutProps & Props} props
 */
export function BasicLayout({ children }) {
  return (
    <Flex flexGrow={1} minH="100dvh" flexDirection="column">
      <Box
        flexShrink={0}
        backgroundColor="white"
        py="1rem"
        position="sticky"
        top="0"
        zIndex="1"
        borderBottomWidth="1px">
        <AspectRatio ratio={260 / 62} maxW="200px" mx="auto">
          <Href
            href={generateFrontUrl({
              id: "@front.home",
            })}>
            <Image
              src={generateFrontPath({
                id: "@front.internal-assets",
                parameters: { filePath: "logo.svg" },
                includeBasePath: true,
              })}
              alt="Clévacances"
            />
          </Href>
        </AspectRatio>
      </Box>

      <Box backgroundColor="gray.100" flexGrow={1}>
        {children}
      </Box>
    </Flex>
  );
}
