import { Flex } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { CenteredContentContext } from "../../../contexts/CenteredContentContext";
import { HeaderContextProvider } from "../../../hooks/useHeader";
import { NavBarContextProvider } from "../../../hooks/useNavBar";
import CookiesConsent from "../../CookiesConsent";
import BottomNavBar from "./BottomNavBar";
import Header from "./Header";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {import("../../../types/Layouts").LayoutProps & Props} props
 */
export function SignedInLayout({ children }) {
  const { push, asPath } = useRouter();

  const { locale } = useLocale();

  const { userCustomer } = useAuth();

  useEffect(() => {
    if (!userCustomer) {
      push(
        generateFrontPath({
          id: "@front.signin",
          query: {
            next: encodeURIComponent(asPath),
          },
        }),
      );
    }
  }, [asPath, push, userCustomer]);

  return (
    <CenteredContentContext.Provider
      value={useMemo(() => ({ maxWidth: "1360px" }), [])}>
      <HeaderContextProvider>
        <NavBarContextProvider>
          <Flex direction="column" minHeight="100vh">
            <Flex flexGrow={1} direction="column-reverse">
              {children}

              <Header />
            </Flex>

            <BottomNavBar />
          </Flex>

          <CookiesConsent locale={locale} />
        </NavBarContextProvider>
      </HeaderContextProvider>
    </CenteredContentContext.Provider>
  );
}
