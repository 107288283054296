// helpers
import usersIsRoot from "../isRoot";

/** @typedef {Array<string>} RequestedAuthorizations */

/**
 * Retourne vrai si l’authorisation comparée est présente dans la liste des authorisations.
 * @param {object} param0
 * @param {RequestedAuthorizations} param0.requestedAuthorizations
 * @param {Array<string>} param0.authorizations
 * @returns {boolean}
 */
function hasAuthorization({ requestedAuthorizations, authorizations }) {
  return requestedAuthorizations.every(function (requestedAuthorization) {
    return authorizations.includes(requestedAuthorization);
  });
}

/**
 * Retourne vrai si l’utilisateur a le droit d’effectuer cette action.
 * @param {object} param0
 * @param {import("../../../types/User").UserBase} [param0.user]
 * @param {string[] | string} param0.authorizations
 * @param {string | number | null} [param0.environmentId]
 * @param {number | null} [param0.hotelId]
 */
export default function usersCan({
  user,
  authorizations,
  environmentId,
  hotelId,
}) {
  //  si le user est root on bypass les tests.
  if (usersIsRoot({ user })) {
    return true;
  }

  /** @type {RequestedAuthorizations} */
  const requestedAuthorizations = !Array.isArray(authorizations)
    ? [authorizations]
    : authorizations;

  /** on récupére les authorizations de l'utilisateur (user) courant. */
  const userAuthorizations = user?.meta?.authorizations ?? [];

  if (hotelId || environmentId) {
    /** @type {RequestedAuthorizations} */
    const _requestedAuthorizations = [];

    if (environmentId) {
      /** @type {number} */
      const _environmentId =
        "string" === typeof environmentId
          ? parseInt(environmentId)
          : environmentId;

      /** on parcourt toutes les Authorizations de l'utilisateur en recherchant l'objet associé à l'environnementId passé en param */
      userAuthorizations.forEach((currentUserAuthorization) => {
        if (
          currentUserAuthorization.environment_id === _environmentId ||
          "*" === currentUserAuthorization.environment_id
        ) {
          _requestedAuthorizations.push(
            ...(currentUserAuthorization?.authorizations ?? []),
          );
        }
      });
    }
    if (hotelId) {
      /** on parcourt toutes les Authorizations de l'utilisateur en recherchant l'objet associé à l'hotelId passé en param */
      userAuthorizations.forEach((currentUserAuthorization) => {
        if (
          currentUserAuthorization.hotel_id === hotelId ||
          "*" === currentUserAuthorization.hotel_id
        ) {
          _requestedAuthorizations.push(
            ...(currentUserAuthorization?.authorizations ?? []),
          );
        }
      });
    }

    return hasAuthorization({
      requestedAuthorizations,
      authorizations: _requestedAuthorizations,
    });
  } else {
    /* Si on ne filtre pas par environnement et par hotelId. */
    return userAuthorizations.some(function (authorizationsByEnvironment) {
      return hasAuthorization({
        requestedAuthorizations,
        authorizations: authorizationsByEnvironment?.authorizations ?? [],
      });
    });
  }
}
