/**
 * Returns a color based on the completion percentage
 * @typedef {object} Props
 * @property {number | undefined} percentage
 */

/**
 * @param {Props} props
 * @returns {string | undefined}
 */
export function getColorFromCompletionPercentage({ percentage }) {
  if (!percentage) {
    return "red";
  }

  if (percentage >= 0 && percentage <= 25) {
    return "red";
  }
  if (percentage > 25 && percentage <= 50) {
    return "orange";
  }

  if (percentage > 50) {
    return "blue";
  }
}
