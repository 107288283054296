import { extendTheme } from "@chakra-ui/react";
import { ALERT_THEME } from "./components/alert";
import { BUTTON_THEME } from "./components/button";
import { CHECKBOX_THEME } from "./components/checkbox";
import { INPUT_THEME } from "./components/input";
import { MODAL_THEME } from "./components/modal";
import { SELECT_THEME } from "./components/select";
import { TEXT_THEME } from "./components/text";
import { TEXTAREA_THEME } from "./components/textarea";

export const THEME_ACCOUNT = extendTheme({
  colors: {
    brandPrimary: {
      50: "#E5F2F9",
      100: "#C7E8F9",
      200: "#95CCEA",
      300: "#6DB6DE",
      400: "#008BD5",
      500: "#008CD2",
      600: "#0F6999",
      700: "#124B69",
      800: "#052D42",
      900: "#00121C",
    },
    brandSecondary: {
      50: "#F9EBE5",
      100: "#F9D6C7",
      200: "#F4A588",
      300: "#F28A5E",
      400: "#EA7246",
      500: "#F95E24",
      600: "#E24D16",
      700: "#A2350E",
      800: "#752508",
      900: "#461300",
    },
    darkGray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
    seasonBadge: {
      low: {
        500: "#4299E1",
      },
      medium: {
        500: "#48BB78",
      },
      high: {
        500: "#ED8936",
      },
      event: {
        500: "#F6AD55",
      },
      none: {
        500: "#718096",
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px var(--chakra-colors-brandPrimary-500)",
  },
  components: {
    Alert: ALERT_THEME,
    Button: BUTTON_THEME,
    Checkbox: CHECKBOX_THEME,
    Input: INPUT_THEME,
    Modal: MODAL_THEME,
    Select: SELECT_THEME,
    Text: TEXT_THEME,
    Textarea: TEXTAREA_THEME,
  },
  fonts: {
    heading: "Outfit",
    body: "Outfit",
  },
  styles: {
    global: {
      body: {
        overflowY: "scroll",
      },
    },
  },
});
