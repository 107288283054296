import { useIntl } from "react-intl";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Handler from "./Handler";
import { ApiErrorProvider } from "../../../components/ReactHookForm/ApiError";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {(fields) => void} onSubmit
 * @property {import("../../../types/TwoFaChallenge").TwoFaChallenge} resource
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export default function Data({ isOpen, onClose, onSubmit, resource }) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnEsc={false}
      size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {intl.formatMessage({
            defaultMessage: "Authentification à deux facteurs",
          })}
        </ModalHeader>

        <ApiErrorProvider>
          <Handler onClose={onClose} onSubmit={onSubmit} resource={resource} />
        </ApiErrorProvider>
      </ModalContent>
    </Modal>
  );
}
