import { Box, HStack, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import NextLink from "next/link";
import { isValidElement, useCallback, useEffect, useRef } from "react";
import { useHeader } from "../../hooks/useHeader";
import { Breadcrumbs } from "../Breadcrumbs";
import { CenteredContent } from "../CenteredContent";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} title
 * @property {string} [backUrl]
 * @property {import("../Breadcrumbs").BreadCrumbItem[]} [breadCrumbItems]
 * @property {import("react").ReactElement} [addon]
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
export function PageHeader({
  title,
  backUrl,
  breadCrumbItems,
  addon,
  children,
}) {
  /** @type {import("react").MutableRefObject<HTMLDivElement | null>} */
  const headerRef = useRef(null);

  const { setPageHeaderHeight } = useHeader();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const updatePageHeaderHeight = useCallback(() => {
    const header = headerRef.current;
    if (header) {
      requestAnimationFrame(() => {
        setPageHeaderHeight(isMobile ? header.offsetHeight : 0);
      });
    }
  }, [isMobile, setPageHeaderHeight]);

  // on mount and unmount
  useEffect(() => {
    updatePageHeaderHeight();
    window.addEventListener("resize", updatePageHeaderHeight);

    return () => {
      setPageHeaderHeight(0);
      window.removeEventListener("resize", updatePageHeaderHeight);
    };
  }, [setPageHeaderHeight, updatePageHeaderHeight]);

  return (
    <Box
      ref={headerRef}
      px={{ base: 0, md: "1rem" }}
      mt={{ base: 0, md: "1.25rem" }}
      position={{ base: "sticky", md: "relative" }}
      top={0}
      zIndex={1}>
      <CenteredContent>
        <Breadcrumbs items={breadCrumbItems} />

        <Box
          backgroundColor="#fff"
          borderRadius={{ base: "0", md: "0.375rem" }}>
          <Box
            pr={{ base: "1rem", md: 0 }}
            pl={backUrl ? "0" : { base: "1rem", md: "0" }}
            borderBottomWidth={{ base: "1px", md: "0" }}>
            <HStack spacing="0" align="center">
              {backUrl && (
                <NextLink href={backUrl} passHref>
                  <Box
                    as="a"
                    display={{ base: "flex", md: "none" }}
                    alignSelf="stretch"
                    px="0.625rem"
                    pr="0.75rem"
                    py="1rem">
                    <Icon icon="ms_arrow_back_ios_new" size="24px" />
                  </Box>
                </NextLink>
              )}

              <Stack
                w="full"
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                align={{ md: "center" }}
                spacing={{ base: ".5rem", md: "1rem" }}
                pt={{ md: ".5rem" }}>
                <Text
                  py="0.5625rem"
                  variant="h1"
                  fontSize={{ base: "1.875rem", xl: "2rem" }}
                  lineHeight={{ base: "1.875rem", xl: "2rem" }}>
                  {title}
                </Text>

                {addon && <Box>{addon}</Box>}
              </Stack>
            </HStack>
          </Box>

          {isValidElement(children) && <>{children}</>}
        </Box>
      </CenteredContent>
    </Box>
  );
}
