// constants
import browser from "../../../constants/browser";

/**
 * Gère la mise en place du code d’erreur 503 lorsqu’une maintenance a lieu.
 * @param {object} param0
 * @param {import("http").ServerResponse} [param0.res]
 */
export default async function nextCheckMaintenance({ res }) {
  if (
    /* On renvoie un code 503 pour indiquer que le serveur est en maintenance. */
    !browser &&
    "true" === process.env.NEXT_PUBLIC_MAINTENANCE &&
    res
  ) {
    res.statusCode = 503;
  }
}
