import MenuCore from "../../../../components/Menu/MenuCore";
import MenuItemCore from "../../../../components/Menu/MenuItemCore";
import MenuItemHighlighted from "./MenuItem/MenuItemHighlighted";

/**
 * @typedef {object} Props
 * @property {import("../../../../components/Menu/MenuItemCore").MenuItemCore[]} items
 */
/**
 * @param {Props} props
 */
function MenuHighlighted({ items }) {
  return (
    <MenuCore
      menuItems={items}
      render={({
        menuItems,
        closeMenuItem,
        openMenuItem,
        openedMenuItemsIds,
      }) => (
        <>
          {menuItems?.map?.((item) => (
            <MenuItemCore
              item={item}
              openedMenuItemsIds={openedMenuItemsIds}
              openMenuItem={openMenuItem}
              closeMenuItem={closeMenuItem}
              render={(params) => <MenuItemHighlighted {...params} />}
              key={item.id}
            />
          ))}
        </>
      )}
    />
  );
}

export default MenuHighlighted;
