import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { localStorageGetItem } from "@raiden/library-ui/helpers/localStorage/getItem";
import { localStorageRemoveItem } from "@raiden/library-ui/helpers/localStorage/removeItem";
import { localStorageSetItem } from "@raiden/library-ui/helpers/localStorage/setItem";
import { LOCAL_STORAGE_KEYS } from "@raiden/library-ui/constants/localStorage";
import { CARTS_STATE_VALUE_DRAFT } from "@raiden/library-ui/constants/carts";

// #region getOldCartInfo
/**
 * Retrieves old cart information from local storage and saves it under a new key.
 * If the old cart info is not valid, returns undefined.
 * @returns {CartInfo | undefined}
 */
function getOldCartInfo() {
  // If window is undefined, return immediately because local storage is not available
  if (typeof window === "undefined") {
    return;
  }

  const OLD_KEY = "cartIdSerialize"; // The old key used to store cart information in local storage
  const cartInfoJson = localStorage.getItem(OLD_KEY); // Retrieve the old cart information from local storage

  // If no cart information found, return
  if (!cartInfoJson) {
    return;
  }

  localStorage.removeItem(OLD_KEY); // Remove the old cart information from local storage

  try {
    // Parse the JSON string to retrieve cart information
    const { id, data: accessToken } = JSON.parse(cartInfoJson);
    // Validate that 'id' is a number and 'accessToken' is a string
    if (typeof id !== "number" || typeof accessToken !== "string") {
      return;
    }

    // Save the valid cart information under the new key in local storage
    localStorageSetItem(LOCAL_STORAGE_KEYS.CART, { id, accessToken });
    // Return the cart information
    return { id, accessToken };
  } catch {
    // If parsing fails, return undefined
    return;
  }
}

/**
 * @callback CartContextSetIdAndToken
 * @param {{ id: number, token: string}} params
 */

/**
 * @typedef {object} CartInfo
 * @property {number} id
 * @property {string} accessToken
 */

/**
 * @typedef {object} CartContextValue
 * @property {CartInfo} [cartInfo]
 * @property {() => void} reset
 * @property {(params: CartInfo) => void} setCartInfo
 * @property {import("@raiden/library-ui/types/Cart").Cart} [cart]
 * @property {import("swr").KeyedMutator<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Cart").Cart>>} mutate
 */

/** @type {CartContextValue} */
const DEFAULT_VALUE = {
  cartInfo: undefined,
  reset: () => {},
  setCartInfo: () => {},
  cart: undefined,
  // @ts-expect-error
  mutate: () => {},
};

const CartContext = createContext(DEFAULT_VALUE);

// #region CartProvider
export function CartProvider({ children }) {
  const [cartInfo, _setCartInfo] = useState(
    /** @type {CartInfo | undefined} */
    (
      localStorageGetItem(LOCAL_STORAGE_KEYS.CART) ??
        getOldCartInfo() ??
        undefined
    ),
  );

  const reset = useCallback(() => {
    _setCartInfo(undefined);
    localStorageRemoveItem(LOCAL_STORAGE_KEYS.CART);
  }, []);

  const setCartInfo = useCallback(
    /**
     * @param {CartInfo} params
     */
    ({ id, accessToken }) => {
      _setCartInfo({ id, accessToken });
      localStorageSetItem(LOCAL_STORAGE_KEYS.CART, { id, accessToken });
    },
    [],
  );

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Cart").Cart>} */
  const { swrResponse: swrResponseCart } = useApi(
    cartInfo
      ? generateApiUrl({
          id: "@carts.show",
          parameters: {
            cartId: cartInfo.id,
          },
          query: {
            cart_token: cartInfo.accessToken,
          },
        })
      : null,
    {
      swrConfig: {
        onError: () => {
          reset();
        },
        onSuccess: (data) => {
          if (data?.data?.state !== CARTS_STATE_VALUE_DRAFT) {
            reset();
          }
        },
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      },
    },
  );

  useEffect(() => {
    // add window focus event
    const handler = () => {
      _setCartInfo((currentCartInfo) => {
        const newCartInfo = localStorageGetItem(LOCAL_STORAGE_KEYS.CART);
        if (JSON.stringify(currentCartInfo) !== JSON.stringify(newCartInfo)) {
          return newCartInfo;
        }
        return currentCartInfo;
      });
    };
    window.addEventListener("focus", handler);
    return () => {
      window.removeEventListener("focus", handler);
    };
  }, [setCartInfo]);

  const value = useMemo(() => {
    /** @type {CartContextValue} */
    const value = {
      cartInfo,
      reset,
      setCartInfo,
      cart: swrResponseCart.data?.data,
      mutate: swrResponseCart.mutate,
    };
    return value;
  }, [
    cartInfo,
    reset,
    setCartInfo,
    swrResponseCart.data?.data,
    swrResponseCart.mutate,
  ]);

  return <CartContext.Provider value={value} children={children} />;
}

// #region useCart
export function useCart() {
  const value = useContext(CartContext);
  if (value === DEFAULT_VALUE) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return value;
}
