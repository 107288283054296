// deps
import { useCallback } from "react";

// hooks
import useLocale from "./useLocale";

/**
 * @typedef {object} Values
 * @property {import("../types/common/TranslatedField").TranslatedField | null} [resource]
 * @property {import("./useLocale").LocaleTranslate[]} localesTranslate
 * @property {string} defaultLocale
 */

/** @typedef {(resource?: import("../types/common/TranslatedField").TranslatedField | null) => import("../types/common/TranslatedField").TranslatedFieldValues} localesGetDefaultValuesKey */

/** @typedef {(data: Values) => import("../types/common/TranslatedField").TranslatedFieldValues} getLocalesDefaultValuesKey */

/**
 *
 * @param {import("../types/common/TranslatedField").TranslatedField | null} [resource]
 * @param {import("./useLocale").LocaleTranslate} [locale]
 * @returns {string | null}
 */
function getValue(resource, locale) {
  if (resource && locale) {
    return resource[locale.id];
  }
  return null;
}

/**
 * permet de sortir les clés des valeurs par défaut des locales {fr: "",en: "",}
 * @param {object} params
 * @param {import("./useLocale").LocaleTranslate[]} params.localesTranslate
 * @param {object} [params.resource]
 * @param {string} params.defaultLocale
 * @returns {import("../types/common/TranslatedField").TranslatedFieldValues}
 */
export function getLocalesDefaultValuesKey({
  localesTranslate,
  resource,
  defaultLocale,
}) {
  let locales = {
    [defaultLocale]: "",
  };
  localesTranslate.forEach((locale) => {
    const value = getValue(resource, locale);
    if (value) {
      locales[locale.id] = value;
    } else {
      locales[locale.id] = "";
    }
  });

  return locales;
}

export function useLocalesGetDefaultValuesKey() {
  const { localesTranslate, defaultLocale } = useLocale();

  /** @type {localesGetDefaultValuesKey} */
  const localesGetDefaultValuesKey = useCallback(
    /**
     * permet de sortir les clés des valeurs par défaut des locales {fr: "",en: "",}
     * @param {import("../types/common/TranslatedField").TranslatedField | null} [resource]
     * @returns {import("../types/common/TranslatedField").TranslatedFieldValues}
     */
    function (resource) {
      return getLocalesDefaultValuesKey({
        localesTranslate,
        resource,
        defaultLocale,
      });
    },
    [localesTranslate, defaultLocale],
  );

  return localesGetDefaultValuesKey;
}
