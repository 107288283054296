import Head from "next/head";
import { memo, useMemo } from "react";
import uniqid from "uniqid";

export const StructuredData = memo(
  /**
   * @typedef {object} Props
   * @property {{[key: string]: any}} data
   */
  /**
   * @param {Props} props
   */ function StructuredData({ data }) {
    const uniqueKey = useMemo(() => {
      return uniqid();
    }, []);
    return (
      <Head>
        <script
          type="application/ld+json"
          key={uniqueKey}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />
      </Head>
    );
  },
);
