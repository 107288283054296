import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { FormattedMessage, useIntl } from "react-intl";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import { DepartmentsAutocomplete } from "../../../components/Form/DepartmentsAutocomplete";
import InputPhone from "../../../components/Form/InputPhone";
import FormControlRHF from "../../../components/ReactHookForm/FormControlRHF";
import FormObserver from "../../../components/ReactHookForm/FormObserver";
import FormStateRHF from "../../../components/ReactHookForm/FormStateRHF";
import { RGrid } from "../../../components/RGrid";
import {
  CUSTOMERS_CONTACTS_CUSTOMER_TYPE_LIST,
  CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR,
} from "../../../constants/customers/contacts";
import {
  hotelsTypeMessage,
  HOTELS_TYPES_VALUE_LIST,
} from "../../../constants/hotels";
import {
  usersAdminsGenderMessage,
  USERS_ADMINS_GENDER_LIST,
} from "../../../constants/users/admins";
import { useApi } from "../../../hooks/useApi";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").Control<import("../Create/Handler").LeadsFormValues>} control
 * @property {boolean} isLoading
 * @property {"label" | "ranking"} type
 */
/**
 * @param {Props} props
 */
export function LeadsCreateForm({ control, isLoading, type }) {
  const intl = useIntl();

  /** @type {import("../../../hooks/useApi").UseApi<import("../../../types/Country").Country[]>} */
  const { swrResponse } = useApi(
    generateApiUrl({ id: "@countries.search", query: { per_page: 250 } }),
  );

  return (
    <Stack spacing="16px">
      {type === "label" && (
        <Text
          style={{
            fontFamily: "Outfit",
            fontSize: "1.25rem",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.025rem",
          }}>
          <FormattedMessage
            defaultMessage="Vous souhaitez rejoindre le label Clévacances ?{br}Recevez notre brochure gratuitement !"
            values={{
              br: <br />,
            }}
          />
        </Text>
      )}
      {type === "ranking" && (
        <Text
          style={{
            fontFamily: "Outfit",
            fontSize: "1.25rem",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.025rem",
          }}>
          <FormattedMessage
            defaultMessage="Vous souhaitez faire classer votre hébergement ?{br}Rencontrez un conseiller Clévacances."
            values={{
              br: <br />,
            }}
          />
        </Text>
      )}
      <Box>
        <ApiErrorMessage withCaughtErrors={true} />

        <RGrid minCellWidth="300px" gridGap="16px">
          <FormControlRHF
            control={control}
            name="data.customer_type"
            rules={{ required: true }}
            label={intl.formatMessage({ defaultMessage: "Vous êtes" })}
            renderWithFormControl={(field) => (
              <Select {...field}>
                {CUSTOMERS_CONTACTS_CUSTOMER_TYPE_LIST.map((type) => (
                  <option value={type.id} key={type.id}>
                    {capitalize(intl.formatMessage(type.label))}
                  </option>
                ))}
              </Select>
            )}
          />
          <FormObserver name="data.customer_type" control={control}>
            {(value) => (
              <>
                {value !==
                  CUSTOMERS_CONTACTS_CUSTOMER_TYPE_VALUE_PARTICULAR && (
                  <FormControlRHF
                    control={control}
                    name="data.company_name"
                    rules={{ required: true }}
                    label={intl.formatMessage({
                      defaultMessage: "Nom de l'entité",
                    })}
                  />
                )}
              </>
            )}
          </FormObserver>
        </RGrid>
      </Box>
      <RGrid minCellWidth="300px" gridGap="16px">
        <FormControlRHF
          control={control}
          label={intl.formatMessage({
            defaultMessage: "Civilité",
          })}
          rules={{
            required: true,
          }}
          name="data.gender"
          renderWithFormControl={(fields) => (
            <Select {...fields} placeholder="-">
              {USERS_ADMINS_GENDER_LIST.map((item) => (
                <option key={item.id} value={item.id}>
                  {capitalize(
                    intl.formatMessage(usersAdminsGenderMessage, {
                      gender: item.id,
                    }),
                  )}
                </option>
              ))}
            </Select>
          )}
        />

        <FormControlRHF
          control={control}
          name="data.first_name"
          rules={{ required: true }}
          label={intl.formatMessage({ defaultMessage: "Prénom" })}
        />

        <FormControlRHF
          control={control}
          name="data.last_name"
          rules={{ required: true }}
          label={intl.formatMessage({ defaultMessage: "Nom" })}
        />
      </RGrid>
      <RGrid minCellWidth="500px" gridGap="16px">
        <FormControlRHF
          control={control}
          name="data.email"
          rules={{ required: true }}
          label={intl.formatMessage({
            defaultMessage: "Adresse email",
          })}
        />

        <InputPhone
          countries={swrResponse.data?.data ?? []}
          nameCountry="data.contact_phone_country"
          nameNumber="data.contact_phone"
          isRequired={true}
        />
      </RGrid>
      <RGrid minCellWidth="500px">
        <FormControlRHF
          control={control}
          name="data.department_id"
          rules={{
            required: true,
          }}
          label={intl.formatMessage({ defaultMessage: "Département" })}
          renderWithFormControl={(field) => (
            <DepartmentsAutocomplete {...field} />
          )}
        />
      </RGrid>
      <RGrid minCellWidth="500px">
        <FormControlRHF
          control={control}
          name="data.hotel_type"
          rules={{
            required: true,
          }}
          label={intl.formatMessage({
            defaultMessage: "Type de bien",
          })}
          renderWithFormControl={(fields) => (
            <Select {...fields} placeholder="-">
              {HOTELS_TYPES_VALUE_LIST.map?.((type) => {
                return (
                  <option value={type.id} key={type.id}>
                    {intl.formatMessage(hotelsTypeMessage, {
                      type: type.id,
                    })}
                  </option>
                );
              })}
            </Select>
          )}
        />
      </RGrid>

      <Box>
        <FormControlRHF
          control={control}
          name="data.message"
          rules={{ maxLength: 5000 }}
          type="textarea"
          withCharactersCounter={true}
          label={intl.formatMessage({
            defaultMessage: "Message",
          })}
        />
      </Box>

      <Box>
        <FormControlRHF
          control={control}
          name="data.accept_both"
          render={(field) => (
            <Checkbox isChecked={field.value} {...field} value={undefined}>
              {type === "label" ? (
                <Text>
                  <FormattedMessage defaultMessage="Je souhaite également faire classer mon hébergement." />
                </Text>
              ) : (
                <Text>
                  <FormattedMessage defaultMessage="Je souhaite également obtenir le label Clévacances." />
                </Text>
              )}
            </Checkbox>
          )}
        />
      </Box>

      <Box>
        <FormControlRHF
          control={control}
          name="data.accept_terms"
          rules={{
            required: true,
            validate: (value) =>
              value
                ? true
                : intl.formatMessage({
                    defaultMessage: "Vous devez accepter les conditions",
                  }),
          }}
          renderWithFormControl={(field) => (
            <Checkbox isChecked={field.value} {...field} value={undefined}>
              <FormLabel as={Text} m="0">
                <FormattedMessage defaultMessage="J'accepte que mes données personnelles soient utilisées pour me recontacter." />
              </FormLabel>
            </Checkbox>
          )}
        />
      </Box>

      <HStack justify="flex-end">
        <FormStateRHF
          render={({ isDirty }) => (
            <Button
              type="submit"
              isLoading={isLoading}
              isDisabled={!isDirty}
              colorScheme="orange"
              backgroundColor="#F95E24">
              <Text variant="button" color="#fff !important">
                <FormattedMessage defaultMessage="Envoyer ma demande" />
              </Text>
            </Button>
          )}
        />
      </HStack>
    </Stack>
  );
}
