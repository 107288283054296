import { Flex } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {import("react").ReactElement} [header]
 */
/**
 * @param {import("react").PropsWithChildren<Props>} props
 */
export function PageLayout({ header, children }) {
  return (
    <Flex flexGrow={1} flexDirection="column-reverse" justify="flex-end">
      <Flex flexGrow={1} flexShrink={0} flexDir="column">
        {children}
      </Flex>

      {header}
    </Flex>
  );
}
