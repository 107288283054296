/**
 * Return true if the customer has more than one hotel based on the roomsInfos
 * @param {object} param0
 * @param {import("../../../types/Customer").CustomerRoomInfo[]} [param0.roomsInfos]
 */

export default function customerHasMoreThanOneHotel({ roomsInfos }) {
  const hotelIds = [];

  roomsInfos?.forEach((roomInfo) => {
    if (hotelIds.indexOf(roomInfo.hotel_id) === -1) {
      hotelIds.push(roomInfo.hotel_id);
    }
  });

  return hotelIds.length > 1;
}
